import { z } from "zod";

import {
  noteDataSchema,
  noteSchema,
  NoteTypes,
  orbitNoteDataBcbaAssessmentSchema,
} from "../types/notes";

export const createNoteEndpointRequestSchema = noteSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .extend({
    id: z.string().optional(),
  })
  .strict();

export type ICreateNoteEndpointRequest = z.input<typeof createNoteEndpointRequestSchema>;

export const createNoteFromAppointmentEndpointRequestSchema = z
  .object({
    appointmentId: z.string(),
    userId: z.string(),
    noteType: z.nativeEnum(NoteTypes).optional(),
  })
  .strict();

export type ICreateNoteFromAppointmentEndpointRequest = z.input<
  typeof createNoteFromAppointmentEndpointRequestSchema
>;

export const updateNoteEndpointRequestSchema = noteSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .deepPartial()
  .merge(noteSchema.pick({ id: true }))
  .extend({
    data: noteDataSchema.optional(),
  })
  .strict();

export type IUpdateNoteEndpointRequest = z.input<typeof updateNoteEndpointRequestSchema>;

export const updateBcbaAssessmentNoteSchema = orbitNoteDataBcbaAssessmentSchema.deepPartial();

export const getNoteEndpointRequestSchema = z
  .object({
    id: z.string(),
    clinicId: z.string(),
  })
  .strict();

export type IGetNoteEndpointRequest = z.input<typeof getNoteEndpointRequestSchema>;
