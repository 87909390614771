import { IClientFile, MedicalServices } from "@finni-health/shared";
import { Button, Card, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import React, { ReactNode } from "react";

import { selectedToggle, unselectedToggle } from "../../../consts";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempMedicalHistory: any;
  setTempMedicalHistory: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClientFile: (req: Partial<IClientFile>) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterCurrentServices: React.FC<IProps> = ({
  isMobile,
  tempMedicalHistory,
  setTempMedicalHistory,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClientFile,
  backButton,
}: IProps) => {
  const toggleCurrentService = (currentService: MedicalServices) => {
    const newTempMedicalHistory = tempMedicalHistory;
    let tempCurrentServices = tempMedicalHistory.currentServices || [];

    if (tempMedicalHistory.currentServices?.includes(currentService)) {
      tempCurrentServices = tempCurrentServices.filter((tempCurrentService: MedicalServices) => {
        return tempCurrentService !== currentService;
      });
    } else {
      tempCurrentServices.push(currentService);
    }

    if (_.isEmpty(tempCurrentServices)) {
      delete newTempMedicalHistory.currentServices;
    } else {
      newTempMedicalHistory.currentServices = tempCurrentServices;
    }

    setTempMedicalHistory({ ...newTempMedicalHistory });
  };

  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          Please select any services that your child is currently receiving.
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.currentServices?.includes(MedicalServices.ABA_THERAPY)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleCurrentService(MedicalServices.ABA_THERAPY);
          }}
        >
          ABA Therapy
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.currentServices?.includes(MedicalServices.PSYCHOLOGICAL_SERVICES)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleCurrentService(MedicalServices.PSYCHOLOGICAL_SERVICES);
          }}
        >
          Psychological Services
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.currentServices?.includes(MedicalServices.SPEECH_THERAPY)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleCurrentService(MedicalServices.SPEECH_THERAPY);
          }}
        >
          Speech Therapy
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.currentServices?.includes(MedicalServices.OCCUPATIONAL_THERAPY)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleCurrentService(MedicalServices.OCCUPATIONAL_THERAPY);
          }}
        >
          Occupational Therapy
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.currentServices?.includes(MedicalServices.PHYSICAL_THERAPY)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleCurrentService(MedicalServices.PHYSICAL_THERAPY);
          }}
        >
          Physical Therapy
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.currentServices?.includes(MedicalServices.PSYCHIATRIC_SERVICES)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleCurrentService(MedicalServices.PSYCHIATRIC_SERVICES);
          }}
        >
          Psychiatric Services
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.currentServices?.includes(MedicalServices.NEUROLOGICAL_SERVICES)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleCurrentService(MedicalServices.NEUROLOGICAL_SERVICES);
          }}
        >
          Neurological Services
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.currentServices?.includes(MedicalServices.SPECIAL_EDUCATION)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleCurrentService(MedicalServices.SPECIAL_EDUCATION);
          }}
        >
          Special Education/IEP
        </Button>
      </Row>

      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempMedicalHistory.otherCurrentServices !== undefined
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            if (tempMedicalHistory.otherCurrentServices === undefined) {
              setTempMedicalHistory({
                ...tempMedicalHistory,
                otherCurrentServices: "",
              });
            } else {
              const newTempMedicalHistory = tempMedicalHistory;
              delete newTempMedicalHistory.otherCurrentServices;
              setTempMedicalHistory({ ...newTempMedicalHistory });
            }
          }}
        >
          Other
        </Button>
      </Row>
      {tempMedicalHistory.otherCurrentServices !== undefined && (
        <Row
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <TextArea
            autoSize
            defaultValue={tempMedicalHistory.otherCurrentServices}
            placeholder="Let us know what other services your child is currently receiving."
            style={{ marginBottom: 25, maxWidth: 400 }}
            onChange={(e) => {
              setTempMedicalHistory({
                ...tempMedicalHistory,
                otherCurrentServices: e.target.value,
              });
            }}
          />
        </Row>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={tempMedicalHistory.otherCurrentServices === ""}
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClientFile({
              medicalHistory: tempMedicalHistory,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
