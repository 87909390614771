import { FilePdfOutlined } from "@ant-design/icons";
import {
  DEBUG_DROPBOX,
  DROPBOX_CONTRACTS,
  DROPBOX_HANDBOOK_TEMPLATE_IDS,
  DropboxDocumentSignerRole,
  DropboxDocumentType,
  IClient,
  IClientFile,
  ISignedDropboxDocument,
} from "@finni-health/shared";
import { Button, Card, Col, List, message, Row, Typography } from "antd";
import * as HelloSign from "hellosign-embedded";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { getOrCreateClientFile } from "../../helpers/get-or-create-client-file";
import { isScreenMobile } from "../../helpers/is-screen-mobile";
import { Loading } from "../../pages/Loading";
import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";

// Hack the types for the library
const dropboxClient = new (HelloSign as any)({
  clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
});

const consentForms = Object.values(DropboxDocumentType).filter(
  (docType) =>
    docType !== DropboxDocumentType.THIRD_PARTY_CONSENT_FORM &&
    docType !== DropboxDocumentType.SCHOOL_OR_AGENT_THIRD_PARTY_CONSENT_FORM
);

const { Title, Text } = Typography;

interface IProps {
  clientFile: IClientFile;
  refreshClientFile?: () => void;
}

export const ConsentForm = ({ clientFile, refreshClientFile }: IProps) => {
  const history = useHistory();

  const { guardian, clinic } = useContext(AuthContext);

  const [isMobile, setIsMobile] = useState(isScreenMobile());
  const [isFetchingEmbedUrl, setFetchingEmbedURL] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [client, setClient] = useState<IClient>();

  useEffect(() => {
    fetchClient().catch(() => {});
  }, []);

  const fetchClient = async () => {
    setClient(await FirestoreService.getClientById(clientFile.clientId));
  };

  const retriesRef = useRef<number>(0); // avoid re-renders

  const createEmbedRequestData = async () => {
    try {
      setFetchingEmbedURL(true);
      const currentForm = DROPBOX_CONTRACTS[DropboxDocumentType.CAREGIVER_CONSENT_FORM];
      const clinicId = clinic?.id;
      const handbookTemplateId =
        DROPBOX_HANDBOOK_TEMPLATE_IDS[clinicId as keyof typeof DROPBOX_HANDBOOK_TEMPLATE_IDS] ||
        DROPBOX_HANDBOOK_TEMPLATE_IDS.default;

      const embedResponse = await FirestoreService.getEmbeddedUrl({
        signers: [
          {
            emailAddress: guardian.email || "",
            name: guardian.firstName + " " + guardian.lastName,
            role: DropboxDocumentSignerRole["Parent/Guardian"],
          },
        ],
        formName: DropboxDocumentType.CAREGIVER_CONSENT_FORM,
        customFields: [
          {
            name: currentForm.customFields.parentFullName,
            value: guardian.firstName + " " + guardian.lastName,
            editor: DropboxDocumentSignerRole["Parent/Guardian"],
            required: true,
          },
          {
            name: currentForm.customFields.clientFullName,
            value: client?.firstName + " " + client?.lastName,
            editor: DropboxDocumentSignerRole["Parent/Guardian"],
            required: true,
          },
          {
            name: currentForm.customFields.practiceName,
            value: clinic.displayName,
            editor: DropboxDocumentSignerRole["Parent/Guardian"],
            required: true,
          },
        ],
        additionalTemplateIds: [handbookTemplateId],
      });

      if (embedResponse.signUrl && embedResponse.expiresAt) {
        openEmbedWindow(embedResponse.signUrl);
        setFetchingEmbedURL(false);
      } else {
        setFetchingEmbedURL(false);

        void message.error(
          "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
        );
      }
    } catch (error) {
      setFetchingEmbedURL(false);

      console.error(error);
      void message.error(
        "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
      );
    }
  };

  const openEmbedWindow = (url: string) => {
    dropboxClient.open(url, {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      allowCancel: true,
      // Set these both to true when testing
      skipDomainVerification: DEBUG_DROPBOX,
      testMode: DEBUG_DROPBOX,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(isScreenMobile());
    });
  }, []);

  // Listen for the finish event so we know when the document has been signed
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const handleFinish = (): void => {
      setIsLoading(true);

      const checkAndUpdateClientFile = async () => {
        if (!guardian || _.isEmpty(guardian)) {
          throw new Error("No guardian found");
        }

        const freshClientFile = await getOrCreateClientFile(guardian);

        const formHasBeenSigned = freshClientFile?.signedDocuments?.find(
          (doc: ISignedDropboxDocument) =>
            doc.documentType === DropboxDocumentType.CAREGIVER_CONSENT_FORM
        );

        if (formHasBeenSigned) {
          refreshClientFile && refreshClientFile();
          clearInterval(intervalId);
          history.push("/");
          return;
        }

        if (retriesRef.current >= 10) {
          console.log("Exceeded max retries, please refresh the page");
          refreshClientFile && refreshClientFile();
          clearInterval(intervalId);
          void message.error("Exceeded max retries, please refresh the page");
          return;
        }

        retriesRef.current += 1;
      };

      intervalId = setInterval(checkAndUpdateClientFile, 4000);
    };

    dropboxClient.on("finish", handleFinish);

    return () => {
      dropboxClient.off("finish", handleFinish);
      clearInterval(intervalId);
    };
  }, []);

  return isLoading ? (
    <Row justify="center" align="middle">
      <Text style={{ marginTop: 25, fontWeight: 500, fontSize: 20 }}>
        Please wait while we wrap things up...
      </Text>
      <Loading />
    </Row>
  ) : (
    <Card
      style={{ width: isMobile ? "100%" : 600, marginTop: 35 }}
      bodyStyle={{ padding: "8px 0" }}
    >
      {/* Fire embed on form review click */}
      <List
        size="large"
        header={
          <Title level={5} style={{ fontWeight: 500, padding: "0 24px" }}>
            Please review and sign these documents
          </Title>
        }
        footer={
          <Row justify="end" style={{ marginTop: 13, padding: "0 24px" }}>
            <Button
              onClick={createEmbedRequestData}
              type="primary"
              shape="round"
              loading={isFetchingEmbedUrl}
            >
              View forms
            </Button>
          </Row>
        }
        dataSource={consentForms}
        renderItem={(item) => (
          <List.Item style={{ margin: "0 24px" }}>
            <Row justify="space-between">
              <Col>
                <FilePdfOutlined />
              </Col>
              <Col style={{ marginLeft: 16 }}>{item}</Col>
            </Row>
          </List.Item>
        )}
      />
    </Card>
  );
};
