import { z } from "zod";

export const motivityCreateClientEndpointRequestSchema = z.object({
  id: z.string().min(1),
  alias: z.string(),
  fullName: z.string().min(1),
});

export type IMotivityCreateClientEndpointRequest = z.input<
  typeof motivityCreateClientEndpointRequestSchema
>;
