import {
  IAddress,
  ICreateClientFileEndpointRequest,
  IGuardian,
  IntakeStatus,
} from "@finni-health/shared";
import _ from "lodash";

import * as FirestoreService from "../services/firestore";

export const getOrCreateClientFile = async (guardian: IGuardian) => {
  try {
    let clientFile = await FirestoreService.getClientFileByGuardianId(guardian.id);

    if (_.isEmpty(clientFile)) {
      const req: ICreateClientFileEndpointRequest = {
        clinicId: guardian.clinicId,
        guardianId: guardian.id,
        clientId: "",
        address: {} as IAddress,
        intakeStatus: IntakeStatus.UNDISCOVERED,
        payers: {},
      };
      await FirestoreService.createClientFile(req);

      clientFile = await FirestoreService.getClientFileByGuardianId(guardian.id);
    }

    return clientFile;
  } catch (err) {
    console.log(err);
  }
};
