import { z } from "zod";

export const createSandataEmployeeEndpointRequestSchema = z.object({
  userId: z.string().min(1),
  sin: z.string().length(9),
});

export type ICreateSandataEmployeeEndpointRequest = z.input<
  typeof createSandataEmployeeEndpointRequestSchema
>;
