import { z } from "zod";

export const notifyClinicEndpointRequestSchema = z
  .object({
    clinicId: z.string().min(1),
    message: z.string().min(1),
    ctaText: z.string().optional(),
    ctaUrl: z.string().optional(),
  })
  .strict();

export type INotifyClinicEndpointRequest = z.input<typeof notifyClinicEndpointRequestSchema>;

export const notifyProviderSuccessNewClinicSignupRequestSchema = z
  .object({
    clinicId: z.string().min(1),
    clinicName: z.string().min(1),
    userName: z.string().min(1),
  })
  .strict();

export type INotifyProviderSuccessNewClinicSignupEndpointRequest = z.input<
  typeof notifyProviderSuccessNewClinicSignupRequestSchema
>;
