import { z } from "zod";

import { BillingCode, Modifier, ProviderType } from "../types";
import { AppointmentLocation } from "../types";

export const BillingCodeSchema = z.nativeEnum(BillingCode);

export const ModifierSchema = z.nativeEnum(Modifier);

export const AppointmentLocationSchema = z.nativeEnum(AppointmentLocation);

export const getExpectedRevenueEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  clientId: z.string().min(1),
  attendeeEmails: z.array(z.string().min(1)),
  billingCode: BillingCodeSchema,
  modifiers: z.array(ModifierSchema),
  startMs: z.number().min(1),
  endMs: z.number().min(1),
  location: AppointmentLocationSchema,
});

export type IGetExpectedRevenueEndpointRequest = z.input<
  typeof getExpectedRevenueEndpointRequestSchema
>;

export interface ICalculateChargeAndUnits {
  clinicId: string;
  clientId: string;
  attendeeEmails: string[];
  billingCode: BillingCode;
  modifiers: Modifier[];
  startMs: number;
  endMs: number;
  location: AppointmentLocation;
  providerType: ProviderType;
}
