import {
  CountryCode,
  GENERAL_ERROR_MESSAGE,
  IAddress,
  ISelectedPlace,
  USStateCode,
} from "@finni-health/shared";
import { message } from "antd";
import { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

interface Props {
  apiKey: string;
  onChange: (address: IAddress, placeDetails?: google.maps.GeocoderResult[]) => void;
  initialAddressLine1?: string;
  isDisabled?: boolean;
}

export const PlacesAutocompleteInput = ({
  apiKey,
  onChange,
  initialAddressLine1 = "",
  isDisabled = false,
}: Props) => {
  const [addressLine1, setAddressLine1] = useState<string>();
  const [tempAddress, setTempAddress] = useState<string>(initialAddressLine1); // Used to control the input value when the user clicks away without selecting an option

  useEffect(() => {
    setAddressLine1(initialAddressLine1);
    setTempAddress(initialAddressLine1);
  }, [initialAddressLine1]);

  const handleAddressAutoComplete = async (selected: ISelectedPlace | null) => {
    try {
      const place = selected?.value;
      const placeId = place?.place_id;

      if (!placeId) {
        void message.error(GENERAL_ERROR_MESSAGE);
        return;
      }

      const placeDetails = await geocodeByPlaceId(placeId);

      if (placeDetails?.[0]) {
        const addressComponents = placeDetails[0].address_components;

        // Extract specific address components
        const addressLine1 = place.structured_formatting.main_text;

        const city = addressComponents.find(
          (component) =>
            component.types.includes("locality") || component.types.includes("sublocality")
        )?.long_name as string;

        const state = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.short_name as USStateCode;

        const zipCode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name as string;

        const address: IAddress = {
          line1: addressLine1,
          city,
          country: CountryCode.US,
          state,
          zipCode,
        };

        // Update the field (state is delayed without this)
        setAddressLine1(addressLine1);
        setTempAddress(addressLine1);
        onChange && selected && onChange(address, placeDetails);
      }
    } catch (error) {
      console.error("Error fetching place details:", error);
      void message.error(GENERAL_ERROR_MESSAGE);
    }
  };

  return (
    <PlacesAutocomplete
      apiKey={apiKey}
      apiOptions={{
        region: "US",
      }}
      minLengthAutocomplete={2}
      selectProps={{
        className: isDisabled ? "ant-input ant-input-disabled" : "ant-input",
        placeholder: "Search...",
        required: true,
        isDisabled,
        value: {
          value: addressLine1,
          label: addressLine1,
        } as {
          value: string;
          label: string;
        },
        onFocus: () => {
          addressLine1 && setTempAddress(addressLine1);
          setAddressLine1("");
        },
        onBlur: () => {
          if (addressLine1 && addressLine1 !== initialAddressLine1) {
            setAddressLine1(addressLine1);
          } else if (tempAddress) {
            setAddressLine1(tempAddress);
          }
        },
        openMenuOnFocus: true,
        onChange: handleAddressAutoComplete,
        styles: {
          control: () => ({}),
          container: () => ({}),
          valueContainer: (styles) =>
            ({
              ...styles,
              padding: 0,
            } as any),
          indicatorsContainer: () => ({
            display: "none",
          }),
          input: (styles) =>
            ({
              ...styles,
              padding: 0,
              margin: 0,
            } as any),
          menu: (styles) =>
            ({
              ...styles,
              margin: 0,
              padding: 0,
              left: 0,
            } as any),
          menuPortal: () => ({}),
          menuList: () => ({}),
        },
      }}
      autocompletionRequest={{
        types: ["address"],
        componentRestrictions: {
          country: ["us"],
        },
      }}
    />
  );
};
