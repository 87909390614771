import {
  AppointmentType,
  BillingCode,
  IAppointment,
  IAppointmentConfig,
  ICompletedAppointment,
  IPayer,
  Modifier,
  ProviderType,
} from "../../types";
import { getBaseBillingModifiers } from "./helpers";

export const COLORADO_APPOINTMENTS: IAppointmentConfig[] = [
  {
    name: AppointmentType.UPDATED_ASSESSMENT,
    billingCode: BillingCode.CODE_97151,
    modifiers: [Modifier.TJ],
  },
  {
    name: AppointmentType.ASSESSMENT_OUTCOME_MEASURES,
    billingCode: BillingCode.CODE_97151,
    modifiers: [Modifier.MOD_99],
  },
];

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  providerType: ProviderType
) => {
  return getBaseBillingModifiers(appointment, payer, providerType);
};

export const coloradoBillingModifier = getStateBillingModifiers;
