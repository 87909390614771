import { z } from "zod";

export const generateSchedulesForClientEndpointRequestSchema = z.object({
  clientId: z.string().min(1),
  week: z.number(),
  year: z.number(),
  timeZone: z.string(),
});

export type IGenerateSchedulesForClientEndpointRequest = z.input<
  typeof generateSchedulesForClientEndpointRequestSchema
>;
