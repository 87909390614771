import { z } from "zod";

import { addressSchema, IClinic } from "../types";

export interface IPublicGetByIdEndpointRequest {
  id: string;
}

export interface IPublicGetByAccessNameEndpointRequest {
  name: string;
}

export interface IPublicGetByAccessZipCodeEndpointRequest {
  zipCode: string;
}

export type IUpdateClinicEndpointRequest = Partial<IClinic> & Pick<IClinic, "id">;

export const createClinicEndpointRequestSchema = z
  .object({
    name: z.string(),
    displayName: z.string(),
    phoneNumber: z.string().optional(),
    email: z.string().optional(),
    address: addressSchema,
    addressNotes: z.string(),
    serviceAreas: z.array(z.string()),
  })
  .strict();

export type ICreateClinicEndpointRequest = z.input<typeof createClinicEndpointRequestSchema>;
