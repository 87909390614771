import { UploadOutlined } from "@ant-design/icons";
import { IClientFile, IClientPayer, IPayer } from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Card, Col, message, Row, Select, Space, Typography, Upload } from "antd";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

import app from "../../services/firebase";
import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";

const { Title, Text } = Typography;

enum ClientFilePayerStates {
  ENTER_PRIMARY_PAYMENT,
  ENTER_SECONDARY_PAYMENT,
}
enum PaymentMethods {
  PRIVATE_INSURANCE,
  MEDICAID,
  CASH,
}

const MEDICAID_PAYER_TEXT = "Medicaid";
const CASH_PAYER_TEXT = "Cash";

interface IProps {
  clientFile: IClientFile;
}

export const Payers: React.FC<IProps> = ({ clientFile }: IProps) => {
  const { refresh: refreshContext, clinic } = useContext(AuthContext);
  const { isMobile } = useResponsive();

  const [clientFilePayerStatus, setClientFilePayerStatus] = useState<ClientFilePayerStates>(
    ClientFilePayerStates.ENTER_PRIMARY_PAYMENT
  );
  const [payers, setPayers] = useState<IPayer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethods>();
  const [tempPrimaryPayerId, setTempPrimaryPayerId] = useState<string>("");
  const [tempSecondaryPayerId, setTempSecondaryPayerId] = useState<string>("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [cardFrontFileList, setCardFrontFileList] = useState<any>();
  const [cardBackFileList, setCardBackFileList] = useState<any>();
  const [tempPrimaryFrontPhotoURL, setTempPrimaryFrontPhotoURL] = useState<string>();
  const [tempPrimaryBackPhotoURL, setTempPrimaryBackPhotoURL] = useState<string>();
  const [tempSecondaryFrontPhotoURL, setTempSecondaryFrontPhotoURL] = useState<string>();
  const [tempSecondaryBackPhotoURL, setTempSecondaryBackPhotoURL] = useState<string>();
  const [isSecondaryInsuranceVisible, setIsSecondaryInsuranceVisible] = useState<boolean>();
  const location = useLocation();

  const fetchPayers = async () => {
    try {
      const allPayersForState = await FirestoreService.getAllPayersForState(clinic?.address?.state);
      setPayers(allPayersForState?.filter((payer) => !payer.payerId.includes("SELFPAY")) || []);
    } catch (error) {
      console.error(`Failed to load payers. state:${clinic.address.state}`, error);
    }
  };

  useEffect(() => {
    fetchPayers().catch(() => {});
  }, []);

  const getUploadProps = (
    fileList: any,
    setFileList: (fileList: any) => void,
    storageLocation: string,
    setURL: (url: string) => void
  ) => {
    return {
      name: "file",
      multiple: false,
      showUploadList: {
        showDownloadIcon: false,
      },
      onRemove: () => {
        setFileList([]);
        return deleteObject(
          ref(getStorage(app), `clientFiles/${clientFile.id}/insurance/${storageLocation}`)
        );
      },
      beforeUpload: async (file: File) => {
        //file size larger than 10MB (10000000 Bytes)
        if (file.size > 10000000) {
          void message.error("Your file is too large. Please select a file under 10MB");
          setFileList([]);
          return false;
        }

        setFileList([file]);

        setIsUploading(true);

        await uploadBytes(
          ref(getStorage(app), `clientFiles/${clientFile.id}/insurance/${storageLocation}`),
          file
        ).then(async (results) => {
          const tempURL = await getDownloadURL(results.ref);
          setURL(tempURL);
          setIsUploading(false);
        });

        ReactGA.event("insurance_upload", {
          page_path: location.pathname + location.search + location.hash,
        });

        // Prevent remote upload
        return false;
      },
      fileList,
    };
  };

  const enterPrimaryPayment = () => {
    const uploadCardFrontProps = getUploadProps(
      cardFrontFileList,
      setCardFrontFileList,
      "primary/front",
      setTempPrimaryFrontPhotoURL
    );

    const uploadCardBackProps = getUploadProps(
      cardBackFileList,
      setCardBackFileList,
      "primary/back",
      setTempPrimaryBackPhotoURL
    );

    return (
      <Card
        title={
          <>
            <Title
              level={5}
              style={{
                marginBottom: 10,
                fontWeight: 400,
                whiteSpace: "pre-wrap",
              }}
            >
              {`How do you intend to pay for your child's therapy?`}
            </Title>
            <Text type="secondary" style={{ fontSize: 14, whiteSpace: "normal" }}>
              {`If you intend to pay with insurance, but currently don't have your insurance card on
              hand, you can select your payment method and skip uploading images by selecting
              "Next".`}
            </Text>
          </>
        }
        style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
      >
        <Space style={{ width: "100%", justifyContent: "center" }}>
          <Button
            htmlType="submit"
            shape="round"
            style={
              selectedPaymentMethod === PaymentMethods.PRIVATE_INSURANCE
                ? {
                    height: 44,
                    width: 160,
                    color: "#fa9a5a",
                    borderColor: "#fa9a5a",
                  }
                : { height: 44, width: 160 }
            }
            onClick={() => {
              setSelectedPaymentMethod(PaymentMethods.PRIVATE_INSURANCE);
              setTempPrimaryPayerId("");
              setCardFrontFileList([]);
              setCardBackFileList([]);
            }}
          >
            Private Insurance
          </Button>
          <Button
            htmlType="submit"
            shape="round"
            style={
              selectedPaymentMethod === PaymentMethods.MEDICAID
                ? {
                    height: 44,
                    width: 120,
                    color: "#fa9a5a",
                    borderColor: "#fa9a5a",
                  }
                : { height: 44, width: 120 }
            }
            onClick={() => {
              setSelectedPaymentMethod(PaymentMethods.MEDICAID);
              setTempPrimaryPayerId(MEDICAID_PAYER_TEXT);
              setCardFrontFileList([]);
              setCardBackFileList([]);
            }}
          >
            Medicaid
          </Button>
          <Button
            htmlType="submit"
            shape="round"
            style={
              selectedPaymentMethod === PaymentMethods.CASH
                ? {
                    height: 44,
                    width: 120,
                    color: "#fa9a5a",
                    borderColor: "#fa9a5a",
                  }
                : { height: 44, width: 120 }
            }
            onClick={() => {
              setSelectedPaymentMethod(PaymentMethods.CASH);
              setTempPrimaryPayerId(CASH_PAYER_TEXT);
              setCardFrontFileList([]);
              setCardBackFileList([]);
            }}
          >
            Cash
          </Button>
        </Space>
        {selectedPaymentMethod === PaymentMethods.PRIVATE_INSURANCE && (
          <Row style={{ marginTop: 25, width: "100%" }} justify="center">
            <Col>
              <Select
                showSearch
                placeholder="Select a payer"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(e) => {
                  setTempPrimaryPayerId(e);
                }}
                style={{
                  width: 300,
                }}
              >
                {payers.map((payer) => (
                  <Select.Option key={payer.payerId}>{payer.name}</Select.Option>
                ))}
                <Select.Option key={"Other"}>{"Other"}</Select.Option>
              </Select>
            </Col>
          </Row>
        )}
        {(selectedPaymentMethod === PaymentMethods.PRIVATE_INSURANCE ||
          selectedPaymentMethod === PaymentMethods.MEDICAID) && (
          <>
            <Row style={{ marginTop: 25, width: "100%" }} justify="center">
              <Col>
                <Upload
                  {...uploadCardFrontProps}
                  fileList={cardFrontFileList}
                  style={{ width: 10 }}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload Front of Card</Button>
                </Upload>
              </Col>
            </Row>
            <Row style={{ marginTop: 10, width: "100%" }} justify="center">
              <Col>
                <Upload {...uploadCardBackProps} fileList={cardBackFileList}>
                  <Button icon={<UploadOutlined />}>Click to Upload Back of Card</Button>
                </Upload>
              </Col>
            </Row>
          </>
        )}
        <Row justify="end">
          <Button
            htmlType="submit"
            type="primary"
            shape="round"
            style={{ marginTop: 25 }}
            loading={isUploading || isLoading}
            disabled={tempPrimaryPayerId === ""}
            onClick={async () => {
              if (selectedPaymentMethod !== PaymentMethods.CASH) {
                setClientFilePayerStatus(ClientFilePayerStates.ENTER_SECONDARY_PAYMENT);
                setSelectedPaymentMethod(undefined);
              } else {
                setIsLoading(true);
                await FirestoreService.updateClientFile({
                  id: clientFile.id,
                  payers: {
                    primary: {
                      payerId: tempPrimaryPayerId,
                      photoUrls: [],
                    },
                  },
                });
                refreshContext();
                setIsLoading(false);
              }
            }}
          >
            Next
          </Button>
        </Row>
      </Card>
    );
  };

  const enterSecondaryPayment = () => {
    const uploadCardFrontProps = getUploadProps(
      cardFrontFileList,
      setCardFrontFileList,
      "secondary/front",
      setTempSecondaryFrontPhotoURL
    );

    const uploadCardBackProps = getUploadProps(
      cardBackFileList,
      setCardBackFileList,
      "secondary/back",
      setTempSecondaryBackPhotoURL
    );

    return (
      <Card
        title={
          <Title
            level={5}
            style={{
              fontWeight: 400,
              whiteSpace: "pre-wrap",
            }}
          >
            Do you have secondary insurance?
          </Title>
        }
        style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
      >
        <Space style={{ width: "100%", justifyContent: "center" }}>
          <Button
            htmlType="submit"
            shape="round"
            style={
              isSecondaryInsuranceVisible
                ? {
                    height: 44,
                    width: 160,
                    color: "#fa9a5a",
                    borderColor: "#fa9a5a",
                  }
                : { height: 44, width: 160 }
            }
            onClick={() => {
              setIsSecondaryInsuranceVisible(true);
            }}
          >
            Yes
          </Button>
          <Button
            htmlType="submit"
            shape="round"
            style={
              isSecondaryInsuranceVisible === false
                ? {
                    height: 44,
                    width: 160,
                    color: "#fa9a5a",
                    borderColor: "#fa9a5a",
                  }
                : { height: 44, width: 160 }
            }
            onClick={() => {
              setIsSecondaryInsuranceVisible(false);
              setSelectedPaymentMethod(undefined);
            }}
          >
            No
          </Button>
        </Space>

        {isSecondaryInsuranceVisible && (
          <Space style={{ marginTop: 25, width: "100%", justifyContent: "center" }}>
            <Button
              htmlType="submit"
              shape="round"
              style={
                selectedPaymentMethod === PaymentMethods.PRIVATE_INSURANCE
                  ? {
                      height: 44,
                      width: 160,
                      color: "#fa9a5a",
                      borderColor: "#fa9a5a",
                    }
                  : { height: 44, width: 160 }
              }
              onClick={() => {
                setSelectedPaymentMethod(PaymentMethods.PRIVATE_INSURANCE);
                setTempSecondaryPayerId("");
                setCardFrontFileList([]);
                setCardBackFileList([]);
              }}
            >
              Private Insurance
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={
                selectedPaymentMethod === PaymentMethods.MEDICAID
                  ? {
                      height: 44,
                      width: 160,
                      color: "#fa9a5a",
                      borderColor: "#fa9a5a",
                    }
                  : { height: 44, width: 160 }
              }
              onClick={() => {
                setSelectedPaymentMethod(PaymentMethods.MEDICAID);
                setTempSecondaryPayerId(MEDICAID_PAYER_TEXT);
                setCardFrontFileList([]);
                setCardBackFileList([]);
              }}
            >
              Medicaid
            </Button>
          </Space>
        )}

        {selectedPaymentMethod === PaymentMethods.PRIVATE_INSURANCE && (
          <Space style={{ marginTop: 25, width: "100%", justifyContent: "center" }}>
            <Select
              showSearch
              placeholder="Select a payer"
              filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => {
                setTempSecondaryPayerId(e);
              }}
              style={{
                width: 300,
              }}
            >
              {payers.map((payer) => (
                <Select.Option key={payer.payerId}>{payer.name}</Select.Option>
              ))}
              <Select.Option key="Other">Other</Select.Option>
            </Select>
          </Space>
        )}

        {selectedPaymentMethod !== undefined && (
          <>
            <Row style={{ marginTop: 25, width: "100%" }} justify="center">
              <Col>
                <Upload {...uploadCardFrontProps} style={{ width: 10 }}>
                  <Button icon={<UploadOutlined />}>Click to Upload Front of Card</Button>
                </Upload>
              </Col>
            </Row>
            <Row style={{ marginTop: 10, width: "100%" }} justify="center">
              <Col>
                <Upload {...uploadCardBackProps}>
                  <Button icon={<UploadOutlined />}>Click to Upload Back of Card</Button>
                </Upload>
              </Col>
            </Row>
          </>
        )}
        <Row justify="space-between" style={{ marginTop: 25 }}>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              setClientFilePayerStatus(clientFilePayerStatus - 1);
            }}
          >
            Back
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            shape="round"
            loading={isUploading || isLoading}
            disabled={
              isSecondaryInsuranceVisible === undefined ||
              (selectedPaymentMethod === undefined && isSecondaryInsuranceVisible) ||
              (selectedPaymentMethod === PaymentMethods.PRIVATE_INSURANCE &&
                tempSecondaryPayerId === "")
            }
            onClick={async () => {
              setIsLoading(true);
              const primaryPayer: IClientPayer = {
                payerId: tempPrimaryPayerId,
                photoUrls: [tempPrimaryFrontPhotoURL || "", tempPrimaryBackPhotoURL || ""],
              };

              let secondaryPayer: IClientPayer;
              if (isSecondaryInsuranceVisible) {
                secondaryPayer = {
                  payerId: tempSecondaryPayerId,
                  photoUrls: [tempSecondaryFrontPhotoURL || "", tempSecondaryBackPhotoURL || ""],
                };
              }

              await FirestoreService.updateClientFile({
                id: clientFile.id,
                payers: {
                  primary: primaryPayer,
                  ...(isSecondaryInsuranceVisible && {
                    secondary: secondaryPayer!,
                  }),
                },
              });
              refreshContext();
              setIsLoading(false);
            }}
          >
            Next
          </Button>
        </Row>
      </Card>
    );
  };

  return (
    <>
      {clientFilePayerStatus === ClientFilePayerStates.ENTER_PRIMARY_PAYMENT &&
        enterPrimaryPayment()}

      {clientFilePayerStatus === ClientFilePayerStates.ENTER_SECONDARY_PAYMENT &&
        enterSecondaryPayment()}
    </>
  );
};
