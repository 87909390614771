import { Badge, Col, Row, Typography } from "antd";

import { COLORS } from "../consts";

const { Title, Text } = Typography;

interface Props {
  title: string;
  description?: string;
  count?: number;
  selected?: boolean;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  dataTest?: string;
}

export const TitleWithBadge = ({
  title,
  description,
  count,
  selected,
  style,
  titleStyle,
  descriptionStyle,
  dataTest,
}: Props) => {
  return (
    <Row align="middle" style={{ flexWrap: "nowrap", ...style }} data-test={dataTest}>
      <Col style={{ marginRight: 8 }}>
        <Title
          style={{
            fontWeight: 500,
            fontSize: 18,
            color: selected ? "#000" : COLORS.GRAY_5,
            marginBottom: 0,
            transition: "all 0.3s ease-in-out",
            ...titleStyle,
          }}
        >
          {title}
        </Title>
        {description !== undefined && <Text style={{ ...descriptionStyle }}>{description}</Text>}
      </Col>
      {count !== undefined && (
        <Badge
          count={count}
          overflowCount={999}
          style={{
            fontSize: 12,
            fontWeight: 500,
            transition: "all 0.3s",
          }}
          color={selected ? COLORS.PRIMARY : COLORS.GRAY_5}
        />
      )}
    </Row>
  );
};
