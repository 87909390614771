import {
  getInitials,
  HiringStatus,
  IClient,
  IClientFile,
  ICreateClientEndpointRequest,
  Sex,
} from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Card, DatePicker, Form, Input, Row, Select, Typography } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";

import { DATE_FORMAT } from "../../consts";
import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";

const { Title } = Typography;

interface IProps {
  clientFile: IClientFile;
  setClientFile: (clientFile: IClientFile) => void;
}

export const ClientDetails: React.FC<IProps> = ({ clientFile, setClientFile }: IProps) => {
  const { guardian } = useContext(AuthContext);

  const { isMobile } = useResponsive();
  const [tempClient, setTempClient] = useState<IClient>({} as IClient);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const saveClientDetails = async () => {
    setIsSaving(true);
    const request: ICreateClientEndpointRequest = {
      clinicId: guardian.clinicId,
      guardianId: guardian.id,
      alias: getInitials(tempClient.firstName, tempClient.lastName),
      firstName: tempClient.firstName,
      middleName: tempClient.middleName || "",
      preferredName: "",
      lastName: tempClient.lastName,
      dateOfBirth: tempClient.dateOfBirth,
      sex: tempClient.sex,

      address: clientFile.address,
      intakeStatus: clientFile.intakeStatus,
      hiringStatus: HiringStatus.NONE,
      isHot: clientFile.isHot,
      payers: clientFile.payers,
      scheduleBlocks: clientFile.scheduleBlocks,
      documents: clientFile.documents,
      signedDocuments: clientFile.signedDocuments,
      payerVerificationPatientId: clientFile.payerVerificationPatientId,
    };

    const clientId = await FirestoreService.createClient(request);

    await FirestoreService.updateClientFile({ id: clientFile.id, clientId });
    setClientFile({ ...clientFile, clientId });
    setIsSaving(false);
  };

  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 500 }}>
          Who is the child recieving care?
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Form layout="vertical" labelCol={{ span: 24 }} onFinish={saveClientDetails}>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please enter your child's first name",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            onChange={(e) => {
              setTempClient({ ...tempClient, firstName: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Middle Name"
          name="middleName"
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            onChange={(e) => {
              setTempClient({ ...tempClient, middleName: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please enter your child's last name",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            onChange={(e) => {
              setTempClient({ ...tempClient, lastName: e.target.value });
            }}
          />
        </Form.Item>
        <Form.Item
          label="Sex at birth"
          name="sex"
          rules={[
            {
              required: true,
              message: "Please select your child's sex at birth",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Select
            tabIndex={4}
            onChange={(sex) => {
              setTempClient({ ...tempClient, sex });
            }}
          >
            <Select.Option value={Sex.FEMALE}>Female</Select.Option>
            <Select.Option value={Sex.MALE}>Male</Select.Option>
            <Select.Option value={Sex.UNKNOWN}>Other</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Date of Birth"
          name="dateOfBirth"
          rules={[
            {
              required: true,
              message: "Please enter your child's date of birth",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <DatePicker
            placeholder="MM-DD-YYYY"
            format="MM-DD-YYYY"
            disabledDate={(curr) => curr && curr > moment().startOf("day")}
            onChange={(date) => {
              setTempClient({
                ...tempClient,
                dateOfBirth: moment(date).format(DATE_FORMAT),
              });
            }}
          />
        </Form.Item>
        <Row justify="end">
          <Button htmlType="submit" type="primary" shape="round" loading={isSaving}>
            Next
          </Button>
        </Row>
      </Form>
    </Card>
  );
};
