import { z } from "zod";

import { DropboxDocumentType } from "../services/dropboxSignService/types";
import {
  addressSchema,
  BehaviorFrequency,
  BehaviorSeverity,
  ClientDocumentType,
  clientPayerSchema,
  contactSchema,
  DayBlock,
  HiringStatus,
  IClient,
  IGoals,
  IntakeStatus,
  ScheduleBlock,
  Sex,
  TherapistGender,
  WeekBlock,
} from "../types";

export const clientDocumentSchema = z.object({
  path: z.string(),
  type: z.nativeEnum(ClientDocumentType),
});

export const signedDropboxDocumentSchema = z.object({
  documentType: z.nativeEnum(DropboxDocumentType),
  signedAt: z.number(),
  signatureRequestId: z.string(),
  signerEmail: z.string(),
  signerName: z.string(),
  signerRole: z.string(),
});

export const therapyAvailabilitySchema = z.object({
  [WeekBlock.MON_WED_FRI]: z.array(z.nativeEnum(DayBlock)).optional(),
  [WeekBlock.TUES_THURS]: z.array(z.nativeEnum(DayBlock)).optional(),
});

export const therapyPreferencesSchema = z.object({
  primaryLanguage: z.string(),
  preferredLanguage: z.string(),
  preferredTherapistGender: z.nativeEnum(TherapistGender),
  secondaryContact: contactSchema.optional(),
  availability: therapyAvailabilitySchema,
});

export const selfHarmSchema = z.object({
  severity: z.nativeEnum(BehaviorSeverity),
  frequency: z.nativeEnum(BehaviorFrequency),
  requireMedicalAttn: z.boolean(),
  headDirected: z.boolean(),
});

export const aggressionSchema = z.object({
  severity: z.nativeEnum(BehaviorSeverity),
  frequency: z.nativeEnum(BehaviorFrequency),
  requireMedicalAttn: z.boolean(),
  bite: z.boolean(),
  weapons: z.boolean(),
});

export const runAwaySchema = z.object({
  severity: z.nativeEnum(BehaviorSeverity),
  frequency: z.nativeEnum(BehaviorFrequency),
  leaveHome: z.boolean(),
});

export const destroyPropertySchema = z.object({
  severity: z.nativeEnum(BehaviorSeverity),
  frequency: z.nativeEnum(BehaviorFrequency),
  targetHighValue: z.boolean(),
  targetGlass: z.boolean(),
  structuralDamage: z.boolean(),
});

export const flopOnGroundSchema = z.object({
  severity: z.nativeEnum(BehaviorSeverity),
  frequency: z.nativeEnum(BehaviorFrequency),
  inHome: z.boolean(),
  outsideHome: z.boolean(),
});

export const takeOffClothesSchema = z.object({
  frequency: z.nativeEnum(BehaviorFrequency),
});

const behaviorsSchema = z.object({
  selfHarm: selfHarmSchema.optional(),
  aggression: aggressionSchema.optional(),
  runAway: runAwaySchema.optional(),
  destroyProperty: destroyPropertySchema.optional(),
  flopOnGround: flopOnGroundSchema.optional(),
  takeOffClothes: takeOffClothesSchema.optional(),
  goals: z.array(z.nativeEnum(IGoals)).optional(),
  otherGoals: z.string().optional(),
});

export const medicalHistorySchema = z.object({
  currentServices: z.array(z.string()).optional(),
  pastServices: z.array(z.string()).optional(),
  livingSituation: z.string(),
  existingDiagnosis: z.string().optional(),
  medication: z.string().optional(),
  mentalIllness: z.string().optional(),
  treatmentImpairments: z.string().optional(),
  visionHearingImpairment: z.boolean(),
});

export const createClientEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  guardianId: z.string().min(1),
  firstName: z.string().min(1),
  middleName: z.string(),
  preferredName: z.string(),
  lastName: z.string().min(1),
  alias: z.string(),
  dateOfBirth: z.string().pipe(z.coerce.date()),
  sex: z.nativeEnum(Sex),

  assignedUserId: z.string().optional(),
  assignedBcbaId: z.string().optional(),
  assignedRbtIds: z.array(z.string()).optional(),
  address: addressSchema,
  addressNotes: z.string().optional(),
  intakeStatus: z.nativeEnum(IntakeStatus),
  intakeStatusNotes: z.string().optional(),
  hiringStatus: z.nativeEnum(HiringStatus),
  isHot: z.boolean(),
  payers: z.object({
    primary: clientPayerSchema.optional(),
    secondary: clientPayerSchema.optional(),
  }),
  scheduleNotes: z.string().optional(),
  scheduleBlocks: z.array(z.nativeEnum(ScheduleBlock)),
  documents: z.array(clientDocumentSchema),
  signedDocuments: z.array(signedDropboxDocumentSchema).optional(),
  therapyPreferences: therapyPreferencesSchema.optional(),
  behaviors: behaviorsSchema.optional(),
  medicalHistory: medicalHistorySchema.optional(),
  payerVerificationPatientId: z.string().nullable(),
});

export type ICreateClientEndpointRequest = z.input<typeof createClientEndpointRequestSchema>;

export interface IDeleteClientEndpointRequest {
  id: string;
}

export const getAllClientsByUserAndClinicSchema = z.object({
  userId: z.string().nonempty(),
  clinicId: z.string().nonempty(),
});
export type IGetAllClientsByUserAndClinicSchema = z.input<
  typeof getAllClientsByUserAndClinicSchema
>;

export type IUpdateClientEndpointRequest = Partial<IClient> & Pick<IClient, "id">;
