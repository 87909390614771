import {
  CaretRightOutlined,
  HistoryOutlined,
  ScheduleOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { IClientFile } from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import {
  getRemainingIntakeStepsCount,
  isBehaviorsComplete,
  isMedicalHistoryComplete,
  isTherapyPreferencesComplete,
} from "../../helpers/client-file-status";
import { TasksCardTask } from "./TasksCardTask";

const { Title } = Typography;

interface IProps {
  clientFile: IClientFile;
}

export const IntakeTasksCard: React.FC<IProps> = ({ clientFile }: IProps) => {
  const { isMobile } = useResponsive();

  const remainingSteps = getRemainingIntakeStepsCount(clientFile);

  return (
    <Card
      style={{ width: "100%" }}
      title={
        <Row align="middle" justify="space-between">
          <Col span={isMobile ? 24 : undefined}>
            <Title level={4} style={{ marginTop: 5, marginBottom: isMobile ? 10 : 5 }}>
              You have {remainingSteps} quick task
              {remainingSteps > 1 && "s"} {remainingSteps !== 3 && "left"}
            </Title>
          </Col>
          <Col>
            <Link to="/intake-questionnaire">
              <Button type="primary" shape="round">
                {`Let's go`}
                <CaretRightOutlined />
              </Button>
            </Link>
          </Col>
        </Row>
      }
    >
      <TasksCardTask
        icon={<ScheduleOutlined />}
        isComplete={isTherapyPreferencesComplete(clientFile?.therapyPreferences)}
        title={"Therapy Preferences"}
        subtitle={"Preferred language, therapist's gender, and availability"}
      />
      <Divider />
      <TasksCardTask
        icon={<SlidersOutlined />}
        isComplete={isBehaviorsComplete(clientFile?.behaviors)}
        title={"Behaviors"}
        subtitle={"Your child's behavior frequency and severity"}
      />
      <Divider />
      <TasksCardTask
        icon={<HistoryOutlined />}
        isComplete={isMedicalHistoryComplete(clientFile?.medicalHistory)}
        title={"Medical History"}
        subtitle={"Your child's treatments and medications"}
      />
    </Card>
  );
};
