import { IClientFile, IGoals } from "@finni-health/shared";
import { Button, Card, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import React, { ReactNode } from "react";

import { selectedToggle, unselectedToggle } from "../../../consts";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempBehaviors: any;
  setTempBehaviors: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClientFile: (req: Partial<IClientFile>) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterGoals: React.FC<IProps> = ({
  isMobile,
  tempBehaviors,
  setTempBehaviors,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClientFile,
  backButton,
}: IProps) => {
  const toggleGoal = (goal: IGoals) => {
    const newTempBehaviors = tempBehaviors;
    let tempGoals = tempBehaviors.goals || [];

    if (tempBehaviors.goals?.includes(goal)) {
      tempGoals = tempGoals.filter((tempGoal: IGoals) => {
        return tempGoal !== goal;
      });
    } else {
      tempGoals.push(goal);
    }

    if (_.isEmpty(tempGoals)) {
      delete newTempBehaviors.goals;
    } else {
      newTempBehaviors.goals = tempGoals;
    }

    setTempBehaviors({ ...newTempBehaviors });
  };

  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          {`What are your goals for your child's care?`}
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempBehaviors.goals?.includes(IGoals.COMMUNICATION_SKILLS)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleGoal(IGoals.COMMUNICATION_SKILLS);
          }}
        >
          Enhance Communication Skills
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempBehaviors.goals?.includes(IGoals.CHALLENGING_BEHAVIOR)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleGoal(IGoals.CHALLENGING_BEHAVIOR);
          }}
        >
          Decrease Challenging Behavior
        </Button>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempBehaviors.goals?.includes(IGoals.INDEPENDENCE) ? selectedToggle : unselectedToggle
          }
          onClick={() => {
            toggleGoal(IGoals.INDEPENDENCE);
          }}
        >
          Improve Independence
        </Button>
      </Row>

      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempBehaviors.goals?.includes(IGoals.SOCIAL_SKILLS) ? selectedToggle : unselectedToggle
          }
          onClick={() => {
            toggleGoal(IGoals.SOCIAL_SKILLS);
          }}
        >
          Improve Social Skills
        </Button>
      </Row>

      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempBehaviors.goals?.includes(IGoals.FOLLOW_INSTRUCTIONS)
              ? selectedToggle
              : unselectedToggle
          }
          onClick={() => {
            toggleGoal(IGoals.FOLLOW_INSTRUCTIONS);
          }}
        >
          Improve Ability to Follow Instructions
        </Button>
      </Row>

      <Row
        justify="center"
        style={{
          width: "100%",
          marginBottom: 25,
        }}
      >
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.otherGoals !== undefined ? selectedToggle : unselectedToggle}
          onClick={() => {
            if (tempBehaviors.otherGoals === undefined) {
              setTempBehaviors({ ...tempBehaviors, otherGoals: "" });
            } else {
              const newTempBehaviors = tempBehaviors;
              delete newTempBehaviors.otherGoals;
              setTempBehaviors({ ...newTempBehaviors });
            }
          }}
        >
          Other
        </Button>
      </Row>
      {tempBehaviors.otherGoals !== undefined && (
        <Row
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <TextArea
            autoSize
            defaultValue={tempBehaviors.otherGoals}
            placeholder="Let us know what other areas you would like to see your child improve in."
            style={{ marginBottom: 25, maxWidth: 400 }}
            onChange={(e) => {
              setTempBehaviors({
                ...tempBehaviors,
                otherGoals: e.target.value,
              });
            }}
          />
        </Row>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={
            (tempBehaviors.goals === undefined && tempBehaviors.otherGoals === undefined) ||
            tempBehaviors.otherGoals === ""
          }
          type="primary"
          shape="round"
          onClick={() => {
            return updateClientFile({
              behaviors: tempBehaviors,
            }).then(() => {
              setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
            });
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
