import {
  CaretRightOutlined,
  FileOutlined,
  FilePdfOutlined,
  MedicineBoxOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { DROPBOX_CONTRACTS, DropboxDocumentType, IClientFile } from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import {
  getRemainingPreapprovalStepsCount,
  isClientDetailsComplete,
  isDocumentsComplete,
  isGeneralConsentSigned,
  isPayersComplete,
} from "../../helpers/client-file-status";
import { TasksCardTask } from "./TasksCardTask";

const { Title } = Typography;

interface IProps {
  clientFile: IClientFile;
}

export const PreapprovalTasksCard: React.FC<IProps> = ({ clientFile }: IProps) => {
  const { isMobile } = useResponsive();

  const remainingSteps = getRemainingPreapprovalStepsCount(clientFile);

  return (
    <Card
      style={{ width: "100%" }}
      title={
        <Row align="middle" justify="space-between">
          <Col span={isMobile ? 24 : undefined}>
            <Title level={4} style={{ marginTop: 5, marginBottom: isMobile ? 10 : 5 }}>
              You have {remainingSteps} quick task
              {remainingSteps > 1 && "s"} {remainingSteps !== 3 && "left"}
            </Title>
          </Col>
          <Col>
            <Link to="/preapproval">
              <Button type="primary" shape="round">
                {`Let's go`}
                <CaretRightOutlined />
              </Button>
            </Link>
          </Col>
        </Row>
      }
    >
      <TasksCardTask
        icon={<SmileOutlined />}
        isComplete={isClientDetailsComplete(clientFile)}
        title={"Child Details"}
        subtitle={"Your child's name, date of birth, and birth sex"}
      />
      <Divider />
      <TasksCardTask
        icon={<MedicineBoxOutlined />}
        isComplete={isDocumentsComplete(clientFile?.documents)}
        title={"Care Documents"}
        subtitle={"Previous diagnosis information, recommendation of ABA, etc."}
      />
      <Divider />
      <TasksCardTask
        icon={<FileOutlined />}
        isComplete={isPayersComplete(clientFile)}
        title={"Insurance"}
        subtitle={"Insurance details and photo of card"}
      />
      <Divider />
      <TasksCardTask
        icon={<FilePdfOutlined />}
        isComplete={isGeneralConsentSigned(clientFile)}
        title={DROPBOX_CONTRACTS[DropboxDocumentType.CAREGIVER_CONSENT_FORM].title}
        subtitle={"Required handbooks and consent forms"}
      />
    </Card>
  );
};
