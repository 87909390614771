import { IClinic } from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Form, Input, Typography } from "antd";
import React, { useState } from "react";
import Lottie from "react-lottie-player";
import { Link } from "react-router-dom";

import { InquiryStates, VALID_ZIP_CODE_REGEX } from "../../consts";
import foxHiAnimation from "../../lottie/fox-hi.json";
import { IInquiryForm } from "../../pages/InquiryFlow";
import * as FirestoreService from "../../services/firestore";

const { Title, Text } = Typography;

type IProps = {
  inquiry: IInquiryForm;
  setInquiryStatus: (value: any) => void;
  setClinic: (value: IClinic) => void;
  updateInquiry: (source: any) => void;
};

export const EnterZipCode: React.FC<IProps> = ({
  inquiry,
  setInquiryStatus,
  setClinic,
  updateInquiry,
}: IProps) => {
  const [playAnimation, setPlayAnimation] = useState<boolean>(true);
  const [pauseAnimation, setPauseAnimation] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isMobile } = useResponsive();

  const submitZipCode = async (zipCode: string) => {
    setIsLoading(true);
    let clinic;
    try {
      clinic = await FirestoreService.getClinicByZipCode(zipCode);
    } catch (err) {
      console.log(`Error getting clinic: ${err}`);
    }
    setIsLoading(false);
    if (clinic !== undefined) {
      updateInquiry({ clinicId: clinic.id });
      setInquiryStatus(InquiryStates.ENTER_GUARDIAN_STATUS);
      setClinic(clinic);
    } else {
      setInquiryStatus(InquiryStates.UNSUPPORTED_ZIP_CODE);
    }
  };

  return (
    <>
      <div style={{ marginTop: isMobile ? -20 : -60, marginBottom: 40 }}>
        {playAnimation ? (
          <Lottie
            play
            animationData={foxHiAnimation}
            style={{ width: 125, height: 125, margin: "auto" }}
            onLoopComplete={() => {
              setPlayAnimation(false);
            }}
          />
        ) : (
          <Lottie
            play={!pauseAnimation}
            goTo={pauseAnimation ? 56 : undefined}
            animationData={foxHiAnimation}
            segments={[27, 80]}
            style={{ width: 125, height: 125, margin: "auto" }}
            onLoopComplete={() => {
              setPauseAnimation(true);
              setTimeout(function () {
                setPauseAnimation(false);
              }, 2000);
            }}
          />
        )}
      </div>
      <Title style={{ fontFamily: "Reckless Neue" }}>Welcome to Finni</Title>
      <Text style={{ display: "block", marginBottom: 30, fontWeight: 400 }}>
        Enter your zip code so we can match you with a local therapist.
      </Text>
      <Form onFinish={() => submitZipCode(inquiry.zipCode)}>
        <Form.Item
          name="zipCode"
          rules={[
            {
              required: true,
              pattern: VALID_ZIP_CODE_REGEX,
              message: "Please enter a 5 digit zip code",
            },
          ]}
          style={{ display: "inline-block", width: 160, margin: "auto" }}
        >
          <Input
            name="zipCode"
            maxLength={5}
            onChange={(value) => {
              updateInquiry({ zipCode: value.target.value });
            }}
            style={{
              textAlign: "center",
              borderRadius: 50,
              padding: 10,
            }}
          />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          loading={isLoading}
          style={{
            marginLeft: 20,
            height: 44,
          }}
        >
          Get Started
        </Button>
        <Typography.Text style={{ display: "block", marginTop: 30, fontWeight: 400 }}>
          Already have an account? <Link to="/login">Log In</Link>
        </Typography.Text>
      </Form>
    </>
  );
};
