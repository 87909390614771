import { z } from "zod";

import { authCodeSchema } from "../types";

export const createAuthorizationEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  clientFileId: z.string().min(1),
  payerId: z.string().min(1),
  isNoAuthRequired: z.boolean(),
  authNumber: z.string(),
  dx: z.string().min(1),
  startDate: z.string().pipe(z.coerce.date()).nullable(),
  endDate: z.string().pipe(z.coerce.date()).nullable(),
  authCodes: authCodeSchema,
});

export type ICreateAuthorizationEndpointRequest = z.input<
  typeof createAuthorizationEndpointRequestSchema
>;

export const getAuthorizationEndpointRequestSchema = z.object({
  clientFileId: z.string().min(1),
  id: z.string().min(1),
});

export type IGetAuthorizationEndpointRequest = z.input<
  typeof getAuthorizationEndpointRequestSchema
>;

export const getAllAuthorizationEndpointRequestSchema = z
  .object({
    clientId: z.string().min(1),
    payerId: z.string().min(1),
    timeMs: z.number().optional(),
  })
  .strict();

export type IGetAllAuthorizationsEndpointRequest = z.input<
  typeof getAllAuthorizationEndpointRequestSchema
>;

export const getAllAuthsAllPayersEndpointRequestSchema = z
  .object({
    clientId: z.string().min(1),
    timeMs: z.number().optional(),
  })
  .strict();

export type IGetAllAuthsAllPayersEndpointRequest = z.input<
  typeof getAllAuthsAllPayersEndpointRequestSchema
>;

export const getActiveAuthorizationsEndpointRequestSchema = z.object({
  clientFileId: z.string().min(1),
});

export type IGetActiveAuthorizationsEndpointRequest = z.input<
  typeof getActiveAuthorizationsEndpointRequestSchema
>;

export const updateAuthorizationEndpointRequestSchema = z.object({
  id: z.string().min(1),
  clientFileId: z.string().min(1),
  clinicId: z.string().min(1),
  payerId: z.string().min(1).optional(),
  isNoAuthRequired: z.boolean().optional(),
  authNumber: z.string().optional(),
  dx: z.string().min(1).optional(),
  startDate: z.string().pipe(z.coerce.date()).nullable().optional(),
  endDate: z.string().pipe(z.coerce.date()).nullable().optional(),
  authCodes: authCodeSchema.optional(),
});

export type IUpdateAuthorizationEndpointRequest = z.input<
  typeof updateAuthorizationEndpointRequestSchema
>;

export const deleteAuthorizationEndpointRequestSchema = z.object({
  clientFileId: z.string().min(1),
  clinicId: z.string().min(1),
  id: z.string().min(1),
});

export type IDeleteAuthorizationEndpointRequest = z.input<
  typeof deleteAuthorizationEndpointRequestSchema
>;
