import moment from "moment";

// Datetime
export const TIME_FORMAT = "HH:mm:ss Z";
export const DISPLAY_TIME_FORMAT = "h:mma";
export const MILITARY_TIME_FORMAT = "HH:mmz";
export const DB_DATE_FORMAT = "YYYY-MM-DD";
export const PAYER_VERIFICATION_DATE_FORMAT = "MM/DD/YYYY";
export const DISPLAY_DATE_FORMAT = "M/D/YYYY";
export const GRAPH_DISPLAY_DATE_FORMAT = "MMM D h:mma";
export const DISPLAY_DATETIME_FORMAT = "MM/DD/YYYY [at] h:mma";
export const WEEKDAY_DATETIME_FORMAT = "ddd MMM Do";

export const HOURS = Array.from(Array(24).keys());
export const AM_HOURS = HOURS.slice(0, 12);
export const PM_HOURS = HOURS.slice(12);

// IDs
export const VIRGINIA_CLINIC_ID = "Rhza4bU6N1GLnF0LcEnI";
export const MASTER_CLINIC_ID = "00000000000000000001";
export const DEMO_CLINIC_ID = "000";
export const TEST_CLINIC_ID = "001";

export const SLACK_CHANNEL_IDS = {
  "provider-success": "C04LSEWCVLP",
  "billing-credentialing": "C04R5GQHSE5",
  "revenue-cycle-management": "C06TL701X44",
};

// Timezones
export const US_TIMEZONES: Record<string, string> = {
  // Standard
  EST: "America/New_York",
  CST: "America/Chicago",
  MST: "America/Denver",
  PST: "America/Los_Angeles",
  AST: "America/Anchorage",
  HST: "Pacific/Honolulu",
  // Daylight Savings
  EDT: "America/New_York",
  CDT: "America/Chicago",
  MDT: "America/Denver",
  PDT: "America/Los_Angeles",
  HDT: "Pacific/Honolulu",
  ADT: "America/Anchorage",
};

export const MOTIVITY_NOTE_BASE_URL =
  "https://app.motivity.net/orgs/-1Fu5VnIJ0OWP3NyAflEWg/psn/ppsn/snote";

export const MISSION_CONTROL_BASE_URL = "https://mission-control.finnihealth.com";

export const START_OF_COMPLETION_TRACKING = moment("2022-12-14");

export const SUGGESTED_APPOINTMENT_ID = "suggested";

// Error Messages
export const GENERAL_ERROR_MESSAGE =
  "Something went wrong, please try again or reach out via in-app chat.";
export const CONTACT_WITH_SCREENSHOT_ERROR_MESSAGE =
  "Please contact the product team with a screenshot of this message.";
// Misc
export const CANCELLED_APPOINTMENT_TEXT = "(CANCELLED) ";
export const CLIENT_FILE_ID_HASH_SALT = "JourneyCanada";

export const FAKE_NOTE_ID_SUFFIX = "_FAKE";
export const SUGGESTED_SCHEDULE_APPOINTMENT_ID = "suggested-schedule-appointment-id";
export const SUGGESTED_SCHEDULE_EXCLUDING_APPOINTMENTS_APPOINTMENT_ID =
  "suggested-schedule-excluding-appointments-id";

export const ONCALL_TOOL_COLOR = "#F8EFFF";
export const ONCALL_TOOL_BORDER_COLOR = "#D3ADF7";

export enum HttpMethods {
  GET = "get",
  POST = "post",
  PUT = "put",
  DELETE = "delete",
  OPTIONS = "options",
  PATCH = "patch",
  HEAD = "head",
}
