import { BehaviorFrequency } from "@finni-health/shared";

export const getBehaviorFrequency = (e: number) => {
  switch (e) {
    case 0:
      return BehaviorFrequency.MONTHLY;
    case 1:
      return BehaviorFrequency.WEEKLY;
    case 2:
      return BehaviorFrequency.DAILY;
    case 3:
      return BehaviorFrequency.HOURLY;
    case 4:
      return BehaviorFrequency.CONSTANTLY;
  }
};
