import { Row } from "antd";
import React from "react";
import Lottie from "react-lottie-player";

import foxHiAnimation from "../lottie/fox-hi.json";

export const Loading: React.FC = () => {
  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <div>
        <Lottie play loop animationData={foxHiAnimation} style={{ width: 100, height: 100 }} />
      </div>
    </Row>
  );
};
