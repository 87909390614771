import { BehaviorFrequency, BehaviorSeverity, IClientFile } from "@finni-health/shared";
import { Button, Card, Radio, Row, Slider, Space, Typography } from "antd";
import React, { ReactNode } from "react";

import { frequencyMarks, selected, unselected } from "../../../consts";
import { getBehaviorFrequency } from "../../../helpers/get-behavior-frequency";
import { getBehaviorFrequencyValue } from "../../../helpers/get-behavior-frequency-value";

const { Title, Text } = Typography;

type IProps = {
  isMobile: boolean;
  tempBehaviors: any;
  setTempBehaviors: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClientFile: (req: Partial<IClientFile>) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterSelfHarm: React.FC<IProps> = ({
  isMobile,
  tempBehaviors,
  setTempBehaviors,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClientFile,
  backButton,
}: IProps) => {
  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          Does your child hurt themselves in any way?
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.selfHarm !== undefined ? selected : unselected}
          onClick={() => {
            setTempBehaviors({
              ...tempBehaviors,
              selfHarm: {
                ...tempBehaviors.selfHarm!,
                frequency: tempBehaviors.selfHarm?.frequency || BehaviorFrequency.MONTHLY,
              },
            });
          }}
        >
          Yes
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.selfHarm === undefined ? selected : unselected}
          onClick={() => {
            const newTempBehaviors = tempBehaviors;
            delete newTempBehaviors.selfHarm;
            setTempBehaviors({ ...newTempBehaviors });
          }}
        >
          No
        </Button>
      </Space>
      {tempBehaviors.selfHarm !== undefined && (
        <>
          Severity
          <Radio.Group
            style={{ marginBottom: 25 }}
            onChange={(e) => {
              setTempBehaviors({
                ...tempBehaviors,
                selfHarm: {
                  ...tempBehaviors.selfHarm!,
                  severity: e.target.value,
                },
              });
            }}
            value={tempBehaviors.selfHarm.severity}
          >
            <Space direction="vertical">
              <Radio value={BehaviorSeverity.LOW}>
                No marks on body, no burns, no choking, no targeting eyes
              </Radio>
              <Radio value={BehaviorSeverity.MEDIUM}>
                Reddening of skin or mild swelling, mild gagging, 1st degree burns
              </Radio>
              <Radio value={BehaviorSeverity.HIGH}>
                Light scratches, small or shallow breaks in skin, moderate to severe swelling, 2nd
                degree burns, vomiting or significant choking
              </Radio>
              <Radio value={BehaviorSeverity.EXTREME}>
                Resulting in scars, lasting tissue damage, disfigurement, 3rd degree burns,
                poisoning, loss of consciousness, targeting eyes, broken bones
              </Radio>
            </Space>
          </Radio.Group>
          Frequency
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Slider
              tooltipVisible={false}
              max={4}
              marks={frequencyMarks}
              step={null}
              onAfterChange={(e) => {
                setTempBehaviors({
                  ...tempBehaviors,
                  selfHarm: {
                    ...tempBehaviors.selfHarm!,
                    frequency: getBehaviorFrequency(e),
                  },
                });
              }}
              style={{ width: "75%", marginBottom: 50, alignSelf: "center" }}
              value={getBehaviorFrequencyValue(tempBehaviors.selfHarm.frequency)}
            />
          </div>
          <Text>
            Does your child require medical attention beyond minor first aid as a result of
            self-injury?
          </Text>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.selfHarm?.requireMedicalAttn ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  selfHarm: {
                    ...tempBehaviors.selfHarm!,
                    requireMedicalAttn: true,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.selfHarm?.requireMedicalAttn === false ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  selfHarm: {
                    ...tempBehaviors.selfHarm!,
                    requireMedicalAttn: false,
                  },
                });
              }}
            >
              No
            </Button>
          </Space>
          <Text>
            Does your child perform head-directed self-injurious behavior that targets sharp
            corners, glass or concrete-like surfaces?
          </Text>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.selfHarm?.headDirected ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  selfHarm: {
                    ...tempBehaviors.selfHarm!,
                    headDirected: true,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.selfHarm?.headDirected === false ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  selfHarm: {
                    ...tempBehaviors.selfHarm!,
                    headDirected: false,
                  },
                });
              }}
            >
              No
            </Button>
          </Space>
        </>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={
            tempBehaviors.selfHarm !== undefined &&
            (tempBehaviors.selfHarm?.requireMedicalAttn === undefined ||
              tempBehaviors.selfHarm?.headDirected === undefined ||
              tempBehaviors.selfHarm?.severity === undefined)
          }
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClientFile({
              behaviors: tempBehaviors,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
