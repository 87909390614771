import {
  AppointmentType,
  BillingCode,
  IAppointment,
  IAppointmentConfig,
  ICompletedAppointment,
  IPayer,
  Modifier,
  ProviderType,
} from "../../types";
import { getBaseBillingModifiers, sortModifiers } from "./helpers";

export const NEW_MEXICO_APPOINTMENTS: IAppointmentConfig[] = [
  {
    name: AppointmentType.GROUP_PARENT_TRAINING,
    billingCode: BillingCode.CODE_97157,
    modifiers: [],
  },
  {
    name: AppointmentType.DIRECT_OR_INDIRECT_SUPERVISION,
    billingCode: BillingCode.CODE_T1026,
    modifiers: [Modifier.UD],
  },
  {
    name: AppointmentType.CLINICAL_MANAGEMENT,
    billingCode: BillingCode.CODE_T1026,
    modifiers: [Modifier.UC],
  },
];

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  providerType: ProviderType
) => {
  const modifiers = new Set(getBaseBillingModifiers(appointment, payer, providerType));

  if (
    "billingUserId" in appointment &&
    "renderingUserId" in appointment &&
    appointment.billingUserId !== appointment.renderingUserId &&
    !["87726", "62308"].includes(payer.payerId) // Don't add for Optum and Evernorth
  ) {
    modifiers.add(Modifier.U7);
  }

  return sortModifiers(Array.from(modifiers));
};

export const newMexicoBillingModifier = getStateBillingModifiers;
