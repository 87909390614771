import { blue, green } from "@ant-design/colors";
import { CheckOutlined } from "@ant-design/icons";
import { Col, Progress, Typography } from "antd";
import React from "react";

const { Title } = Typography;

interface IProps {
  title: string;
  hours: number;
  inputHours: number;
}

export const HoursProgressBar: React.FC<IProps> = ({ title, hours, inputHours }) => {
  const strokeColor = (inputHours / hours) * 100 >= 100 ? green.primary : blue.primary;

  return (
    <Col
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 20,
      }}
    >
      <Progress
        type="circle"
        strokeColor={strokeColor}
        percent={(inputHours / hours) * 100}
        format={(percent) =>
          percent! >= 100 ? (
            <CheckOutlined style={{ fontSize: 36, color: `${green.primary}` }} />
          ) : (
            `${hours} hrs`
          )
        }
      />
      <Title
        level={5}
        style={{
          fontWeight: "400",
          paddingTop: 10,
        }}
      >
        {title}
      </Title>
    </Col>
  );
};
