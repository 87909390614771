import { getAuth } from "firebase/auth";

import app from "./firebase";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const endpoint = (uri: string, method: string) => {
  const configuredEndpoint = async (body: any) => {
    const authToken = await getAuth(app).currentUser?.getIdToken();
    const response = await fetch(BACKEND_BASE_URL + uri, {
      method: method,
      mode: "cors",
      body: JSON.stringify(body),
      headers: {
        "content-type": "application/json",
        authorization: authToken || "",
      },
    });
    const result = await response.json();

    return result;
  };

  return configuredEndpoint;
};
