import { z } from "zod";

import { enrollmentStatusSchema } from "../types/general";

export const plyServiceRequestSchema = z.object({
  npi: z.string(),
});

export type IPlyServiceRequest = z.input<typeof plyServiceRequestSchema>;

export const plyServiceResponseSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  npi: z.string(),
  enrollment_statuses: z.array(enrollmentStatusSchema),
});

export type IPlyServiceResponse = z.input<typeof plyServiceResponseSchema>;
