import { z } from "zod";

import {
  IIndirect,
  IndirectReason,
  IndirectRejectionReason,
  IndirectStatus,
} from "../types/calendar";

export const approveIndirectEndpointRequestSchema = z.object({
  id: z.string().min(1),
});

export const rejectIndirectEndpointRequestSchema = z.object({
  id: z.string().min(1),
  rejectionReason: z.nativeEnum(IndirectRejectionReason),
  rejectionNotes: z.string(),
});

export const createIndirectEndpointRequestSchema = z.object({
  clinicId: z.string(),
  attendeeEmails: z.array(z.string()),
  summary: z.string(),
  description: z.string(),
  startMs: z.number(),
  endMs: z.number(),
  timeZone: z.string(),
  indirectReason: z.nativeEnum(IndirectReason),
  status: z.nativeEnum(IndirectStatus),
  rejectionReason: z.nativeEnum(IndirectRejectionReason).optional(),
  rejectionNotes: z.string().optional(),
  isDirectPayRate: z.boolean().optional(),
});

export type ICreateIndirectEndpointRequest = z.input<typeof createIndirectEndpointRequestSchema>;

export type IApproveIndirectEndpointRequest = z.input<typeof approveIndirectEndpointRequestSchema>;

export type IRejectIndirectEndpointRequest = z.input<typeof rejectIndirectEndpointRequestSchema>;

export interface IGetIndirectsForWeekAndClinicIdEndpointRequest {
  week: number;
  year: number;
  clinicId: string;
  timeZone: string;
}

export type IUpdateIndirectEndpointRequest = Partial<IIndirect> & Pick<IIndirect, "id">;

export interface IDeleteIndirectEndpointRequest {
  id: string;
  clientFileId?: string;
}
