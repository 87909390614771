import {
  IAppointment,
  IAppointmentConfig,
  ICompletedAppointment,
  IPayer,
  ProviderType,
} from "../../types";
import { getBaseBillingModifiers } from "./helpers";

export const ILLINOIS_APPOINTMENTS: IAppointmentConfig[] = [];

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  providerType: ProviderType
) => {
  return getBaseBillingModifiers(appointment, payer, providerType);
};

export const illinoisBillingModifier = getStateBillingModifiers;
