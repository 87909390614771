import { z } from "zod";

import { AdonisClaimStatus } from "../types";

export const createAdonisClaimEndpointRequestSchema = z.object({
  compAptId: z.string(),
});

export type ICreateAdonisClaimEndpointRequestSchema = z.input<
  typeof createAdonisClaimEndpointRequestSchema
>;

export const createAdonisClaimByClinicEndpointRequestSchema = z.object({
  clinicId: z.string(),
  startMs: z.number(),
  endMs: z.number(),
  timeZone: z.string(),
});

export type ICreateAdonisClaimByClinicEndpointRequestSchema = z.input<
  typeof createAdonisClaimByClinicEndpointRequestSchema
>;

export const createAdonisClaimByClinicErrorResponseSchema = z.object({
  error: z.string(),
});

export type ICreateAdonisClaimByClinicErrorResponseSchema = z.input<
  typeof createAdonisClaimByClinicErrorResponseSchema
>;

export const adonisClaimResult = z.object({
  aptIds: z.array(z.string()),
  clientId: z.string(),
  claimId: z.string(),
  error: z.string(),
});

export type IAdonisClaimResult = z.input<typeof adonisClaimResult>;

// Each result will have a claimId if successful, or an error if not
export const createAdonisClaimByClinicSuccessResponseSchema = z.object({
  results: z.array(adonisClaimResult),
});

export type ICreateAdonisClaimByClinicSuccessResponseSchema = z.input<
  typeof createAdonisClaimByClinicSuccessResponseSchema
>;

export const createAdonisPatientRequestSchema = z.object({
  clientId: z.string(),
});

export type ICreateAdonisPatientRequestSchema = z.input<typeof createAdonisPatientRequestSchema>;

export const createAdonisProviderRequestSchema = z.object({
  npis: z.array(z.string()),
});

export type ICreateAdonisProviderRequestSchema = z.input<typeof createAdonisProviderRequestSchema>;
export const getAdonisClaimRequestSchema = z.object({
  claimId: z.string(),
});

export type IGetAdonisClaimRequestSchema = z.input<typeof getAdonisClaimRequestSchema>;

export const adonisWebhookRequestSchema = z.object({
  id: z.string(),
  timestamp: z.string(),
  object: z.string(),
  payload_version: z.string(),
  type: z.string(),
});

export type IAdonisWebhookRequestSchema = z.input<typeof adonisWebhookRequestSchema>;

export const adonisClaimStatusChangeRequestSchema = z.object({
  id: z.string(),
  data: z.object({
    id: z.string(),
    external_encounter_id: z.string(),
    external_patient_id: z.string(),
    status: z.nativeEnum(AdonisClaimStatus),
    biller_notes: z.array(z.string()),
  }),
});
