import {
  CountryCode,
  ICreateGuardianEndpointRequest,
  IntakeStatus,
  USStateCode,
} from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Form, Input, message, Typography } from "antd";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { VALID_EMAIL_REGEX, VALID_PASSWORD_REGEX } from "../../consts";
import { signIn } from "../../helpers/sign-in";
import { IInquiryForm } from "../../pages/InquiryFlow";
import * as FirestoreService from "../../services/firestore";
import { PhoneNumberInput } from "../PhoneNumberInput";

const { Title, Text } = Typography;

interface IProps {
  inquiry: IInquiryForm;
}

interface ISignupFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
}

export const ContactForm: React.FC<IProps> = ({ inquiry }: IProps) => {
  const history = useHistory();

  const [form] = Form.useForm<ISignupFormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isMobile } = useResponsive();

  const handleSignup = async (values: ISignupFormValues) => {
    setIsLoading(true);

    const isEmailAvailable = await FirestoreService.isGuardianEmailAvailable(values.email);

    if (!isEmailAvailable) {
      void message.error(
        "An account already exists with that email address. Would you like to log in instead?"
      );
      setIsLoading(false);
      return;
    }

    const createGuardianRequest: ICreateGuardianEndpointRequest = {
      clinicId: inquiry.clinicId,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: "",
      phoneNumber: {
        primary: values.phoneNumber,
        home: "",
        work: "",
        cell: "",
      },
      email: values.email,
      password: values.password,
      address: {
        line1: "",
        line2: "",
        city: "",
        state: USStateCode.UNKNOWN,
        country: CountryCode.US,
        zipCode: inquiry.zipCode,
      },
      addressNotes: "",
      intakeStatus: IntakeStatus.UNDISCOVERED,
      intakeStatusNotes: inquiry.intakeStatusNotes,
      payers: {},
    };

    await FirestoreService.createGuardian(createGuardianRequest);

    await signIn(
      values.email,
      values.password,
      () => {
        history.push("/inquire-complete");
      },
      () => {
        void message.error("Oops! Something went wrong");
        history.push("/");
      }
    );

    setIsLoading(false);
  };

  return (
    <div style={{ marginTop: isMobile ? 0 : -25 }}>
      <Title style={{ fontFamily: "Reckless Neue" }}>{`Let's get started!`}</Title>
      <Text style={{ display: "block", marginBottom: 30, fontWeight: 400 }}>
        Provide your information below to get started with onboarding. We keep all your data private
        and secure.
      </Text>
      <Form form={form} layout="vertical" labelCol={{ span: 24 }} onFinish={handleSignup}>
        <Form.Item
          label="Parent/Guardian's First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "First name is a required field",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
            name="firstName"
          />
        </Form.Item>

        <Form.Item
          label="Parent/Guardian's Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Last name is a required field",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
            name="lastName"
          />
        </Form.Item>

        <Form.Item
          label="What's the best number to reach you?"
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Please enter a valid phone number",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <PhoneNumberInput
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              pattern: VALID_EMAIL_REGEX,
              message: "Please enter a valid email",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
            name="email"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              pattern: VALID_PASSWORD_REGEX,
              message:
                "Your Password must be at least 8 characters long, contain at least 1 number, and at least 1 special character (`~!@#$%^&*-_=+;:'\",<.>?)",
            },
            {
              required: true,
              message: "Please input your Password",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input.Password
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
            name="password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your Password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match"));
              },
            }),
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input.Password
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
          />
        </Form.Item>
        <Button
          style={{ width: 120, height: 44, fontWeight: 600 }}
          htmlType="submit"
          type="primary"
          shape="round"
          loading={isLoading}
        >
          Submit
        </Button>
        <Typography.Text style={{ display: "block", marginTop: 30, fontWeight: 400 }}>
          Already have an account? <Link to="/login">Log In</Link>
        </Typography.Text>
      </Form>
    </div>
  );
};
