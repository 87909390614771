import { z } from "zod";

import { USStateCode } from "../types";
import { IPayer } from "../types/general";

export const getPayerByIdEndPointRequestSchema = z.object({
  state: z.nativeEnum(USStateCode),
  payerId: z.string(),
});
export const getAllPayersForStateEndPointRequestSchema = z.object({
  state: z.nativeEnum(USStateCode),
});
export const deletePayerEndPointRequestSchema = z.object({
  id: z.string(),
});

export type IGetPayerByIdEndPointRequest = z.input<typeof getPayerByIdEndPointRequestSchema>;

export type IGetAllPayersForStateEndPointRequest = z.input<
  typeof getAllPayersForStateEndPointRequestSchema
>;
export type IUpdatePayerEndPointRequest = Omit<IPayer, "createdAt" | "updatedAt" | "deletedAt">;
export type ICreatePayerEndPointRequest = Omit<
  IPayer,
  "id" | "createdAt" | "updatedAt" | "deletedAt"
>;
export type IDeletePayerEndPointRequest = z.input<typeof deletePayerEndPointRequestSchema>;
