import { IClientFile } from "@finni-health/shared";
import _ from "lodash";

import { ClientFileIntakeQuestionnaireStates } from "../consts";

export const getCurrentIntakeQuestionnaireStatus = (clientFile: IClientFile) => {
  if (!_.isEmpty(clientFile.medicalHistory?.treatmentImpairments)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_VISION_HEARING_ABILITY;
  } else if (!_.isEmpty(clientFile.medicalHistory?.mentalIllness)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_OTHER_ISSUES;
  } else if (!_.isEmpty(clientFile.medicalHistory?.medication)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_FAMILY_MEDICAL_HISTORY;
  } else if (!_.isEmpty(clientFile.medicalHistory?.existingDiagnosis)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_PRESCRIBED_MEDICATION;
  } else if (!_.isEmpty(clientFile.medicalHistory?.livingSituation)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_MEDICAL_CONDITIONS;
  } else if (!_.isEmpty(clientFile.medicalHistory?.pastServices)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_LIVING_SITUATION;
  } else if (!_.isEmpty(clientFile.medicalHistory?.currentServices)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_PREVIOUS_SERVICES;
  } else if (!_.isEmpty(clientFile.behaviors?.goals)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_CURRENT_SERVICES;
  } else if (!_.isEmpty(clientFile.behaviors?.takeOffClothes)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_GOALS;
  } else if (!_.isEmpty(clientFile.behaviors?.flopOnGround)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_STRIPPING_CLOTHING_FREQUENCY;
  } else if (!_.isEmpty(clientFile.behaviors?.destroyProperty)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_FALLING_DOWN_FREQUENCY;
  } else if (!_.isEmpty(clientFile.behaviors?.runAway)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_DESTROYING_PROPERTY_FREQUENCY;
  } else if (!_.isEmpty(clientFile.behaviors?.aggression)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_RUNNING_AWAY_FREQUENCY;
  } else if (!_.isEmpty(clientFile.behaviors?.selfHarm)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_AGGRESSION_FREQUENCY;
  } else if (!_.isEmpty(clientFile.therapyPreferences?.availability)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_SELF_HARM_FREQUENCY;
  } else if (!_.isEmpty(clientFile.therapyPreferences?.secondaryContact)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_AVAILABILITY;
  } else if (!_.isEmpty(clientFile.therapyPreferences?.preferredTherapistGender)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_OTHER_CAREGIVER;
  } else if (!_.isEmpty(clientFile.therapyPreferences?.preferredLanguage)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_PREFERRED_THERAPIST_GENDER;
  } else if (!_.isEmpty(clientFile.therapyPreferences?.primaryLanguage)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_PREFERRED_LANGUAGE;
  } else if (!_.isEmpty(clientFile.address.line1)) {
    return ClientFileIntakeQuestionnaireStates.ENTER_PRIMARY_LANGUAGE;
  } else {
    return ClientFileIntakeQuestionnaireStates.ENTER_THERAPY_LOCATION;
  }
};
