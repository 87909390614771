import {
  DropboxDocumentType,
  IBehaviors,
  IClientDocument,
  IClientFile,
  IDiagnostician,
  IGuardian,
  IMedicalHistory,
  IPediatrician,
  ISignedDropboxDocument,
  ITherapyPreferences,
} from "@finni-health/shared";
import _ from "lodash";

import * as FirestoreService from "../services/firestore";

/**
 * Given an clientFile object, determines how many steps are left in the preapproval process
 *
 * @returns the number of incomplete steps in the preapproval process
 */
export const getRemainingPreapprovalStepsCount = (clientFile: any) => {
  return [
    isClientDetailsComplete(clientFile),
    isDocumentsComplete(clientFile?.documents),
    isPayersComplete(clientFile),
    isGeneralConsentSigned(clientFile),
  ].filter((x) => !x).length;
};

/**
 * @param guardian
 * @returns True if clientFile object exists for guardian, false otherwise
 */
export const isClientFileStarted = (guardian: IGuardian) => {
  const clientFile = FirestoreService.getClientFileByGuardianId(guardian.id);

  return !_.isEmpty(clientFile);
};

/**
 * @param guardian
 * @param clientFile
 * @returns True if all steps of the preapproval are complete, false otherwise
 */
export const isPreapprovalComplete = (clientFile: IClientFile) => {
  return (
    isClientDetailsComplete(clientFile) &&
    isDocumentsComplete(clientFile?.documents) &&
    isPayersComplete(clientFile) &&
    isGeneralConsentSigned(clientFile)
  );
};

/**
 * @param clientFile
 * @returns True if there clientFile has an attached clientId, false otherwise
 */
export const isClientDetailsComplete = (clientFile: any) => {
  return !!clientFile?.clientId;
};

/**
 * @param documents
 * @returns True if clientFile's documents list is not empty, false otherwise
 */
export const isDocumentsComplete = (documents: IClientDocument[]) => {
  return !_.isEmpty(documents);
};

/**
 * @param diagnostician
 * @returns True if the providers object has a non-empty diagnostician, false otherwise
 */
export const isDiagnosticianComplete = (diagnostician: IDiagnostician) => {
  return !_.isEmpty(diagnostician);
};

/**
 * @param pediatrician
 * @returns True if the providers object has a non-empty pediatrician, false otherwise
 */
export const isPediatricianComplete = (pediatrician: IPediatrician) => {
  return !_.isEmpty(pediatrician);
};

/**
 * @param guardian
 * @returns True if a guardian has entered payer information, false otherwise
 */
export const isPayersComplete = (clientFile: IClientFile) => {
  return !_.isEmpty(clientFile?.payers);
};

/**
 *
 * @param guardian
 * @returns True if a guardian has signed the general consent form, false otherwise
 */
export const isGeneralConsentSigned = (clientFile: IClientFile) => {
  return !!clientFile.signedDocuments?.find(
    (document: ISignedDropboxDocument) =>
      document.documentType === DropboxDocumentType.CAREGIVER_CONSENT_FORM
  );
};

/**
 * @param guardian
 * @returns True if the entire clientFile object is complete, false otherwise
 */
export const isClientFileComplete = (guardian: IGuardian) => {
  return _.isEmpty(guardian.firstName);
};

/**
 * @param clientFile
 * @returns True if all steps of the intake are complete, false otherwise
 */
export const isIntakeComplete = (clientFile: IClientFile) => {
  return (
    isTherapyPreferencesComplete(clientFile?.therapyPreferences) &&
    isBehaviorsComplete(clientFile?.behaviors) &&
    isMedicalHistoryComplete(clientFile?.medicalHistory)
  );
};

/**
 * @param guardian
 * @returns True if a guardian has entered therapy preferences, false otherwise
 */
export const isTherapyPreferencesComplete = (therapyPreferences: ITherapyPreferences) => {
  return therapyPreferences?.availability !== undefined;
};

/**
 * @param guardian
 * @returns True if a guardian has entered behavior information, false otherwise
 */
export const isBehaviorsComplete = (behaviors: IBehaviors) => {
  return behaviors?.goals !== undefined || behaviors?.otherGoals !== undefined;
};

/**
 * @param guardian
 * @returns True if a guardian has entered medical history, false otherwise
 */
export const isMedicalHistoryComplete = (medicalHistory: IMedicalHistory) => {
  return medicalHistory?.visionHearingImpairment !== undefined;
};

/**
 * Given an clientFile object, determines how many steps are left in the intake process
 *
 * @returns the number of incomplete steps in the intake process
 */
export const getRemainingIntakeStepsCount = (clientFile: any) => {
  return [
    isTherapyPreferencesComplete(clientFile?.therapyPreferences),
    isBehaviorsComplete(clientFile?.behaviors),
    isMedicalHistoryComplete(clientFile?.medicalHistory),
  ].filter((x) => !x).length;
};
