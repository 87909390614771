import { IClientFile } from "@finni-health/shared";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../components/AuthProvider";
import { IntakeComplete } from "../components/ClientOnboardingFlow/IntakeComplete";
import { PreapprovalComplete } from "../components/ClientOnboardingFlow/PreapprovalComplete";
import { Welcome } from "../components/ClientOnboardingFlow/Welcome";
import { isIntakeComplete, isPreapprovalComplete } from "../helpers/client-file-status";
import { getOrCreateClientFile } from "../helpers/get-or-create-client-file";
import { Loading } from "./Loading";

export const ClientFileFlow: React.FC = () => {
  const { guardian } = useContext(AuthContext);

  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchData().catch(() => {});
  }, [guardian]);

  const fetchData = async () => {
    if (!_.isEmpty(guardian)) {
      const clientFile = await getOrCreateClientFile(guardian);
      setClientFile(clientFile);
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loading />
  ) : !isPreapprovalComplete(clientFile) ? (
    <Welcome clientFile={clientFile} />
  ) : !isIntakeComplete(clientFile) ? (
    <PreapprovalComplete clientFile={clientFile} refreshClientFile={fetchData} />
  ) : (
    <IntakeComplete clientFile={clientFile} />
  );
};
