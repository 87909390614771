import { z } from "zod";

export const masterRosterGetCredentialReponsesEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
});

export const masterRosterGetRosteringEffectiveMsByClientIdAndUserIdEndpointRequestSchema = z
  .object({
    clinicId: z.string().min(1),
    clientId: z.string().min(1),
    userId: z.string().min(1),
  })
  .strict();

export type IMasterRosterGetCredentialReponsesEndpointRequest = z.input<
  typeof masterRosterGetCredentialReponsesEndpointRequestSchema
>;

export type IMasterRosterGetRosteringEffectiveMsByClientIdAndUserIdEndpointRequest = z.input<
  typeof masterRosterGetRosteringEffectiveMsByClientIdAndUserIdEndpointRequestSchema
>;
