import { IClientAvailability } from "@finni-health/shared";
import { ClientHours } from "@finni-health/ui";
import { Layout, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Header } from "../components/Header";
import { ScheduleTable } from "../components/Schedule/ScheduleTable";
import { Loading } from "../pages/Loading";
import * as FirestoreService from "../services/firestore";

const { Title, Text } = Typography;
const { Content } = Layout;

export const Schedule: React.FC = () => {
  const { clientFileHash } = useParams<{ clientFileHash: string }>();
  const [clientAvailability, setClientAvailability] = useState<IClientAvailability | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [inputHours, setInputHours] = useState<number>(0);

  useEffect(() => {
    if (!clientAvailability) {
      fetchClientAvailability(true).catch(() => {});
    }
  }, [clientAvailability]);

  const fetchClientAvailability = async (showLoading = true) => {
    setIsLoading(showLoading);
    try {
      const clientAvailability = await FirestoreService.getClientAvailabilityByHash(clientFileHash);

      setClientAvailability(clientAvailability);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!clientAvailability?.availability) {
    return (
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 20,
          height: "100vh",
        }}
      >
        <Title level={3}>Client not found</Title>
      </Content>
    );
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
        }}
      >
        <div
          className="schedule-header"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 80,
            marginBottom: 60,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Title level={2} style={{ textAlign: "center" }}>
            Let us know when you’re available
          </Title>
          <Text style={{ textAlign: "center", fontSize: "1.2rem" }}>
            Please ensure that the availability you provide us is accurate, to ensure your child
            gets the care they need.
          </Text>
        </div>
        {clientAvailability &&
        clientAvailability?.minimumHours &&
        clientAvailability?.recommendedHours ? (
          <ClientHours
            minimumHours={clientAvailability.minimumHours}
            recommendedHours={clientAvailability.recommendedHours}
            inputHours={inputHours}
          />
        ) : null}
        <ScheduleTable
          onHoursChange={setInputHours}
          hash={clientFileHash}
          initialAvailability={clientAvailability.availability}
        />
      </Content>
    </div>
  );
};
