import {
  CountryCode,
  ICreateInquiryEndpointRequest,
  InquiryStatus,
  USStateCode,
} from "@finni-health/shared";
import { Button, Form, Input, Typography } from "antd";
import React from "react";

import { InquiryStates, VALID_EMAIL_REGEX } from "../../consts";
import { IInquiryForm } from "../../pages/InquiryFlow";
import { createInquiry } from "../../services/firestore";
import { PhoneNumberInput } from "../PhoneNumberInput";

const { Title, Text } = Typography;

type IProps = {
  inquiry: IInquiryForm;
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
};

export const UnsupportedDiagnoses: React.FC<IProps> = ({
  inquiry,
  setInquiryStatus,
  updateInquiry,
}: IProps) => {
  return (
    <>
      <Title style={{ fontFamily: "Reckless Neue" }}>{`Let's get started!`}</Title>
      <Text
        style={{
          display: "block",
          marginBottom: 30,
          marginRight: "auto",
          marginLeft: "auto",
          fontWeight: 400,
          maxWidth: 530,
        }}
      >
        {`Provide your information below and we'll reach out for next steps. We
        keep all your data private and secure.`}
      </Text>
      <Form
        layout="vertical"
        labelCol={{ span: 24 }}
        onFinish={async () => {
          const createInquiryRequest: ICreateInquiryEndpointRequest = {
            clinicId: inquiry.clinicId,
            firstName: inquiry.firstName,
            lastName: inquiry.lastName,
            phoneNumber: {
              primary: inquiry.phoneNumber,
              home: "",
              work: "",
              cell: "",
            },
            email: inquiry.email,
            address: {
              line1: "",
              line2: "",
              city: "",
              state: USStateCode.UNKNOWN,
              country: CountryCode.US,
              zipCode: inquiry.zipCode,
            },
            intakeStatus: InquiryStatus.UNDISCOVERED,
            intakeStatusNotes: ["Undiagnosed", inquiry.intakeStatusNotes].join("\n"),
          };
          await createInquiry(createInquiryRequest);
          setInquiryStatus(InquiryStates.SUBMITTED);
        }}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            onChange={(e) =>
              updateInquiry({
                firstName: e.target.value,
              })
            }
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
          />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please enter your last name",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            onChange={(e) =>
              updateInquiry({
                lastName: e.target.value,
              })
            }
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              pattern: VALID_EMAIL_REGEX,
              message: "Please enter a valid email",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <Input
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
            onChange={(e) =>
              updateInquiry({
                email: e.target.value,
              })
            }
          />
        </Form.Item>
        <Form.Item
          label="What's the best number to reach you?"
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Please enter a valid phone number",
            },
          ]}
          style={{
            maxWidth: 450,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 25,
          }}
        >
          <PhoneNumberInput
            style={{
              borderRadius: 50,
              padding: 10,
              paddingLeft: 20,
            }}
            onChange={(e: any) =>
              updateInquiry({
                phoneNumber: e.target.value,
              })
            }
          />
        </Form.Item>
        <Button
          onClick={() => setInquiryStatus(InquiryStates.ENTER_DIAGNOSES)}
          shape="round"
          style={{ height: 40, width: 100 }}
        >
          Back
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          style={{ marginLeft: 10, height: 40, width: 100 }}
        >
          Submit
        </Button>
      </Form>
    </>
  );
};
