import { z } from "zod";

import { IAddress, IClientFile, IClientPayer, IntakeStatus } from "../types";

export interface ICreateClientFileEndpointRequest {
  clinicId: string;
  clientId: string;
  guardianId: string;

  address: IAddress;
  addressNotes?: string;

  intakeStatus: IntakeStatus;
  intakeStatusNotes?: string;

  payers: {
    primary?: IClientPayer;
    secondary?: IClientPayer;
  };
}

export interface ICreateSkeletonClientFileEndpointRequest {
  clinicId: string;
  guardianId: string;
}

export type IUpdateClientFileEndpointRequest = Partial<IClientFile> & Pick<IClientFile, "id">;

export const getAssignedClientFilesByClinicAndProviderSchema = z.object({
  clinicId: z.string().min(1),
  providerId: z.string().min(1),
});

export type IGetAssignedClientFilesByClinicAndProviderEndpointRequest = z.input<
  typeof getAssignedClientFilesByClinicAndProviderSchema
>;
