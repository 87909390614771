import {
  FirestoreCollectionName,
  IClient,
  IClientFile,
  ICreateClientEndpointRequest,
  ICreateClientFileEndpointRequest,
  ICreateGuardianEndpointRequest,
  ICreateInquiryEndpointRequest,
  IGetEmbeddedUrlEndpointRequest,
  IGetSignedDocumentEndpointRequest,
  IGuardian,
  IntakeStatus,
  IPayer,
  IUpdateClientAvailabilityByHashEndpointRequest,
  MASTER_CLINIC_ID,
  USStateCode,
} from "@finni-health/shared";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import * as backend from "./backend";
import app from "./firebase";

const SLACK_INQUIRY_BOT_WEBHOOK_URL = process.env.REACT_APP_SLACK_INQUIRY_BOT_WEBHOOK_URL;

export const db = getFirestore(app);

export const ENDPOINTS = {
  getCreateGuardianEndpoint: () => backend.endpoint("/guardians/create", "post"),
  getUpdateGuardianEndpoint: () => backend.endpoint("/guardians/update", "post"),
  getCreateClientEndpoint: () => backend.endpoint("/clients/create", "post"),
  getCreateClientFileEndpoint: () => backend.endpoint("/client-files/create", "post"),
  getUpdateClientFileEndpoint: () => backend.endpoint("/client-files/update", "post"),
  getGetClientAvailabilityByHashEndpoint: () =>
    backend.endpoint("/client-availabilities/get-latest-by-hash", "post"),
  getUpdateClientAvailabilityEndpoint: () =>
    backend.endpoint("/client-availabilities/update-by-hash", "post"),
  getCreateInquiryEndpoint: () => backend.endpoint("/inquiries/create", "post"),
  getGetClinicByZipCodeEndpoint: () => backend.endpoint("/clinics/getByZipCode", "post"),
  getGetClinicByNameEndpoint: () => backend.endpoint("/clinics/getByName", "post"),
  getGetClinicByIdEndpoint: () => backend.endpoint("/clinics/getById", "post"),
  getValidateGuardianEmailEndpoint: () => backend.endpoint("/guardians/validate-email", "post"),
  getNotifyClinicEndpoint: () => backend.endpoint("/slack/notify-clinic", "post"),
  getGetEmbeddedUrlEndpoint: () => backend.endpoint("/dropbox-sign/embedded-url/get", "post"),
  getGetDownloadSignedDocumentEndpoint: () =>
    backend.endpoint("/dropbox-sign/signed-document/get", "post"),
};

export const getAllPayersForState = async (state: USStateCode) => {
  const docs = await getDocs(
    query(
      collection(db, FirestoreCollectionName.PAYERS),
      where("state", "==", state),
      orderBy("name", "asc")
    )
  );
  const allPayersForState: IPayer[] = [];
  docs.forEach((doc) => {
    const payer = doc.data() as IPayer;
    if (!payer.deletedAt) {
      allPayersForState.push(payer);
    }
  });

  return allPayersForState;
};

export const getAllPayersForClinicId = async (clinicId: string) => {
  const docs = await getDocs(
    query(
      collection(db, FirestoreCollectionName.PAYERS),
      where("clinicId", "==", clinicId),
      orderBy("name", "asc")
    )
  );

  const allPayersForClinicId: IPayer[] = [];
  docs.forEach((doc) => {
    const payer = doc.data() as IPayer;
    if (!payer.deletedAt) {
      allPayersForClinicId.push(payer);
    }
  });

  return allPayersForClinicId;
};

export const getGuardianById = async (id: string) => {
  const result = await getDocs(
    query(collection(db, FirestoreCollectionName.GUARDIANS), where("id", "==", id), limit(1))
  );
  const user = result.docs.map((doc: any) => doc.data())[0];

  return user;
};

export const isGuardianEmailAvailable = async (email: string) => {
  try {
    const validateEmailEndpoint = ENDPOINTS.getValidateGuardianEmailEndpoint();
    const validateRes = await validateEmailEndpoint({ email });

    return validateRes.emailAvailable;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getClientById = async (id: string) => {
  const result = await getDoc(doc(db, FirestoreCollectionName.CLIENTS, id));
  const client = result.data() as IClient;

  if (!client || client.deletedAt) {
    return Promise.reject("Could not find active client by id");
  }

  return client;
};

export const getClientAvailabilityByHash = async (hash: string) => {
  const getClientAvailabilityByHash = ENDPOINTS.getGetClientAvailabilityByHashEndpoint();
  const clientAvailability = await getClientAvailabilityByHash({ hash });

  return clientAvailability;
};

export const updateClientAvailability = async (
  req: IUpdateClientAvailabilityByHashEndpointRequest
) => {
  try {
    const updateClientAvailability = ENDPOINTS.getUpdateClientAvailabilityEndpoint();
    const clientAvailability = await updateClientAvailability(req);

    return clientAvailability;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getClinicById = async (id: string) => {
  try {
    const getClinicById = ENDPOINTS.getGetClinicByIdEndpoint();
    const clinic = await getClinicById({ id });

    return clinic;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getClinicByZipCode = async (zipCode: string) => {
  try {
    const getClinicByZipCode = ENDPOINTS.getGetClinicByZipCodeEndpoint();
    const clinic = await getClinicByZipCode({ zipCode });

    return clinic;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getClinicByName = async (name: string) => {
  try {
    const getClinicByName = ENDPOINTS.getGetClinicByNameEndpoint();
    const clinic = await getClinicByName({ name });

    return clinic;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createGuardian = async (req: ICreateGuardianEndpointRequest) => {
  try {
    const createGuardian = ENDPOINTS.getCreateGuardianEndpoint();
    const result = await createGuardian(req);

    const clinic = await getClinicById(req.clinicId);
    const slackMsg = `":alert: Incoming Lead! A new client is prequalified for service at ${clinic.name}. \nEmail Address: ${req.email} \nPhone number: ${req.phoneNumber.primary} \nZip: ${req.address.zipCode}"`;

    try {
      const notifyClinic = ENDPOINTS.getNotifyClinicEndpoint();
      await notifyClinic({
        clinicId: req.clinicId,
        message: slackMsg.slice(1, -1),
      });
    } catch (err) {
      return (
        SLACK_INQUIRY_BOT_WEBHOOK_URL &&
        fetch(SLACK_INQUIRY_BOT_WEBHOOK_URL, {
          method: "post",
          body: '{"text":' + slackMsg + "}",
        })
      );
    }

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createInquiry = async (req: ICreateInquiryEndpointRequest) => {
  try {
    const createInquiry = ENDPOINTS.getCreateInquiryEndpoint();
    const result = await createInquiry(req);

    const slackMsg = `":alert: An unqualified client is inquiring for service! \nEmail Address: ${req.email} \nPhone number: ${req.phoneNumber.primary} \nZip: ${req.address.zipCode}"`;

    try {
      if (!req.clinicId || req.clinicId === MASTER_CLINIC_ID)
        throw new Error("No clinicId provided");
      const notifyClinic = ENDPOINTS.getNotifyClinicEndpoint();
      await notifyClinic({
        clinicId: req.clinicId,
        message: slackMsg.slice(1, -1),
      });
    } catch (err) {
      return (
        SLACK_INQUIRY_BOT_WEBHOOK_URL &&
        fetch(SLACK_INQUIRY_BOT_WEBHOOK_URL, {
          method: "post",
          body: '{"text":' + slackMsg + "}",
        })
      );
    }

    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateGuardian = async (id: string, req: Partial<IGuardian>) => {
  try {
    const request = {
      id,
      ...req,
    };

    const updateGuardian = ENDPOINTS.getUpdateGuardianEndpoint();
    const result = await updateGuardian(request);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @deprecated
 * we are deprecating IClientFile and clientFile methods. use IClient and client methods instead.
 */
export const createClientFile = async (req: ICreateClientFileEndpointRequest) => {
  try {
    const request = {
      clinicId: req.clinicId,
      guardianId: req.guardianId,
      intakeStatus: IntakeStatus.UNDISCOVERED,
    };

    const createClientFile = ENDPOINTS.getCreateClientFileEndpoint();
    const result = await createClientFile(request);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @deprecated
 * we are deprecating IClientFile and clientFile methods. use IClient and client methods instead.
 */
export const updateClientFile = async (req: Partial<IClientFile> & Pick<IClientFile, "id">) => {
  try {
    const updateClientFile = ENDPOINTS.getUpdateClientFileEndpoint();
    const result = await updateClientFile(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @deprecated
 * we are deprecating IClientFile and clientFile methods. use IClient and client methods instead.
 */
export const getClientFileByGuardianId = async (guardianId: string) => {
  const req = await getDocs(
    query(
      collection(db, FirestoreCollectionName.CLIENT_FILES),
      where("guardianId", "==", guardianId),
      limit(1)
    )
  );
  const clientFile = req.docs.map((doc: any) => doc.data())[0];

  return clientFile;
};

export const createClient = async (req: ICreateClientEndpointRequest) => {
  try {
    const createClient = ENDPOINTS.getCreateClientEndpoint();
    const result = await createClient(req);

    return result.id;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmbeddedUrl = async (req: IGetEmbeddedUrlEndpointRequest) => {
  try {
    const getEmbeddedUrl = ENDPOINTS.getGetEmbeddedUrlEndpoint();
    const result = await getEmbeddedUrl(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadSignedDocument = async (req: IGetSignedDocumentEndpointRequest) => {
  try {
    const downloadSignedDocument = ENDPOINTS.getGetDownloadSignedDocumentEndpoint();
    const result = await downloadSignedDocument(req);

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};
