import { z } from "zod";

export const getClientSummariesEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  clientId: z.string().min(1),
});

export const getClientSummariesWithRangeEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  clientId: z.string().min(1),
  startMs: z.number(),
  endMs: z.number(),
});

export type IGetClientSummariesEndpointRequest = z.input<
  typeof getClientSummariesEndpointRequestSchema
>;

export type IGetClientSummariesWithRangeEndpointRequest = z.input<
  typeof getClientSummariesWithRangeEndpointRequestSchema
>;
