import {
  AppointmentType,
  BillingCode,
  IAppointment,
  IAppointmentConfig,
  ICompletedAppointment,
  IPayer,
  ProviderType,
} from "../../types";
import { getBaseBillingModifiers } from "./helpers";

export const NEW_HAMPSHIRE_APPOINTMENTS: IAppointmentConfig[] = [
  {
    name: AppointmentType.ADDITIONAL_ASSESSMENT,
    billingCode: BillingCode.CODE_0362T,
    modifiers: [],
  },
  {
    name: AppointmentType.DIRECT_SUPERVISION,
    billingCode: BillingCode.CODE_0362T,
    modifiers: [],
  },
];

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  providerType: ProviderType
) => {
  return getBaseBillingModifiers(appointment, payer, providerType);
};

export const newHampshireBillingModifier = getStateBillingModifiers;
