import { IStateBillingModifiersMap, USStateCode } from "../../types";
import { alabamaBillingModifier } from "./alabama";
import { arizonaBillingModifier } from "./arizona";
import { californiaBillingModifier } from "./california";
import { coloradoBillingModifier } from "./colorado";
import { conneticutBillingModifier } from "./connecticut";
import { georgiaBillingModifier } from "./georgia";
import { illinoisBillingModifier } from "./illinois";
import { indianaBillingModifier } from "./indiana";
import { kentuckyBillingModifier } from "./kentucky";
import { michiganBillingModifier } from "./michigan";
import { newHampshireBillingModifier } from "./newHampshire";
import { newMexicoBillingModifier } from "./newMexico";
import { newYorkBillingModifier } from "./newYork";
import { ohioBillingModifier } from "./ohio";
import { rhodeislandBillingModifier } from "./rhodeIsland";
import { southCarolinaBillingModifier } from "./southCarolina";
import { tennesseeBillingModifier } from "./tennessee";
import { utahBillingModifier } from "./utah";
import { virginiaBillingModifier } from "./virginia";
import { washingtonBillingModifier } from "./washington";

export const STATE_BILLING_MODIFIERS: IStateBillingModifiersMap = {
  [USStateCode.AL]: alabamaBillingModifier,
  [USStateCode.AZ]: arizonaBillingModifier,
  [USStateCode.CA]: californiaBillingModifier,
  [USStateCode.CO]: coloradoBillingModifier,
  [USStateCode.CT]: conneticutBillingModifier,
  [USStateCode.GA]: georgiaBillingModifier,
  [USStateCode.IL]: illinoisBillingModifier,
  [USStateCode.IN]: indianaBillingModifier,
  [USStateCode.MI]: michiganBillingModifier,
  [USStateCode.NM]: newMexicoBillingModifier,
  [USStateCode.NY]: newYorkBillingModifier,
  [USStateCode.OH]: ohioBillingModifier,
  [USStateCode.SC]: southCarolinaBillingModifier,
  [USStateCode.VA]: virginiaBillingModifier,
  [USStateCode.WA]: washingtonBillingModifier,
  [USStateCode.KY]: kentuckyBillingModifier,
  [USStateCode.TN]: tennesseeBillingModifier,
  [USStateCode.NH]: newHampshireBillingModifier,
  [USStateCode.RI]: rhodeislandBillingModifier,
  [USStateCode.UT]: utahBillingModifier,
};
