import { IAvailability, Weekday } from "@finni-health/shared";
import { dataModelToSelectedCells, selectedCellsToDataModel } from "@finni-health/shared";
import { COLORS } from "@finni-health/ui";
import { Grid, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";

import * as FirestoreService from "../../services/firestore";

const { useBreakpoint } = Grid;

interface RecordType {
  key: number;
  timeSlot: string;
}

interface ScheduleTableProps {
  onHoursChange: React.Dispatch<React.SetStateAction<number>>;
  hash: string;
  initialAvailability: IAvailability;
}

// Creates a string array of time slots in 30 minute increments, starting at 7:00am
const timeSlots: string[] = [];
for (let i = 0; i < 30; i++) {
  const startingHour = 7;
  const currentMinute = i % 2 === 0 ? "00" : "30";
  let currentHour = startingHour + Math.floor(i / 2);

  if (currentHour > 12) {
    currentHour -= 12;
    timeSlots.push(`${currentHour}:${currentMinute} pm`);
  } else if (currentHour === 12) {
    timeSlots.push(`${currentHour}:${currentMinute} pm`);
  } else {
    timeSlots.push(`${currentHour}:${currentMinute} am`);
  }
}

export const ScheduleTable: React.FC<ScheduleTableProps> = ({
  onHoursChange,
  hash,
  initialAvailability,
}) => {
  const screens = useBreakpoint();
  const selectedColumn = useRef<number | null>(null);

  // Create days of the week
  const days = screens["md"]
    ? Object.values(Weekday)
    : Object.values(Weekday).map((day) => day[0].toUpperCase());

  // States
  const [availability, setAvailability] = useState<IAvailability | null>(initialAvailability);
  const [dragging, setDragging] = useState<boolean>(false);

  // Create initial state for selected cells
  const initialSelectedCells = initialAvailability
    ? dataModelToSelectedCells(initialAvailability)
    : Array.from({ length: timeSlots.length }, () => Array(days.length).fill(false));
  const [selectedCells, setSelectedCells] = useState<Array<Array<boolean>>>(initialSelectedCells);

  // Create data model from selected cells
  useEffect(() => {
    const dataModel = selectedCellsToDataModel(selectedCells);
    setAvailability(dataModel);
  }, [selectedCells]);

  // Set initial hours
  useEffect(() => {
    let hours = 0;
    selectedCells.forEach((row) => {
      row.forEach((cell) => {
        if (cell) {
          hours += 0.5;
        }
      });
    });
    onHoursChange(hours);
  }, []);

  // Update clientAvailability every time availability changes
  useEffect(() => {
    const updateClientAvailability = async () => {
      if (availability) {
        const updatedAvailability = {
          availability: availability,
          hash: hash,
        };

        await FirestoreService.updateClientAvailability(updatedAvailability);
      }
    };

    updateClientAvailability().catch((error) => console.log(error));
  }, [availability]);

  // Handlers
  const onMouseDown = (rowIndex: number, colIndex: number): void => {
    toggleCell(rowIndex, colIndex);
    setDragging(true);
    selectedColumn.current = colIndex; // Set the column being selected
  };

  const onMouseEnter = (rowIndex: number, colIndex: number): void => {
    // Check if dragging and the column matches the current selected column
    if (dragging && colIndex === selectedColumn.current) {
      toggleCell(rowIndex, colIndex);
    }
  };

  const onMouseUp = (): void => {
    setDragging(false);
    selectedColumn.current = null;
  };

  // Toggle cell selection
  const toggleCell = (rowIndex: number, colIndex: number): void => {
    setSelectedCells((prev) => {
      const newSelectedCells = prev.map((row) => [...row]); // create new copy of each row
      if (!newSelectedCells[rowIndex][colIndex]) {
        // Only increase count if cell is not already selected
        onHoursChange((prevHours) => prevHours + 0.5);
      } else {
        onHoursChange((prevHours) => prevHours - 0.5);
      }
      newSelectedCells[rowIndex][colIndex] = !newSelectedCells[rowIndex][colIndex];
      return newSelectedCells;
    });
  };

  // Create columns for each day of the week
  const columns = [
    {
      title: "",
      dataIndex: "timeSlot",
      width: 100,
      align: "center" as const,
      render: (text: string) => (
        <div
          style={{
            fontSize: "0.9rem",
          }}
        >
          {text}
        </div>
      ),
    },
    ...days.map((day, colIndex) => ({
      title: day,
      dataIndex: day,
      width: screens["md"] ? 100 : screens["sm"] ? 50 : 36,
      align: "center" as const,
      render: (text: string, record: RecordType, rowIndex: number) => (
        <div
          className="schedule-cell"
          style={{
            background: selectedCells[rowIndex][colIndex]
              ? `${COLORS.PRIMARY}`
              : `${COLORS.LIGHT_GRAY}`,
            height: 25,
            width: "100%",
            textAlign: "center",
          }}
          onMouseDown={() => onMouseDown(rowIndex, colIndex)}
          onMouseEnter={() => onMouseEnter(rowIndex, colIndex)}
        />
      ),
    })),
  ];

  // Create data source for table
  const dataSource: RecordType[] = timeSlots.map((timeSlot, index) => ({
    key: index,
    timeSlot,
  }));

  return (
    <div onMouseUp={onMouseUp} style={{ userSelect: "none", marginTop: 30, marginBottom: 50 }}>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="timeSlot"
        size="small" // Reduce row height
        style={{ lineHeight: 0 }} // Adjust vertical spacing
      />
    </div>
  );
};
