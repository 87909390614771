import { IClientFile, TherapistGender } from "@finni-health/shared";
import { Button, Card, Row, Select, Typography } from "antd";
import React, { ReactNode } from "react";

import { ClientFileIntakeQuestionnaireStates } from "../../../consts";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempTherapyPreferences: any;
  setTempTherapyPreferences: (value: any) => void;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClientFile: (req: Partial<IClientFile>) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterPreferredTherapistGender: React.FC<IProps> = ({
  isMobile,
  tempTherapyPreferences,
  setTempTherapyPreferences,
  setClientFileIntakeQuestionnaireStatus,
  updateClientFile,
  backButton,
}: IProps) => {
  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          {`Do you have a preference of gender for your child's therapist?`}
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Row justify="center" style={{ marginBottom: 25, width: "100%" }}>
        <Select
          placeholder="Select gender"
          defaultValue={tempTherapyPreferences.preferredTherapistGender}
          onChange={(e) => {
            setTempTherapyPreferences({
              ...tempTherapyPreferences,
              preferredTherapistGender: e,
            });
          }}
          style={{
            width: 300,
          }}
        >
          <Select.Option key={TherapistGender.ANY}>Any</Select.Option>
          <Select.Option key={TherapistGender.FEMALE}>Female</Select.Option>
          <Select.Option key={TherapistGender.MALE}>Male</Select.Option>
          <Select.Option key={TherapistGender.NON_BINARY}>Non-Binary</Select.Option>
        </Select>
      </Row>
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={tempTherapyPreferences.preferredTherapistGender === undefined}
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClientFile({
              therapyPreferences: tempTherapyPreferences,
            });
            setClientFileIntakeQuestionnaireStatus(
              ClientFileIntakeQuestionnaireStates.ENTER_OTHER_CAREGIVER
            );
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
