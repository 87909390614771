import { Button, Typography } from "antd";
import React from "react";

import { InquiryStates } from "../../consts";

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
};

export const EnterDiagnoses: React.FC<IProps> = ({ setInquiryStatus, updateInquiry }: IProps) => {
  return (
    <>
      <Title style={{ fontFamily: "Reckless Neue", maxWidth: 1000 }}>
        Has your child recieved a recommendation for ABA Therapy?
      </Title>
      <Text style={{ display: "block", marginBottom: 30, fontWeight: 400 }}>
        This is a formal diagnosis or note of recommendation typically given by a child
        psychologist, pediatric neurologist, or developmental pediatrician.
      </Text>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          height: 44,
          width: 150,
        }}
        onClick={() => {
          updateInquiry({
            isDiagnosed: true,
          });
          setInquiryStatus(InquiryStates.CONTACT_FORM);
        }}
      >
        Yes
      </Button>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          marginLeft: 20,
          height: 44,
          width: 150,
        }}
        onClick={() => {
          updateInquiry({
            isDiagnosed: false,
          });
          setInquiryStatus(InquiryStates.UNSUPPORTED_DIAGNOSES);
        }}
      >
        No
      </Button>
    </>
  );
};
