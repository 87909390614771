import { z } from "zod";

import { IMixpanelEvent } from "../types";

export const trackEventEndpointRequestSchema = z
  .object({
    eventName: z.nativeEnum(IMixpanelEvent),
    userId: z.string().min(1),
    clinicId: z.string().min(1),
    properties: z.any().optional(),
  })
  .strict();

export type ITrackEventEndpointRequest = z.input<typeof trackEventEndpointRequestSchema>;
