import { z } from "zod";

import { baseLibraryDocumentSchema } from "../types";

export const createLibraryEndpointRequestSchema = baseLibraryDocumentSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .extend({
    clinicId: z.string(),
  })
  .strict();

export type ICreateLibraryEndpointRequest = z.input<typeof createLibraryEndpointRequestSchema>;

export const createFolderEndpointRequestSchema = baseLibraryDocumentSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .extend({
    parentPath: z.string(),
    tagIds: z.array(z.string()),
  })
  .strict();

export type ICreateFolderEndpointRequest = z.input<typeof createFolderEndpointRequestSchema>;

export const createTemplateReferenceRequestSchema = z
  .object({
    description: z.string().optional(),
    currentPath: z.string(),
    templateId: z.string(),
    tagIds: z.array(z.string()),
  })
  .strict();

export type ICreateTemplateReferenceRequest = z.input<typeof createTemplateReferenceRequestSchema>;
