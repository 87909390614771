import { createContext, useContext, useEffect, useState } from "react";

import { MOBILE_WINDOW_WIDTH_MAX } from "../consts";

// Create a context for responsive data
const ResponsiveContext = createContext({ windowWidth: 0, isMobile: false });

interface IProps {
  children: React.ReactNode;
}

// Provider component that manages responsive data
export const ResponsiveProvider = ({ children }: IProps) => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" ? window.innerWidth < MOBILE_WINDOW_WIDTH_MAX : false
  );

  // Update windowWidth when the window is resized
  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setIsMobile(window.innerWidth < MOBILE_WINDOW_WIDTH_MAX);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ResponsiveContext.Provider value={{ windowWidth, isMobile }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

// Custom hook to access responsive data
export const useResponsive = () => {
  const context = useContext(ResponsiveContext);
  if (!context) {
    throw new Error("useResponsive must be used within a ResponsiveProvider");
  }
  return context;
};
