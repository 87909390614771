import { Button, Typography } from "antd";
import React from "react";
import Lottie from "react-lottie-player";

import foxCelebrateAnimation from "../../lottie/fox-celebrate.json";

const { Title, Text } = Typography;

export const Submitted: React.FC = () => {
  return (
    <>
      <div style={{ marginTop: -60, marginBottom: 40 }}>
        <Lottie
          play
          loop={false}
          animationData={foxCelebrateAnimation}
          style={{
            width: 125,
            height: 125,
            margin: "auto",
            marginTop: -70,
            marginBottom: 20,
          }}
        />
      </div>
      <Title style={{ fontFamily: "Reckless Neue" }}>Your information has been submitted</Title>
      <Text
        style={{
          display: "block",
          marginBottom: 30,
          marginRight: "auto",
          marginLeft: "auto",
          fontWeight: 400,
          maxWidth: 530,
        }}
      >
        {`We'll reach out shortly with next steps.`}
      </Text>
      <a href="https://www.finnihealth.com/">
        <Button shape="round" style={{ height: 40, width: 100 }}>
          Done
        </Button>
      </a>
    </>
  );
};
