//colors
export const COLORS = {
  PRIMARY: "#ed762f",
  LIGHT_ORANGE: "#FA8E46",
  NEUTRAL_ORANGE: "#eec1a9",

  LIGHT_PURPLE: "#D1BCE7",
  DARK_PURPLE: "#4A3A54",

  GREEN: "#06d6a0",
  STANDARD_GREEN: "green",

  YELLOW: "#ffd166",
  DARK_YELLOW: "#F46E65",

  STANDARD_RED: "#FF0000",
  RED: "#ef476f",

  NEUTRAL_BLUE: "#CDEDF6",
  LIGHT_BLUE: "#0096c7",
  BLUE: "#108EE9",

  WHITE: "#ffffff",
  GRAY: "#dfdfdf",
  LIGHT_GRAY: "#e4e4e4",
  EXTRA_LIGHT_GRAY: "#f5f5fa",
  BORDER_GRAY: "#e6e6f5",
  DARK_GRAY: "#bfbfbf",
  GRAY_5: "#9B9BC2",
  LINK: "#1677ff",
};

export const MOBILE_WINDOW_WIDTH_MAX = 850;
export const EXTRA_SMALL_MOBILE_WIDTH_MAX = 375;
export const NOTION_HELP_URL =
  "https://www.notion.so/finnihealth/1c4a899828454ae187120c05ceb1335f?v=09b14fa01c51413f86f371d2a66a6cd3&pvs=4";
export const MAX_Z_INDEX = 9999999;
export const OVERLAY_Z_INDEX = 999999;
