export enum TransportMode {
  DRIVING = "driving",
  BICYCLING = "bicycling",
  WALKING = "walking",
  TRANSIT = "transit",
}

export interface IAddressDistanceEndpointRequest {
  addressA: string;
  addressB: string;
  departureTime?: string;
}

export interface IMapVisualRepresentation {
  text: string;
  value: number;
}

export type IAddressDistanceEndpointResponse = {
  [mode in TransportMode]: {
    duration: IMapVisualRepresentation;
    distance: IMapVisualRepresentation;
    durationInTraffic: IMapVisualRepresentation;
  };
};
