import { CheckCircleOutlined } from "@ant-design/icons";
import { COLORS } from "@finni-health/ui";
import { useResponsive } from "@finni-health/ui";
import { Avatar, Col, Row, Typography } from "antd";
import React, { ReactNode } from "react";

const { Title, Text } = Typography;

interface IProps {
  icon: ReactNode;
  isComplete: boolean;
  title: string;
  subtitle: string;
}

export const TasksCardTask: React.FC<IProps> = ({ icon, isComplete, title, subtitle }: IProps) => {
  const { isMobile } = useResponsive();

  return (
    <Row align="middle">
      <Col span={isMobile ? 3 : 2}>
        <Avatar
          size={isMobile ? "small" : "default"}
          icon={isComplete ? <CheckCircleOutlined /> : icon}
          style={{
            color: COLORS.DARK_PURPLE,
            background: isComplete ? COLORS.LIGHT_PURPLE : "#00000012",
          }}
        />
      </Col>
      <Col span={isMobile ? 21 : 22}>
        <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
          {title}
        </Title>
        <Text>{subtitle}</Text>
      </Col>
    </Row>
  );
};
