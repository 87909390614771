import { getAuth } from "firebase/auth";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import app from "../services/firebase";

/**
 * Helper component to ensure user is Authenticated before accessing the route.
 */
export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return getAuth(app).currentUser ? (
          <>{children}</>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        );
      }}
    />
  );
};
