import { Button, Typography } from "antd";
import React from "react";

import { InquiryStates } from "../../consts";

const { Title, Text } = Typography;

type IProps = {
  setInquiryStatus: (value: any) => void;
  updateInquiry: (source: any) => void;
};

export const EnterIsGuardian: React.FC<IProps> = ({ setInquiryStatus, updateInquiry }: IProps) => {
  return (
    <>
      <Title style={{ fontFamily: "Reckless Neue" }}>
        {`Are you the child's parent or legal guardian?`}
      </Title>
      <Text style={{ display: "block", marginBottom: 30, fontWeight: 400 }}>
        We just need to double check that we can work directly with you. <br /> If you are refering
        someone, please select No.
      </Text>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          height: 44,
          width: 150,
        }}
        onClick={() => {
          updateInquiry({
            isGuardian: true,
          });
          setInquiryStatus(InquiryStates.ENTER_DIAGNOSES);
        }}
      >
        Yes
      </Button>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          marginLeft: 20,
          height: 44,
          width: 150,
        }}
        onClick={() => {
          updateInquiry({
            isGuardian: false,
          });
          setInquiryStatus(InquiryStates.UNSUPPORTED_GUARDIAN_STATUS);
        }}
      >
        No
      </Button>
    </>
  );
};
