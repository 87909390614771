import {
  AppointmentType,
  BillingCode,
  IAppointment,
  IAppointmentConfig,
  ICompletedAppointment,
  IPayer,
  Modifier,
  ProviderType,
} from "../../types";
import { getBaseBillingModifiers } from "./helpers";

export const TENNESSEE_APPOINTMENTS: IAppointmentConfig[] = [
  {
    name: AppointmentType.INITIAL_ASSESSMENT_TELEHEALTH,
    billingCode: BillingCode.CODE_97151,
    modifiers: [Modifier.GT],
  },
  {
    name: AppointmentType.ADDITIONAL_ASSESSMENT_TELEHEALTH,
    billingCode: BillingCode.CODE_97152,
    modifiers: [Modifier.GT],
  },
  {
    name: AppointmentType.ADAPTIVE_BEHAVIOR_TREATMENT_TELEHEALTH,
    billingCode: BillingCode.CODE_97153,
    modifiers: [Modifier.GT],
  },
  {
    name: AppointmentType.GROUP_ADAPTIVE_BEHAVIOR_TELEHEALTH,
    billingCode: BillingCode.CODE_97154,
    modifiers: [Modifier.GT],
  },
  {
    name: AppointmentType.DIRECT_SERVICES_TELEHEALTH,
    billingCode: BillingCode.CODE_97155,
    modifiers: [Modifier.GT],
  },
  {
    name: AppointmentType.PARENT_TRAINING_TELEHEALTH,
    billingCode: BillingCode.CODE_97156,
    modifiers: [Modifier.GT],
  },
  {
    name: AppointmentType.GROUP_ADAPTIVE_BEHAVIOR_TELEHEALTH,
    billingCode: BillingCode.CODE_97157,
    modifiers: [Modifier.GT],
  },
  {
    name: AppointmentType.GROUP_ADAPTIVE_BEHAVIOR_TELEHEALTH,
    billingCode: BillingCode.CODE_97158,
    modifiers: [Modifier.GT],
  },
];

const getStateBillingModifiers = (
  appointment: IAppointment | ICompletedAppointment,
  payer: IPayer,
  providerType: ProviderType
) => {
  return getBaseBillingModifiers(appointment, payer, providerType);
};

export const tennesseeBillingModifier = getStateBillingModifiers;
