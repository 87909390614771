import { z } from "zod";

import { transitionCriteriaSchema } from "../types";

export const createTransitionCriteriaEndpointRequestSchema = transitionCriteriaSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .strict();

export type ICreateTransitionCriteriaEndpointRequest = z.input<
  typeof createTransitionCriteriaEndpointRequestSchema
>;

export const updateTransitionCriteriaEndpointRequestSchema = transitionCriteriaSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    id: z.string(),
  })
  .strict();

export type IUpdateTransitionCriteriaEndpointRequest = z.input<
  typeof updateTransitionCriteriaEndpointRequestSchema
>;
