import { BehaviorFrequency, BehaviorSeverity, IClientFile } from "@finni-health/shared";
import { Button, Card, Radio, Row, Slider, Space, Typography } from "antd";
import React, { ReactNode } from "react";

import { frequencyMarks, selected, unselected } from "../../../consts";
import { getBehaviorFrequency } from "../../../helpers/get-behavior-frequency";
import { getBehaviorFrequencyValue } from "../../../helpers/get-behavior-frequency-value";

const { Title, Text } = Typography;

type IProps = {
  isMobile: boolean;
  tempBehaviors: any;
  setTempBehaviors: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClientFile: (req: Partial<IClientFile>) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterFallingDownFrequency: React.FC<IProps> = ({
  isMobile,
  tempBehaviors,
  setTempBehaviors,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClientFile,
  backButton,
}: IProps) => {
  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          Does your child fall or drop themselves to the ground?
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.flopOnGround !== undefined ? selected : unselected}
          onClick={() => {
            setTempBehaviors({
              ...tempBehaviors,
              flopOnGround: {
                ...tempBehaviors.flopOnGround!,
                frequency: tempBehaviors.flopOnGround?.frequency || BehaviorFrequency.MONTHLY,
              },
            });
          }}
        >
          Yes
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={tempBehaviors.flopOnGround === undefined ? selected : unselected}
          onClick={() => {
            const newTempBehaviors = tempBehaviors;
            delete newTempBehaviors.flopOnGround;
            setTempBehaviors({ ...newTempBehaviors });
          }}
        >
          No
        </Button>
      </Space>
      {tempBehaviors.flopOnGround !== undefined && (
        <>
          Severity
          <Radio.Group
            style={{ marginBottom: 25 }}
            onChange={(e) => {
              setTempBehaviors({
                ...tempBehaviors,
                flopOnGround: {
                  ...tempBehaviors.flopOnGround!,
                  severity: e.target.value,
                },
              });
            }}
            value={tempBehaviors.flopOnGround.severity}
          >
            <Space direction="vertical">
              <Radio value={BehaviorSeverity.LOW}>
                Child lays on the ground, but stands up immediately when told to do so
              </Radio>
              <Radio value={BehaviorSeverity.MEDIUM}>
                Child lays on the ground, but will stand up with a light physical touch prompt
              </Radio>
              <Radio value={BehaviorSeverity.HIGH}>
                Child lays on the ground and will not stand up until physically assisted up
              </Radio>
              <Radio value={BehaviorSeverity.EXTREME}>
                Child lays on the ground and will not stand up even with physical assistance
              </Radio>
            </Space>
          </Radio.Group>
          Frequency
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Slider
              tooltipVisible={false}
              max={4}
              marks={frequencyMarks}
              step={null}
              onAfterChange={(e) => {
                setTempBehaviors({
                  ...tempBehaviors,
                  flopOnGround: {
                    ...tempBehaviors.flopOnGround!,
                    frequency: getBehaviorFrequency(e),
                  },
                });
              }}
              style={{ width: "75%", marginBottom: 50, alignSelf: "center" }}
              value={getBehaviorFrequencyValue(tempBehaviors.flopOnGround.frequency)}
            />
          </div>
          <Text>Does this happen at home?</Text>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.flopOnGround?.inHome ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  flopOnGround: {
                    ...tempBehaviors.flopOnGround!,
                    inHome: true,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.flopOnGround?.inHome === false ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  flopOnGround: {
                    ...tempBehaviors.flopOnGround!,
                    inHome: false,
                  },
                });
              }}
            >
              No
            </Button>
          </Space>
          <Text>Does this happen outside of home?</Text>
          <Space
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 25,
            }}
          >
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.flopOnGround?.outsideHome ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  flopOnGround: {
                    ...tempBehaviors.flopOnGround!,
                    outsideHome: true,
                  },
                });
              }}
            >
              Yes
            </Button>
            <Button
              htmlType="submit"
              shape="round"
              style={tempBehaviors.flopOnGround?.outsideHome === false ? selected : unselected}
              onClick={() => {
                setTempBehaviors({
                  ...tempBehaviors,
                  flopOnGround: {
                    ...tempBehaviors.flopOnGround!,
                    outsideHome: false,
                  },
                });
              }}
            >
              No
            </Button>
          </Space>
        </>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={
            tempBehaviors.flopOnGround !== undefined &&
            (tempBehaviors.flopOnGround?.inHome === undefined ||
              tempBehaviors.flopOnGround?.outsideHome === undefined ||
              tempBehaviors.flopOnGround?.severity === undefined)
          }
          type="primary"
          shape="round"
          onClick={() => {
            return updateClientFile({
              behaviors: tempBehaviors,
            }).then(() => {
              setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
            });
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
