import { z } from "zod";

// Deprecated.
export const createSessionEndpointRequestSchema = z
  .object({
    clinicId: z.string().min(1),
    userId: z.string().min(1),
    clientId: z.string().min(1),
  })
  .strict();

// Deprecated.
export type ICreateSessionEndpointRequest = z.input<typeof createSessionEndpointRequestSchema>;

export const createSessionFromAppointmentEndpointRequestSchema = z.object({
  userId: z.string().min(1),
  appointmentId: z.string().min(1),
});

export type ICreateSessionFromAppointmentEndpointRequest = z.input<
  typeof createSessionFromAppointmentEndpointRequestSchema
>;
