import { z } from "zod";

import {
  addressSchema,
  clientPayersSchema,
  IGuardian,
  IntakeStatus,
  phoneNumberSchema,
} from "../types";

export const createGuardianEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  firstName: z.string().min(1),
  middleName: z.string(),
  lastName: z.string().min(1),
  phoneNumber: phoneNumberSchema,
  email: z.string().email(),
  password: z.string().min(1),
  address: addressSchema,
  addressNotes: z.string(),
  intakeStatus: z.nativeEnum(IntakeStatus),
  intakeStatusNotes: z.string(),
  payers: clientPayersSchema,
});

export type ICreateGuardianEndpointRequest = z.input<typeof createGuardianEndpointRequestSchema>;

export type IUpdateGuardianEndpointRequest = Partial<IGuardian> & Pick<IGuardian, "id">;

export interface IGetGuardianWithClientDetailsEndpointRequest {
  guardianId?: string;
  clinicId?: string;
}
