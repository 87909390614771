import { z } from "zod";

export interface IBatchBillEndpointRequest {
  completedAppointmentIds: string[];
}

export interface IBatchRecompleteEndpointRequest {
  completedAppointmentIds: string[];
}

export const batchHoldEndpointRequestSchema = z.object({
  completedAppointmentIds: z.array(z.string()),
});

export type IBatchHoldEndpointRequest = z.input<typeof batchHoldEndpointRequestSchema>;

export const adonisUploadEndpointRequestSchema = z.object({
  locationPath: z.string().min(1),
});

export type IAdonisUploadEndpointRequest = z.input<typeof adonisUploadEndpointRequestSchema>;
