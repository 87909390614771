import moment from "moment-timezone";

import { Weekday } from "../types";

export const selectedCellsToDataModel = (selectedCells: boolean[][]) => {
  const dataModel: { [key in Weekday]: { startMs: number; endMs: number }[] } = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };

  Object.keys(dataModel).forEach((day, i) => {
    let startSlot: moment.Moment | null = null;
    let endSlot: moment.Moment | null = null;

    selectedCells.forEach((row, j) => {
      if (row[i]) {
        if (startSlot === null) {
          startSlot = moment()
            .day(day)
            .hours(7)
            .minutes(j * 30)
            .seconds(0)
            .milliseconds(0);
          endSlot = moment(startSlot).add(30, "minutes");
        } else {
          endSlot = moment()
            .day(day)
            .hours(7)
            .minutes(j * 30)
            .seconds(0)
            .milliseconds(0)
            .add(30, "minutes");
        }
      } else if (startSlot !== null && endSlot !== null) {
        dataModel[day as Weekday].push({
          startMs: startSlot.valueOf(),
          endMs: endSlot.valueOf(),
        });
        startSlot = null;
        endSlot = null;
      }
    });
  });

  return dataModel;
};

export const dataModelToSelectedCells = (dataModel: {
  [key: string]: { startMs: number; endMs: number }[];
}) => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const selectedCells: boolean[][] = Array(30)
    .fill(0)
    .map(() => Array(7).fill(false));

  days.forEach((day, i) => {
    dataModel?.[day]?.forEach(({ startMs, endMs }) => {
      const start = moment(startMs);
      const end = moment(endMs);
      const startSlot = Math.max((start.hour() - 7) * 2 + Math.floor(start.minute() / 30), 0);
      const endSlot = Math.max((end.hour() - 7) * 2 + Math.floor(end.minute() / 30), 0);

      for (let j = startSlot; j < endSlot; j++) {
        selectedCells[j][i] = true;
      }
    });
  });

  return selectedCells;
};
