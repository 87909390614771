import { PhoneOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";
import InputMask from "react-input-mask";

const DEFAULT_PREFIX = "(";
const COUNTRY_CODE_PREFIX = "+1 ";

const PHONE_NUMBER_MASK = "999) 999-9999";

const DEFAULT_PHONE_FORMAT = DEFAULT_PREFIX + PHONE_NUMBER_MASK;
const PHONE_WITH_COUNTRY_CODE = COUNTRY_CODE_PREFIX + DEFAULT_PHONE_FORMAT;

export const PhoneNumberInput = (props: any) => {
  const [mask, setMask] = useState<string>(DEFAULT_PHONE_FORMAT);

  const beforeMaskedStateChange = ({ nextState, previousState, currentState }: any) => {
    let { value, selection } = nextState;
    const oldValue = previousState?.value;
    let cursorPosition = selection?.start;

    // remove leading "("" if input is still blank
    if ((value === DEFAULT_PREFIX && currentState === undefined) || currentState?.value === "") {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    if (
      oldValue !== undefined &&
      value !== undefined &&
      [COUNTRY_CODE_PREFIX, COUNTRY_CODE_PREFIX + DEFAULT_PREFIX, ""].includes(value) &&
      oldValue.length > value.length
    ) {
      // User deleted their entire input, reset mask and value to default
      value = "";
      setMask(DEFAULT_PHONE_FORMAT);
    } else if (value !== undefined && value.startsWith(DEFAULT_PREFIX + "1")) {
      // user entered country code, remove leading "1" and set mask accordingly
      value = value.slice(DEFAULT_PREFIX.length, -1);
      setMask(PHONE_WITH_COUNTRY_CODE);
    } else if (value === COUNTRY_CODE_PREFIX + DEFAULT_PREFIX) {
      // super stupid library bug, gotta set selection manually here on mask change
      const prefixLength = (COUNTRY_CODE_PREFIX + DEFAULT_PREFIX).length;
      selection = { start: prefixLength, end: prefixLength };
    }

    return {
      value,
      selection,
    };
  };

  return (
    <InputMask
      {...props}
      mask={mask}
      maskPlaceholder={null}
      beforeMaskedStateChange={beforeMaskedStateChange}
    >
      <Input type="tel" prefix={<PhoneOutlined />} {...props} />
    </InputMask>
  );
};
