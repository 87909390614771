import { z } from "zod";

export const CalendarGetAppointmentsForClinicAndRangeEndpointRequestSchema = z.object({
  clinicId: z.string(),
  // Using coerce because this is the request param. So everything converts to string.
  // Need to convert it back to number.
  // Without coerce, zod validation fails.
  startMs: z.coerce.number(),
  endMs: z.coerce.number(),
  timeZone: z.string(),
});
export type ICalendarGetAppointmentsForClinicAndRangeEndpointRequest = z.input<
  typeof CalendarGetAppointmentsForClinicAndRangeEndpointRequestSchema
>;
