import { z } from "zod";

import { PayerVerificationType, USStateCode } from "../types";

// Custom transformer to enforce MM/DD/YYYY format for the dateOfBirth field
const payerVerificationDateFormat = z.string().refine(
  (data) => {
    const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    if (typeof data === "string" && regex.test(data)) {
      return true;
    }
    return false;
  },
  {
    message: "Invalid date of birth format. Must be in MM/DD/YYYY format.",
  }
);

/**
 * @param {string} firstName - The first name of the patient.
 * @param {string} lastName - The last name of the patient.
 * @param {string} dateOfBirth - The date of birth of the patient (in the format "MM/DD/YYYY").
 * @param {string} state - The state in which the patient resides.
 */
export const payerVerificationPatientInfoSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  dateOfBirth: payerVerificationDateFormat,
  state: z.nativeEnum(USStateCode),
});

/**
 * Used if the policy holder is not self
 * @param {string} firstName - The first name of the patient.
 * @param {string} lastName - The last name of the patient.
 * @param {string} dateOfBirth - The date of birth of the patient (in the format "MM/DD/YYYY").
 * @param {string} state - The state in which the patient resides.
 */
export const payerVerificationPolicyHolderSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  dateOfBirth: payerVerificationDateFormat,
});

export const payerVerificationPayerInfoSchema = z.object({
  payerId: z.string().min(1),
  payerName: z.string().min(1),
  memberId: z.string().min(1),
});

export const createPayerVerificationEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  clientFileId: z.string().min(1),
  payerInfo: payerVerificationPayerInfoSchema,
  patientInfo: payerVerificationPatientInfoSchema,
  policyHolder: payerVerificationPolicyHolderSchema.optional(),
  type: z.nativeEnum(PayerVerificationType),
  id: z.string().optional(),
  npi: z.string().optional(),
});

export type ICreatePayerVerificationEndpointRequest = z.input<
  typeof createPayerVerificationEndpointRequestSchema
>;

export type IPayerVerificationPatientInfoRequest = z.input<
  typeof payerVerificationPatientInfoSchema
>;

export type IPayerVerificationPolicyHolder = z.input<typeof payerVerificationPolicyHolderSchema>;

export const getLatestPayerVerificationEndpointRequestSchema = z.object({
  clinicId: z.string().min(1),
  clientFileId: z.string().min(1),
  patientId: z.string().min(1),
  type: z.nativeEnum(PayerVerificationType),
});

export type IGetLatestPayerVerificationEndpointRequest = z.input<
  typeof getLatestPayerVerificationEndpointRequestSchema
>;

export const getPayerVerificationByIdEndpointRequestSchema =
  getLatestPayerVerificationEndpointRequestSchema.extend({
    verificationId: z.string().min(1),
  });

export type IGetPayerVerificationByIdEndpointRequest = z.input<
  typeof getPayerVerificationByIdEndpointRequestSchema
>;
