import { UserOutlined } from "@ant-design/icons";
import { useResponsive } from "@finni-health/ui";
import { Button, Col, Divider, Form, Input, message, Row, Typography } from "antd";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";

import app from "../services/firebase";

const { Title, Text } = Typography;

interface IPasswordResetFormValues {
  email: string;
}

export const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const isLoggedIn = Boolean(getAuth(app).currentUser);

  const { isMobile } = useResponsive();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendPassResetEmail = (values: IPasswordResetFormValues) => {
    setIsLoading(true);
    const { email } = values;

    sendPasswordResetEmail(getAuth(app), email)
      .then(() => {
        void message.success(
          "An email has been sent with password reset instructions - be sure to check spam!"
        );
        history.push("/login");
      })
      .catch(() => {
        void message.error(
          "Oops! Something went wrong. Please try again or reach out to care@finnihealth.com for help"
        );
      });
    setIsLoading(false);
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Row style={{ height: "100%", overflow: "hidden" }} justify="center">
      <Row
        style={{
          width: "100%",
          marginTop: 30,
          paddingBottom: 30,
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
        justify="center"
        align="middle"
      >
        <Col>
          <a
            href="/"
            style={{
              display: "flex",
              justifyContent: isMobile ? "center" : "start",
              width: "100%",
            }}
          >
            <img src="/logo.svg" height={25} />
          </a>
        </Col>
      </Row>
      <Col span={isMobile ? 20 : 12}>
        <Row justify="center" align="middle" style={{ textAlign: "center" }}>
          <Col style={{ marginTop: 130 }}>
            <Title style={{ fontFamily: "Reckless Neue" }}>Reset your password</Title>
            <Text
              style={{
                display: "block",
                marginBottom: 30,
                marginRight: "auto",
                marginLeft: "auto",
                fontWeight: 400,
                maxWidth: 530,
              }}
            >
              {`Enter the email address associated with your account and we'll
              send you a link to reset your password.`}
            </Text>
            <Form name="basic" onFinish={sendPassResetEmail}>
              <Form.Item name="email" rules={[{ required: true, message: "Email is required" }]}>
                <Input prefix={<UserOutlined />} placeholder="Email Address" />
              </Form.Item>
              <Row justify="center" align="middle" style={{ width: "100%" }}>
                <Form.Item
                  style={{
                    width: "50%",
                    minWidth: 125,
                    marginBottom: 0,
                  }}
                >
                  <Button
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    loading={isLoading}
                    style={{ width: "100%" }}
                  >
                    Send
                  </Button>
                </Form.Item>
              </Row>
            </Form>

            <Divider style={{ fontSize: 14 }}>or</Divider>

            <Row justify="center">
              <Link
                to="/inquire"
                style={{
                  width: "50%",
                  minWidth: 125,
                }}
              >
                <Button shape="round" style={{ width: "100%" }}>
                  Sign Up
                </Button>
              </Link>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
