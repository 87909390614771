import { z } from "zod";

import {
  CancellationReason,
  CancellationRejectionReason,
  CancelledCompletedAppointmentStatus,
  ICompletedAppointment,
} from "../types/calendar";
import { ExportFilter } from "./payroll";

export const approveCancelledCompletedAppointmentEndpointRequestSchema = z.object({
  appointmentId: z.string().min(1),
});
export type IApproveCancelledCompletedAppointmentEndpointRequest = z.input<
  typeof approveCancelledCompletedAppointmentEndpointRequestSchema
>;

export const rejectCancelledCompletedAppointmentEndpointRequestSchema = z.object({
  appointmentId: z.string().min(1),
  cancellationRejectionReason: z.nativeEnum(CancellationRejectionReason),
  cancellationRejectionNotes: z.string(),
});
export type IRejectCancelledCompletedAppointmentEndpointRequest = z.input<
  typeof rejectCancelledCompletedAppointmentEndpointRequestSchema
>;

export interface ICreateCancelledCompletedAppointmentEndpointRequest {
  appointmentId: string;
  cancellationReason: CancellationReason;
  cancellationNotes: string;
  cancellationStatus: CancelledCompletedAppointmentStatus;
}

export interface IUnCancelCompletedAppointmentsForClinicEndpointRequest {
  appointmentId: string;
}

export interface ICreateCompletedAppointmentEndpointRequest {
  noteId: string;
  timeZone: string;
}

export const createCompletedAppointmentWithoutNoteEndpointRequestSchema = z.object({
  appointmentId: z.string().min(1),
});
export type ICreateCompletedAppointmentWithoutNoteEndpointRequest = z.input<
  typeof createCompletedAppointmentWithoutNoteEndpointRequestSchema
>;

export interface IGetCompletedAppointmentsForClinicEndpointRequest {
  clinicId: string;
}

export interface IGetCompletedAppointmentsForClinicAndRangeEndpointRequest {
  clinicId: string;
  startMs: number;
  endMs: number;
  exportBy?: ExportFilter;
}

export interface IGetCompletedAppointmentsForClinicClientAndRangeEndpointRequest
  extends IGetCompletedAppointmentsForClinicAndRangeEndpointRequest {
  clientId: string;
}

export type IUpdateCompletedAppointmentEndpointRequest = Partial<ICompletedAppointment> &
  Pick<ICompletedAppointment, "id">;

export interface IDeleteCompletedAppointmentEndpointRequest {
  id: string;
}
