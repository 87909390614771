import { IClientFile } from "@finni-health/shared";
import { Button, Card, Form, Input, Row, Space, Typography } from "antd";
import React, { ReactNode } from "react";

import { ClientFileIntakeQuestionnaireStates, selected, unselected } from "../../../consts";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempTherapyPreferences: any;
  setTempTherapyPreferences: (value: any) => void;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClientFile: (req: Partial<IClientFile>) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterPrimaryLanguage: React.FC<IProps> = ({
  isMobile,
  tempTherapyPreferences,
  setTempTherapyPreferences,
  setClientFileIntakeQuestionnaireStatus,
  updateClientFile,
  backButton,
}: IProps) => {
  return (
    <Card
      title={
        <Title
          level={5}
          style={{
            fontWeight: 400,
            whiteSpace: "pre-line",
          }}
        >
          {`What is the primary language spoken at your child's home?`}
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={tempTherapyPreferences.primaryLanguage === "english" ? selected : unselected}
          onClick={() => {
            setTempTherapyPreferences({
              ...tempTherapyPreferences,
              primaryLanguage: "english",
            });
          }}
        >
          English
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempTherapyPreferences.primaryLanguage !== "english" &&
            tempTherapyPreferences.primaryLanguage !== undefined
              ? selected
              : unselected
          }
          onClick={() => {
            setTempTherapyPreferences({
              ...tempTherapyPreferences,
              primaryLanguage: "",
            });
          }}
        >
          Other
        </Button>
      </Space>
      {tempTherapyPreferences.primaryLanguage !== "english" &&
        tempTherapyPreferences.primaryLanguage !== undefined && (
          <Form layout="vertical" labelCol={{ span: 24 }}>
            <Form.Item
              label="Primary Language"
              name="primaryLanguage"
              rules={[
                {
                  required: true,
                  message: "Please enter your household's primary language",
                },
              ]}
              initialValue={tempTherapyPreferences.primaryLanguage}
              style={{
                maxWidth: 450,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 25,
              }}
            >
              <Input
                onChange={(e) => {
                  setTempTherapyPreferences({
                    ...tempTherapyPreferences,
                    primaryLanguage: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Form>
        )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={
            tempTherapyPreferences.primaryLanguage === "" ||
            tempTherapyPreferences.primaryLanguage === undefined
          }
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClientFile({
              therapyPreferences: tempTherapyPreferences,
            });
            setClientFileIntakeQuestionnaireStatus(
              ClientFileIntakeQuestionnaireStates.ENTER_PREFERRED_LANGUAGE
            );
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
