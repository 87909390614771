import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";

import app from "../services/firebase";

export const signIn = async (
  email: string,
  password: string,
  next: (_user: any) => void,
  error: (_error: any) => void
) => {
  const auth = getAuth(app);
  await setPersistence(auth, browserSessionPersistence);
  await signInWithEmailAndPassword(auth, email, password).then(next).catch(error);
};
