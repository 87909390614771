import { Button, Typography } from "antd";
import React from "react";

const { Title } = Typography;

type IProps = {
  setShouldAskLanguagePref: (value: boolean) => void;
  updateInquiry: (source: any) => void;
};

export const EnterLanguagePreference: React.FC<IProps> = ({
  setShouldAskLanguagePref,
  updateInquiry,
}: IProps) => {
  return (
    <>
      <Title style={{ fontFamily: "Reckless Neue" }}>
        Would you prefer a Spanish-speaking team member reach out?
      </Title>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          height: 44,
          width: 150,
        }}
        onClick={() => {
          updateInquiry({
            intakeStatusNotes: "Prefers Spanish-speaking",
          });
          setShouldAskLanguagePref(false);
        }}
      >
        Yes
      </Button>
      <Button
        htmlType="submit"
        shape="round"
        style={{
          marginLeft: 20,
          height: 44,
          width: 150,
        }}
        onClick={() => {
          setShouldAskLanguagePref(false);
        }}
      >
        No
      </Button>
    </>
  );
};
