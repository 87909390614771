import { BehaviorFrequency } from "@finni-health/shared";

export const getBehaviorFrequencyValue = (frequency: BehaviorFrequency) => {
  switch (frequency) {
    case BehaviorFrequency.MONTHLY:
      return 0;
    case BehaviorFrequency.WEEKLY:
      return 1;
    case BehaviorFrequency.DAILY:
      return 2;
    case BehaviorFrequency.HOURLY:
      return 3;
    case BehaviorFrequency.CONSTANTLY:
      return 4;
  }
};
