import { Row } from "antd";
import React from "react";

import { HoursProgressBar } from "./HoursProgressBar";

interface IProps {
  minimumHours: number;
  recommendedHours: number;
  inputHours: number;
}

export const ClientHours: React.FC<IProps> = ({ minimumHours, recommendedHours, inputHours }) => {
  return (
    <Row
      justify="space-evenly"
      style={{
        width: "100%",
        maxWidth: 700,
        marginTop: 20,
      }}
    >
      <HoursProgressBar title="Recommended" hours={recommendedHours} inputHours={inputHours} />
      <HoursProgressBar title="Minimum" hours={minimumHours} inputHours={inputHours} />
    </Row>
  );
};
