import { ArrowLeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  IBehaviors,
  IClientFile,
  IMedicalHistory,
  ITherapyPreferences,
} from "@finni-health/shared";
import { useResponsive } from "@finni-health/ui";
import { Button, Col, Modal, Row, Steps, Typography } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { ClientFileIntakeQuestionnaireStates } from "../../consts";
import { getRemainingIntakeStepsCount } from "../../helpers/client-file-status";
import { getCurrentIntakeQuestionnaireStatus } from "../../helpers/get-current-intake-questionnaire-status";
import { getOrCreateClientFile } from "../../helpers/get-or-create-client-file";
import { Loading } from "../../pages/Loading";
import * as FirestoreService from "../../services/firestore";
import { AuthContext } from "../AuthProvider";
import { EnterAggressionFrequency } from "./IntakeQuestionnaireSections/EnterAggressionFrequency";
import { EnterAvailability } from "./IntakeQuestionnaireSections/EnterAvailability";
import { EnterCurrentServices } from "./IntakeQuestionnaireSections/EnterCurrentServices";
import { EnterDestroyingPropertyFrequency } from "./IntakeQuestionnaireSections/EnterDestroyingPropertyFrequency";
import { EnterFallingDownFrequency } from "./IntakeQuestionnaireSections/EnterFallingDownFrequency";
import { EnterFamilyMedicalHistory } from "./IntakeQuestionnaireSections/EnterFamilyMedicalHistory";
import { EnterGoals } from "./IntakeQuestionnaireSections/EnterGoals";
import { EnterLivingSituation } from "./IntakeQuestionnaireSections/EnterLivingSituation";
import { EnterMedicalConditions } from "./IntakeQuestionnaireSections/EnterMedicalConditions";
import { EnterOtherCaregiver } from "./IntakeQuestionnaireSections/EnterOtherCaregiver";
import { EnterOtherIssues } from "./IntakeQuestionnaireSections/EnterOtherIssues";
import { EnterPreferredLanguage } from "./IntakeQuestionnaireSections/EnterPreferredLanguage";
import { EnterPreferredTherapistGender } from "./IntakeQuestionnaireSections/EnterPreferredTherapistGender";
import { EnterPrescribedMedication } from "./IntakeQuestionnaireSections/EnterPrescribedMedication";
import { EnterPreviousServices } from "./IntakeQuestionnaireSections/EnterPreviousServices";
import { EnterPrimaryLanguage } from "./IntakeQuestionnaireSections/EnterPrimaryLanguage";
import { EnterRunningAwayFrequency } from "./IntakeQuestionnaireSections/EnterRunningAwayFrequency";
import { EnterSelfHarm } from "./IntakeQuestionnaireSections/EnterSelfHarm";
import { EnterStrippingClothingFrequency } from "./IntakeQuestionnaireSections/EnterStrippingClothingFrequency";
import { EnterTherapyLocation } from "./IntakeQuestionnaireSections/EnterTherapyLocation";
import { EnterVisionHearingAbility } from "./IntakeQuestionnaireSections/EnterVisionHearingAbility";

const { Text } = Typography;
const { confirm } = Modal;

export enum INTAKE_TIMELINE_STEPS {
  THERAPY_PREFERENCES,
  BEHAVIORS,
  MEDICAL_HISTORY,
}

export const INTAKE_STEP_NAMES = {
  THERAPY_PREFERENCES: "Therapy Preferences",
  BEHAVIORS: "Behaviors",
  MEDICAL_HISTORY: "Medical History",
};

export const IntakeQuestionnaire: React.FC = () => {
  const { guardian } = useContext(AuthContext);
  const history = useHistory();
  const { isMobile } = useResponsive();

  const [remainingSteps, setRemainingSteps] = useState<number>(-1);
  const [clientFile, setClientFile] = useState<IClientFile>({} as IClientFile);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clientFileIntakeQuestionnaireStatus, setClientFileIntakeQuestionnaireStatus] =
    useState<ClientFileIntakeQuestionnaireStates>(
      ClientFileIntakeQuestionnaireStates.ENTER_THERAPY_LOCATION
    );
  const [tempTherapyPreferences, setTempTherapyPreferences] = useState<ITherapyPreferences>(
    {} as ITherapyPreferences
  );
  const [tempBehaviors, setTempBehaviors] = useState<IBehaviors>({} as IBehaviors);
  const [tempMedicalHistory, setTempMedicalHistory] = useState<IMedicalHistory>(
    {} as IMedicalHistory
  );

  useEffect(() => {
    fetchData().catch(() => {});
  }, [guardian]);

  useEffect(() => {
    getClientFileProgress(clientFile);
  }, [clientFile]);

  const fetchData = async () => {
    if (!_.isEmpty(guardian)) {
      const clientFile = await getOrCreateClientFile(guardian);
      clientFile.therapyPreferences && setTempTherapyPreferences(clientFile.therapyPreferences);
      clientFile.behaviors && setTempBehaviors(clientFile.behaviors);
      clientFile.medicalHistory && setTempMedicalHistory(clientFile.medicalHistory);
      setClientFileIntakeQuestionnaireStatus(getCurrentIntakeQuestionnaireStatus(clientFile));
      getClientFileProgress(clientFile);
      setClientFile(clientFile);
      setIsLoading(false);
    }
  };

  const updateClientFile = async (req: Partial<IClientFile>) => {
    await FirestoreService.updateClientFile({ id: clientFile.id, ...req });
    setClientFile({ ...clientFile, ...req });
  };

  const getClientFileProgress = (clientFile: IClientFile) => {
    const remainingSteps = getRemainingIntakeStepsCount(clientFile);

    if (remainingSteps === 0) {
      history.push("/");
    }

    setRemainingSteps(remainingSteps);
  };

  const confirmExit = () => {
    confirm({
      title: "Are you sure you want to exit?",
      icon: <ExclamationCircleOutlined />,
      content: "Your progress will not be saved until you complete the current step.",
      okText: "Yes",
      onOk() {
        history.push("/");
      },
    });
  };

  const backButton = () => {
    return (
      <Button
        htmlType="submit"
        type="primary"
        shape="round"
        onClick={() => {
          setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus - 1);
        }}
      >
        Back
      </Button>
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Row style={{ width: "100%" }} justify="center" align="top">
        <Button
          style={{
            position: "absolute",
            left: isMobile ? 15 : 20,
            marginTop: isMobile ? 8 : -15,
            height: 40,
          }}
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={confirmExit}
        >
          {!isMobile && "Exit"}
        </Button>

        {isMobile ? (
          <Text style={{ display: "block", marginTop: 15, fontWeight: 500 }}>
            {`Task ${4 - remainingSteps}/3 ${
              INTAKE_STEP_NAMES[
                INTAKE_TIMELINE_STEPS[3 - remainingSteps] as keyof typeof INTAKE_STEP_NAMES
              ]
            }`}
          </Text>
        ) : (
          <Steps
            progressDot
            current={3 - remainingSteps}
            direction="horizontal"
            style={{ width: "80%" }}
          >
            {Object.values(INTAKE_STEP_NAMES).map((stepName) => (
              <Steps.Step key={stepName} subTitle={stepName} />
            ))}
          </Steps>
        )}
      </Row>
      <Row style={{ width: "100%" }} justify="center">
        <Col span={isMobile ? 24 : undefined}>
          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_THERAPY_LOCATION && (
            <EnterTherapyLocation
              isMobile={isMobile}
              tempClientFile={clientFile}
              updateClientFile={updateClientFile}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_PRIMARY_LANGUAGE && (
            <EnterPrimaryLanguage
              isMobile={isMobile}
              tempTherapyPreferences={tempTherapyPreferences}
              setTempTherapyPreferences={setTempTherapyPreferences}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_PREFERRED_LANGUAGE && (
            <EnterPreferredLanguage
              isMobile={isMobile}
              tempTherapyPreferences={tempTherapyPreferences}
              setTempTherapyPreferences={setTempTherapyPreferences}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_PREFERRED_THERAPIST_GENDER && (
            <EnterPreferredTherapistGender
              isMobile={isMobile}
              tempTherapyPreferences={tempTherapyPreferences}
              setTempTherapyPreferences={setTempTherapyPreferences}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_OTHER_CAREGIVER && (
            <EnterOtherCaregiver
              isMobile={isMobile}
              tempTherapyPreferences={tempTherapyPreferences}
              setTempTherapyPreferences={setTempTherapyPreferences}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_AVAILABILITY && (
            <EnterAvailability
              isMobile={isMobile}
              tempTherapyPreferences={tempTherapyPreferences}
              setTempTherapyPreferences={setTempTherapyPreferences}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_SELF_HARM_FREQUENCY && (
            <EnterSelfHarm
              isMobile={isMobile}
              tempBehaviors={tempBehaviors}
              setTempBehaviors={setTempBehaviors}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_AGGRESSION_FREQUENCY && (
            <EnterAggressionFrequency
              isMobile={isMobile}
              tempBehaviors={tempBehaviors}
              setTempBehaviors={setTempBehaviors}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_RUNNING_AWAY_FREQUENCY && (
            <EnterRunningAwayFrequency
              isMobile={isMobile}
              tempBehaviors={tempBehaviors}
              setTempBehaviors={setTempBehaviors}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_DESTROYING_PROPERTY_FREQUENCY && (
            <EnterDestroyingPropertyFrequency
              isMobile={isMobile}
              tempBehaviors={tempBehaviors}
              setTempBehaviors={setTempBehaviors}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_FALLING_DOWN_FREQUENCY && (
            <EnterFallingDownFrequency
              isMobile={isMobile}
              tempBehaviors={tempBehaviors}
              setTempBehaviors={setTempBehaviors}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_STRIPPING_CLOTHING_FREQUENCY && (
            <EnterStrippingClothingFrequency
              isMobile={isMobile}
              tempBehaviors={tempBehaviors}
              setTempBehaviors={setTempBehaviors}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_GOALS && (
            <EnterGoals
              isMobile={isMobile}
              tempBehaviors={tempBehaviors}
              setTempBehaviors={setTempBehaviors}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_CURRENT_SERVICES && (
            <EnterCurrentServices
              isMobile={isMobile}
              tempMedicalHistory={tempMedicalHistory}
              setTempMedicalHistory={setTempMedicalHistory}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_PREVIOUS_SERVICES && (
            <EnterPreviousServices
              isMobile={isMobile}
              tempMedicalHistory={tempMedicalHistory}
              setTempMedicalHistory={setTempMedicalHistory}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_LIVING_SITUATION && (
            <EnterLivingSituation
              isMobile={isMobile}
              tempMedicalHistory={tempMedicalHistory}
              setTempMedicalHistory={setTempMedicalHistory}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_MEDICAL_CONDITIONS && (
            <EnterMedicalConditions
              isMobile={isMobile}
              tempMedicalHistory={tempMedicalHistory}
              setTempMedicalHistory={setTempMedicalHistory}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_VISION_HEARING_ABILITY && (
            <EnterVisionHearingAbility
              isMobile={isMobile}
              tempMedicalHistory={tempMedicalHistory}
              setTempMedicalHistory={setTempMedicalHistory}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_PRESCRIBED_MEDICATION && (
            <EnterPrescribedMedication
              isMobile={isMobile}
              tempMedicalHistory={tempMedicalHistory}
              setTempMedicalHistory={setTempMedicalHistory}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_FAMILY_MEDICAL_HISTORY && (
            <EnterFamilyMedicalHistory
              isMobile={isMobile}
              tempMedicalHistory={tempMedicalHistory}
              setTempMedicalHistory={setTempMedicalHistory}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}

          {clientFileIntakeQuestionnaireStatus ===
            ClientFileIntakeQuestionnaireStates.ENTER_OTHER_ISSUES && (
            <EnterOtherIssues
              isMobile={isMobile}
              tempMedicalHistory={tempMedicalHistory}
              setTempMedicalHistory={setTempMedicalHistory}
              clientFileIntakeQuestionnaireStatus={clientFileIntakeQuestionnaireStatus}
              setClientFileIntakeQuestionnaireStatus={setClientFileIntakeQuestionnaireStatus}
              updateClientFile={updateClientFile}
              backButton={backButton}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
