import { z } from "zod";

import {
  DropboxDocumentSignerRole,
  DropboxDocumentType,
} from "../services/dropboxSignService/types";

export const DropboxSignSignerSchema = z.object({
  emailAddress: z.string().min(1),
  name: z.string().min(1),
  role: z.literal(DropboxDocumentSignerRole["Parent/Guardian"]),
});

export const DropboxSignCCSignerSchema = z.object({
  emailAddress: z.string().min(1),
  role: z.literal(DropboxDocumentSignerRole["Parent/Guardian"]),
});

export const DropboxSignSigningOptionsSchema = z.object({
  draw: z.boolean(),
  type: z.boolean(),
  upload: z.boolean(),
  phone: z.boolean(),
  defaultType: z.literal("draw" as const),
});

export const getDropboxSignEmbedEndpointRequestSchema = z.object({
  signers: z.array(DropboxSignSignerSchema),
  ccEmailAddresses: z.array(DropboxSignCCSignerSchema).optional(),
  formName: z.nativeEnum(DropboxDocumentType),
  customFields: z
    .array(
      z.object({
        name: z.string().min(1),
        value: z.string().min(1),
        editor: z.literal(DropboxDocumentSignerRole["Parent/Guardian"]),
        required: z.boolean(),
      })
    )
    .optional(),
  additionalTemplateIds: z.array(z.string().min(1)).optional(),
});

export const getSignedDocumentEndpointRequestSchema = z.object({
  signatureRequestId: z.string().min(1),
  clientId: z.string().min(1).optional(),
});

export const sendConsentFormSchema = z.object({
  clinicId: z.string().min(1),
  signers: z.array(DropboxSignSignerSchema),
  ccEmailAddresses: z.array(DropboxSignCCSignerSchema).optional(),
  customFields: z
    .array(
      z.object({
        name: z.string().min(1),
        value: z.string().min(1),
        editor: z.literal(DropboxDocumentSignerRole["Parent/Guardian"]),
        required: z.boolean(),
      })
    )
    .optional(),
  templateIds: z.array(z.string().min(1)).optional(),
});

export type IGetEmbeddedUrlEndpointRequest = z.input<
  typeof getDropboxSignEmbedEndpointRequestSchema
>;

export type IGetSignedDocumentEndpointRequest = z.input<
  typeof getSignedDocumentEndpointRequestSchema
>;

export type ISendConsentFormEndpointRequest = z.input<typeof sendConsentFormSchema>;
