import { IClientFile } from "@finni-health/shared";
import { Button, Card, Radio, Row, Space, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { ReactNode } from "react";

const { Title } = Typography;

type IProps = {
  isMobile: boolean;
  tempMedicalHistory: any;
  setTempMedicalHistory: (value: any) => void;
  clientFileIntakeQuestionnaireStatus: any;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClientFile: (req: Partial<IClientFile>) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterLivingSituation: React.FC<IProps> = ({
  isMobile,
  tempMedicalHistory,
  setTempMedicalHistory,
  clientFileIntakeQuestionnaireStatus,
  setClientFileIntakeQuestionnaireStatus,
  updateClientFile,
  backButton,
}: IProps) => {
  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          {`What is your child's living situation?`}
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Radio.Group
        style={{ width: "100%", marginBottom: 25 }}
        onChange={(e) => {
          if (e.target.value === "Other") {
            setTempMedicalHistory({
              ...tempMedicalHistory,
              livingSituation: "",
            });
          } else {
            setTempMedicalHistory({
              ...tempMedicalHistory,
              livingSituation: e.target.value,
            });
          }
        }}
        value={
          ["FamilyHome", "FosterHome", "GroupHome", undefined].includes(
            tempMedicalHistory.livingSituation
          )
            ? tempMedicalHistory.livingSituation
            : "Other"
        }
      >
        <Space direction="vertical">
          <Radio value={"FamilyHome"}>Family Home</Radio>
          <Radio value={"FosterHome"}>Foster Home</Radio>
          <Radio value={"GroupHome"}>Group Home</Radio>
          <Radio value={"Other"}>Other</Radio>
        </Space>
      </Radio.Group>
      {!["FamilyHome", "FosterHome", "GroupHome", undefined].includes(
        tempMedicalHistory.livingSituation
      ) && (
        <Row
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <TextArea
            autoSize
            defaultValue={tempMedicalHistory.livingSituation}
            placeholder="Let us know your child's living situation."
            style={{ marginBottom: 25, maxWidth: 400 }}
            onChange={(e) => {
              setTempMedicalHistory({
                ...tempMedicalHistory,
                livingSituation: e.target.value,
              });
            }}
          />
        </Row>
      )}
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={
            tempMedicalHistory.livingSituation === undefined ||
            tempMedicalHistory.livingSituation === ""
          }
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClientFile({
              medicalHistory: tempMedicalHistory,
            });
            setClientFileIntakeQuestionnaireStatus(clientFileIntakeQuestionnaireStatus + 1);
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
