import { z } from "zod";

import { AppointmentLocation, BillingCode, IAppointment, IUser, Modifier } from "../types";

export interface ICalendarCreateAppointmentEndpointRequest {
  clinicId: string;
  clientId: string;
  attendeeEmails: string[];

  billingCode: BillingCode;
  modifiers: Modifier[];
  location: AppointmentLocation;

  summary: string;
  description: string;
  startMs: number;
  endMs: number;
  timeZone: string;
  rrule?: string;
  hasMeets?: boolean;
  noteId: string | null;
  sessionId: string | null;
}

export interface ICalendarCreateAppointmentUsingNoteEndpointRequest {
  noteId: string;
}

export interface ICalendarGetAppointmentEndpointRequest {
  id: string;
}

export interface ICalendarGetAppointmentsForClientIdAndDayEndpointRequest {
  clinicId: string;
  clientId: string;
  date: string;
  timeZone: string;
}
export interface ICalendarGetAppointmentSuggestionsForClientIdAndWeekEndpointRequest {
  user: IUser;
  clinicId: string;
  week: number;
  year: number;
  timeZone: string;
}

export interface ICalendarGetAppointmentsForClinicIdAndWeekEndpointRequest {
  clinicId: string;
  week: number;
  year: number;
  timeZone: string;
}

export interface ICalendarGetAppointmentsForWeekEndpointRequest {
  week: number;
  year: number;
  timeZone: string;
}

export interface ICalendarGetAppointmentsFoNoteIdEndpointRequest {
  noteId: string;
}

export type ICalendarUpdateAppointmentEndpointRequest = Partial<IAppointment> &
  Pick<IAppointment, "id"> & {
    createMeet?: boolean;
  };

export interface ICalendarDeleteAppointmentEndpointRequest {
  id: string;
}

export interface ICalendarGetAppointmentsForUserAndRangeEndpointRequest {
  clinicId: string;
  startMs: number;
  endMs: number;
  email: string;
  timeZone: string;
}

export const AppointmentsGetAppointmentsForClientAndRangeEndpointRequestSchema = z.object({
  clinicId: z.string(),
  clientId: z.string().optional(),
  // Using coerce because this is the request param. So everything converts to string.
  // Need to convert it back to number.
  // Without coerce, zod validation fails.
  startMs: z.coerce.number(),
  endMs: z.coerce.number(),
  timeZone: z.string(),
});

export type IAppointmentsGetAppointmentsForClientAndRangeEndpointRequest = z.input<
  typeof AppointmentsGetAppointmentsForClientAndRangeEndpointRequestSchema
>;

export const AppointmentsGetAppointmentsForClinicAndRangeEndpointRequestSchema = z.object({
  clinicId: z.string(),
  // Using coerce because this is the request param. So everything converts to string.
  // Need to convert it back to number.
  // Without coerce, zod validation fails.
  startMs: z.coerce.number(),
  endMs: z.coerce.number(),
  timeZone: z.string(),
});

export type IAppointmentsetAppointmentsForClinicAndRangeEndpointRequest = z.input<
  typeof AppointmentsGetAppointmentsForClinicAndRangeEndpointRequestSchema
>;

export interface ICalendarSyncAppointmentsEndpointRequest {
  startMs?: number;
  endMs?: number;
}
