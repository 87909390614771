import { DayBlock, IClientFile, WeekBlock } from "@finni-health/shared";
import { Button, Card, Row, Space, Typography } from "antd";
import _ from "lodash";
import React, { ReactNode } from "react";

import { ClientFileIntakeQuestionnaireStates } from "../../../consts";

const { Title, Text } = Typography;

type IProps = {
  isMobile: boolean;
  tempTherapyPreferences: any;
  setTempTherapyPreferences: (value: any) => void;
  setClientFileIntakeQuestionnaireStatus: (value: any) => void;
  updateClientFile: (req: Partial<IClientFile>) => Promise<void>;
  backButton: () => ReactNode;
};

export const EnterAvailability: React.FC<IProps> = ({
  isMobile,
  tempTherapyPreferences,
  setTempTherapyPreferences,
  setClientFileIntakeQuestionnaireStatus,
  updateClientFile,
  backButton,
}: IProps) => {
  const selectedAvailability = {
    height: 50,
    width: 120,
    color: "#fa9a5a",
    borderColor: "#fa9a5a",
  };

  const unselectedAvailability = {
    height: 50,
    width: 120,
    color: "rgba(0, 0, 0, 0.65)",
    borderColor: "#dadada",
  };

  const updateSelectedAvailability = (weekBlock: WeekBlock, dayBlock: DayBlock) => {
    const availability = tempTherapyPreferences.availability || {};
    if (availability[weekBlock] === undefined) {
      availability[weekBlock] = [dayBlock];
    } else if (availability[weekBlock]!.includes(dayBlock)) {
      const tempDayBlocks = availability[weekBlock];
      availability[weekBlock] = tempDayBlocks.filter((tempDayBlock: DayBlock) => {
        return tempDayBlock !== dayBlock;
      });
      _.isEmpty(availability[weekBlock]) && delete availability[weekBlock];
    } else {
      availability[weekBlock]!.push(dayBlock);
    }
    setTempTherapyPreferences({
      ...tempTherapyPreferences,
      availability,
    });
  };

  return (
    <Card
      title={
        <Title level={5} style={{ fontWeight: 400, whiteSpace: "pre-line" }}>
          {`What is your child's availability for therapy?`}
        </Title>
      }
      style={{ width: isMobile ? "100%" : 450, marginTop: 35 }}
    >
      <Row justify="center" style={{ width: "100%", marginBottom: 10 }}>
        <Text
          type="secondary"
          style={{
            fontSize: 14,
            whiteSpace: "pre-line",
          }}
        >
          Monday/Wednesday/Friday Availability
        </Text>
      </Row>
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempTherapyPreferences.availability?.monWedFri?.includes(DayBlock.MORNING)
              ? selectedAvailability
              : unselectedAvailability
          }
          onClick={() => {
            updateSelectedAvailability(WeekBlock.MON_WED_FRI, DayBlock.MORNING);
          }}
        >
          Morning <br />
          <Text
            type="secondary"
            style={{
              fontSize: 10,
              verticalAlign: "top",
            }}
          >
            (8am - 12pm)
          </Text>
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempTherapyPreferences.availability?.monWedFri?.includes(DayBlock.MIDDAY)
              ? selectedAvailability
              : unselectedAvailability
          }
          onClick={() => {
            updateSelectedAvailability(WeekBlock.MON_WED_FRI, DayBlock.MIDDAY);
          }}
        >
          Midday <br />
          <Text
            type="secondary"
            style={{
              fontSize: 10,
              verticalAlign: "top",
            }}
          >
            (12pm - 4pm)
          </Text>
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempTherapyPreferences.availability?.monWedFri?.includes(DayBlock.EVENING)
              ? selectedAvailability
              : unselectedAvailability
          }
          onClick={() => {
            updateSelectedAvailability(WeekBlock.MON_WED_FRI, DayBlock.EVENING);
          }}
        >
          <>
            Evening <br />
            <Text
              type="secondary"
              style={{
                fontSize: 10,
                verticalAlign: "top",
              }}
            >
              (4pm - 8pm)
            </Text>
          </>
        </Button>
      </Space>
      <Row justify="center" style={{ width: "100%", marginBottom: 10 }}>
        <Text
          type="secondary"
          style={{
            fontSize: 14,
            whiteSpace: "pre-line",
          }}
        >
          Tuesday/Thursday Availability
        </Text>
      </Row>
      <Space style={{ width: "100%", justifyContent: "center", marginBottom: 25 }}>
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempTherapyPreferences.availability?.tuesThurs?.includes(DayBlock.MORNING)
              ? selectedAvailability
              : unselectedAvailability
          }
          onClick={() => {
            updateSelectedAvailability(WeekBlock.TUES_THURS, DayBlock.MORNING);
          }}
        >
          Morning <br />
          <Text
            type="secondary"
            style={{
              fontSize: 10,
              verticalAlign: "top",
            }}
          >
            (8am - 12pm)
          </Text>
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempTherapyPreferences.availability?.tuesThurs?.includes(DayBlock.MIDDAY)
              ? selectedAvailability
              : unselectedAvailability
          }
          onClick={() => {
            updateSelectedAvailability(WeekBlock.TUES_THURS, DayBlock.MIDDAY);
          }}
        >
          Midday <br />
          <Text
            type="secondary"
            style={{
              fontSize: 10,
              verticalAlign: "top",
            }}
          >
            (12pm - 4pm)
          </Text>
        </Button>
        <Button
          htmlType="submit"
          shape="round"
          style={
            tempTherapyPreferences.availability?.tuesThurs?.includes(DayBlock.EVENING)
              ? selectedAvailability
              : unselectedAvailability
          }
          onClick={() => {
            updateSelectedAvailability(WeekBlock.TUES_THURS, DayBlock.EVENING);
          }}
        >
          Evening <br />
          <Text
            type="secondary"
            style={{
              fontSize: 10,
              verticalAlign: "top",
            }}
          >
            (4pm - 8pm)
          </Text>
        </Button>
      </Space>
      <Row justify="space-between">
        {backButton()}
        <Button
          htmlType="submit"
          disabled={tempTherapyPreferences.availability === undefined}
          type="primary"
          shape="round"
          onClick={async () => {
            await updateClientFile({
              therapyPreferences: tempTherapyPreferences,
            });
            setClientFileIntakeQuestionnaireStatus(
              ClientFileIntakeQuestionnaireStates.ENTER_SELF_HARM_FREQUENCY
            );
          }}
        >
          Next
        </Button>
      </Row>
    </Card>
  );
};
