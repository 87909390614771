import { Buffer } from "buffer";
import Hashids from "hashids";

import { CLIENT_FILE_ID_HASH_SALT } from "../consts";

const HASHIDS = new Hashids(CLIENT_FILE_ID_HASH_SALT);

export const encodeHash = (id: string) => {
  const hexId = Buffer.from(id, "utf8").toString("hex");
  const hash = HASHIDS.encodeHex(hexId);
  return hash;
};

export const decodeHash = (hash: string) => {
  const decodedHexId = HASHIDS.decodeHex(hash);
  const decodedId = Buffer.from(decodedHexId, "hex").toString("utf8");
  return decodedId;
};
