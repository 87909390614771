import { z } from "zod";

import { targetIntervalSchema, targetSchema } from "../types";

export const createTargetEndpointRequestSchema = targetSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .strict();

export type ICreateTargetEndpointRequest = z.input<typeof createTargetEndpointRequestSchema>;

export const updateTargetIntervalEndpointRequestSchema = targetIntervalSchema.deepPartial();

export type IUpdateTargetIntervalEndpointRequest = z.input<
  typeof updateTargetIntervalEndpointRequestSchema
>;

export const updateTargetEndpointRequestSchema = targetSchema
  .omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    id: z.string(),
    userId: z.string(),
  })
  .strict();

export type IUpdateTargetEndpointRequest = z.input<typeof updateTargetEndpointRequestSchema>;

export const createTargetGroupEndpointRequestSchema = targetSchema
  .omit({
    name: true,
    id: true,
    groupId: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .extend({
    names: z.array(z.string()),
  })
  .strict();

export type ICreateTargetGroupEndpointRequest = z.input<
  typeof createTargetGroupEndpointRequestSchema
>;

export const updateTargetGroupEndpointRequestSchema = targetSchema
  .omit({
    id: true,
    groupId: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
  })
  .deepPartial()
  .extend({
    groupId: z.string(),
    userId: z.string(),
  })
  .strict();

export type IUpdateTargetGroupEndpointRequest = z.input<
  typeof updateTargetGroupEndpointRequestSchema
>;
