import {
  DropboxDocumentSignerRole,
  DropboxDocumentType,
  DropboxHandbookTemplateId,
  IDropboxContract,
} from "./types";

export const DROPBOX_TEMPLATE_IDS = {
  [DropboxDocumentType.CAREGIVER_CONSENT_FORM]: "0aa674943b1c328d02dbbe591be010e0339a98fa", // also media consent form (same template)
  [DropboxDocumentType.THIRD_PARTY_CONSENT_FORM]: "2f00973c20ac4f4e69767bcfb520d76ed807d643",
  [DropboxDocumentType.SCHOOL_OR_AGENT_THIRD_PARTY_CONSENT_FORM]:
    "ee74f771cddbbfa02622218ce8166ce59cd57d5e",
  [DropboxDocumentType.CAREGIVER_HANDBOOK]: "" as DropboxHandbookTemplateId, // assigned at runtime
};

export const DROPBOX_CONTRACTS: IDropboxContract = {
  [DropboxDocumentType.CAREGIVER_CONSENT_FORM]: {
    title: "Caregiver consent forms",
    subject: "Caregiver consent forms",
    message:
      "Please sign this Caregiver consent form and then we can discuss more. Let me know if you have any questions.",
    templateId: DROPBOX_TEMPLATE_IDS[DropboxDocumentType.CAREGIVER_CONSENT_FORM],
    signerRole: DropboxDocumentSignerRole["Parent/Guardian"],
    customFields: {
      parentFullName: "Parent Full Name",
      clientFullName: "Child Full Name",
      practiceName: "Practice Name",
    },
    previewURL:
      "https://drive.google.com/file/d/1_IoJHnszrR5Z--Y9b5blkVVtuacbx26q/view?usp=sharing",
  },
  [DropboxDocumentType.MEDIA_CONSENT_FORM]: {
    title: "Media consent forms",
    subject: "Media consent forms",
    message:
      "Please sign this Media consent form and then we can discuss more. Let me know if you have any questions.",
    templateId: DROPBOX_TEMPLATE_IDS[DropboxDocumentType.CAREGIVER_CONSENT_FORM], // bundled with caregiver consent form if we want to have this independently add it to Dropbox
    signerRole: DropboxDocumentSignerRole["Parent/Guardian"],
    customFields: {
      parentFullName: "Parent Full Name",
    },
    previewURL:
      "https://drive.google.com/file/d/1KpMsnHXrmqCSQVM-SOER5Ou3YH084znL/view?usp=drive_link",
  },
  [DropboxDocumentType.THIRD_PARTY_CONSENT_FORM]: {
    title: "Third party consent form",
    subject: "Third party consent form",
    message:
      "Please sign this Consent form and then we can discuss more. Let me know if you have any questions.",
    templateId: DROPBOX_TEMPLATE_IDS[DropboxDocumentType.THIRD_PARTY_CONSENT_FORM],
    signerRole: DropboxDocumentSignerRole["Parent/Guardian"],
    customFields: {
      parentFullName: "Parent Full Name",
    },
    previewURL:
      "https://drive.google.com/file/d/1gV4FcGfGfledfK6kDk2RVAdMp7KmBkGx/view?usp=sharing",
  },
  [DropboxDocumentType.SCHOOL_OR_AGENT_THIRD_PARTY_CONSENT_FORM]: {
    title: "Third party consent form (School/Agent)",
    subject: "Third party consent form (School/Agent)",
    message:
      "Please sign this Consent form and then we can discuss more. Let me know if you have any questions.",
    templateId: DROPBOX_TEMPLATE_IDS[DropboxDocumentType.SCHOOL_OR_AGENT_THIRD_PARTY_CONSENT_FORM],
    signerRole: DropboxDocumentSignerRole["Parent/Guardian"],
    customFields: {
      parentFullName: "Parent Full Name",
    },
    previewURL:
      "https://drive.google.com/file/d/1AtTyM6MeS2HtR3sqxMcXtBu0hs-hWiIa/view?usp=drive_link",
  },
  [DropboxDocumentType.CAREGIVER_HANDBOOK]: {
    title: "Clients and/or Caregiver Handbook",
    subject: "Clients and/or Caregiver Handbook",
    message:
      "Please sign this handbook and then we can discuss more. Let me know if you have any questions.",
    templateId: DROPBOX_TEMPLATE_IDS[DropboxDocumentType.CAREGIVER_HANDBOOK],
    signerRole: DropboxDocumentSignerRole["Parent/Guardian"],
    customFields: {
      parentFullName: "Parent Full Name",
      clientFullName: "Child Full Name",
    },
  },
};

export const DROPBOX_HANDBOOK_TEMPLATE_IDS = {
  default: "fec90ccc2bda7cc522fd5b3d41344f731af4d42a",
  "000": "fec90ccc2bda7cc522fd5b3d41344f731af4d42a", // demo: Demo
  "2HsKMtRnHrCi1RrqsCmr": "c39c2aaf71874c806b15cc295bbb0742a1a30346", // al-kp: Krishonna's Practice
  // "mi-bear-with-me": "Bear With Me Behavior Solutions", // TODO Nothing on file, no staff in the staff table
  "5S52WtvIBJXkxGEtamvE": "48ff4f9e5e7be1d3e1457093e3fd9ff160075526", // ct-arc-light: Arc Light Behavioral Health Collective
  "6KnMkGWXDnerrbcnCUD6": "928c3181ac25d0eb936da764c48b126700c51476", // ny-wc: New York
  "7MBfmyWMF6sqcke6XPAI": "0225cd315f35196c7b2737aa21b9fdd4b4c0a94d", // oh-donnie: Donnie's Practice
  "8OYj0EdqefRLbmfi8y5w": "54f4de47cf6b88f2ce4571074c55337d1bed66e4", // mi-lexie: Lexie's Practice
  // "oh-tiffany": "Tiffany's Practice", // TODO Missing form on google drive
  FEvB96jXaWy1qVZCfuoN: "6070615cfab1ebc5fee7bea8d9777ee71e51734e", // va-rithly: Rithly's Practice
  // "tx-treichae": "Affirmative Parenting Solutions", // TODO Missing form on google drive (Churned)
  // "nj-heather": "Heather's Practice", // TODO Missing form on google drive
  // "sc-tia": "Tia's Practice", // TODO Missing form on google drive
  // "ca-kelsey": "Kelsey's Practice", // TODO Missing form on google drive
  N5GYBRWWxeqJ1jUnYK2J: "834d323665ded805c335ef979195fd58daf625c6", // ca-melissa: Melissa's Practice
  PzDphUpK6AxnCDuC617h: "36cd4555ea33c0e4dfd8ca7d1a4fb458cb4cd979", // mo-mae: Missouri
  Rhza4bU6N1GLnF0LcEnI: "5636d1bd5aba855a0951191f0a91ed2e9edcd2ed", // va-cb: Virginia Beach (Erin)
  Ru35D8TfqvW1PIlB7yDy: "575bc14e0e4935d2d1a4475979bb7f9484a28b61", // il-brooke": Brooke's Practice
  // "ri-spenser": "Spenser's Practice", // TODO Missing form on google drive
  UlNSaqhO5xIeYbeWca62: "3c63b186a3ff7003dc47fe85d2cb00cad1275320", // sc-stacie: Stacie's Practice
  YIrPnVSehX7lxTYOWh3h: "f880858bcc6e3d515fbf2ab190b9e0347572a2c6", // il-ha: Illinois
  YdbhNuNLHkHiGAEkWype: "8f15e33a64fef4eb088179a6ce6007eddd733374", // nm-lc: South New Mexico
  // "co-anikkah": "Anikkah's Practice", // TODO Missing form on google drive
  bZXsjPDAU7c8AYLzbruR: "bdcb9e063268cbc3995b7460f575fb20dafab78e", // co-wi: Big Bear
  // "co-diane": "Diane's Practice", // TODO Missing form on google drive
  // "nm-north": "North New Mexico", // TODO Missing form on google drive
  k9Da5Qs6StM2q87s6VkR: "3e0d5335759a7236c483bc9ed8752bffb1628dc4", // pivot-aba: Pivot ABA
  mHwkuIeevA01LDZRDrA9: "838e01df7daf2d318d683cbe2558814352a794da", // sc-chanelle: South Carolina
  msw1bEZQ7DxDfpH7nrn1: "4bb2cc9aaf32e95e351d1b7b3ae3040481e24bd0", // ga-ga
  // "sc-chelsea": "Chelsea's Practice", // TODO Missing form on google drive
  // "tn-kb-bh": "KB Behavioral Health", // TODO Missing form on google drive
  q5oBmjbpHSE7uSoi9STJ: "3d8c2a5551898212092940ffc8e5743d2b957424", // olympia: Kaylyn's Practice
  // "il-dominique": "Dominique's Practice", // TODO Missing form on google drive
  // "tx-jazmyn": "Behavioral Therhappy", // TODO Missing form on google drive
  urBWc6yZTMiIrkuRLIVC: "ea036b2cd558e3b93ca333e8be56770c82f4bf07", // mi-marshayla: Marshayla's Practice
  yWmwwKRIwFrtSi9luaqc: "ac2f3f0712fcbe2494741387fdadc6ad3ce0b2f6", // co-bh: Brick House
  FO8O6dCl2Vy7K3QAnAeP: "4d91935b50f5071f076645116719425d26a59b0e", // hope-to-inspire: Hannah’s Practice: Hope to Inspire ABA
  X9uqSh5gtU7Oecfd5hqx: "bb5f7c5f3aec01e62448675fd12b97a1c3c2911d", // nh-ally: Ally's Practice
  OTAeFNecymhgmS0fXpiE: "6eccb0c4431e8547d73cab1d24a3063c451b15ef", // beyond-behavior: Beyond Behavior
  onHDBODjA0dvcKwtZ7UG: "3d4d3e882997d667b71061d0db710c2aa6e06a1b", // tn-kb-bh: KB Behavioral Health
  ukfPVmaMCYIg0b3tXsNy: "67d51c2c0773558a0c763b9e4718e2339ea60ec4", // well-aba: Well ABA
  pJQTyDvchBiZmpTGVLHc: "029a7171fc52ef301c1592a864442322f1889553", // beauty-and-the-behavior: Beauty and the Behavior
  G6Czrb8XM3uCtjkmZpF8: "25dff3d6e1b97803e93901fa1512feb901e6afa7", // sense-ational: Sheila's Practice
  uZ5Yhn8opwMlIKxRrEBz: "8a4e2c08a5157d4520d018edcb6c5d39b986b318", // thrive-consulting: VA Kelli Smith
  "5ynjhErMt1MA5ZM3CjfS": "ed47b56bcfbc408346a1026064f5de6d224a39de", // sunny-solutions-aba: CT Deanna Dixon
  te07HQ0i8lKKtdLQFaMK: "e8262f4ca624d7beadf54a303264d4b7de7ffb7e", // BehaviorBloom ABA: CO Rachel
  iwmmFls5mF5G0vJ1mTyk: "7b01077e319f5fb751186faf774332f9c5c079dd", // Arrow Behavioral Associates: CT Julio
  KMKIMSOm5oNvCQpBYoro: "dcd17a957e621f68e57f455ae2536ec4abced6aa", // Integrity ABA: SC Tia Reyes
  UUGS5EnyZ6tgcSuZ5s2J: "dbd9c50dc89b1bd5eddc726565cac2ebc00202ac", // Chockstone Behavioral Consulting: VT Kassondra Gaylord
  fQRBUX9VaMuWvjNIoyZP: "ffed1e6be4083d87741aa420f6b362c2a4d4ef34", // Empower ABA: NC Jade Alford
  VKoUKPwQql87F9kYqemK: "c6aa2229a766e1225bbc121efdd86508433f4269", // Bright Beginnings ABA: CA Sarah Mandel
  kUrTsz8s1pjgQameiBH3: "6e6cace4573c8e64d6110c4c284ea5dfe505da2b", // Common Grounds Learning: ID Annabel
  ItzbxUAwGpF4sNmWujpK: "139a72ac73999c8339f42ce0c66bc2ad426023a0", // Inclusive Health: CA Audrey Ronquillo
  ePwsjdwU8JPezi5Rd6Gi: "49a5c3d474879ce0b107f5cf3481828f27ca5e2b", // Goemaere Consulting LLC: GA Ariana Goemaere
  CGJj8XTcQuv8EfonTKxC: "d1ee530e9ae70277235bd2bd4733f5e14efbc577", // roots-aba-services: Roots ABA Services
  "5mLG1CJRCZ3kT0y2b8YG": "13dfc59f9bcfc15ee2446b1d110098a5b7bf097b", // hundred-leaves: AZ Sofia Guerra
  DKaS1ljDaEeR14Baqg3F: "e149a26ac10b260d194830a13cf125a730d1de46", // committed-to-kids-aba: Committed to Kids ABA
  LjRUQ9rku6XjFH2Fh87M: "a95c4d86959a07d5ade6a4f96936c6909f96d474", // blossom-aba-therapy: Tia Palmer's practice
  xwPcCKvGJIQcCD9UnvOJ: "0dee78377d243a82cb0d6814416fb94377c30719", // Little Teddy Bears's ABA: NY Sunghee Kim
  GA0AUq560NZ1XSx3IyBb: "8d5338dacbb5a83077cb8f687752b0b5cd60e4d1", // NM ABA.CO
  CMWGGt1FRc3bJmDfZi1F: "de469a580b430c92a4e2e1ca1d27b8824cecc92c", // Open Doors ABA
  kQDAbTDWydDjrWJRYCaM: "ed5c939ad5ffec60ba297f77fde9848a1e9dd23d", // Connecticut Behavioral Services LLC
};

export const DROPBOX_HANDBOOK_PREVIEW_URLS = {
  default: "https://drive.google.com/file/d/1ASVRdEW09qjPXxmQ1iYroV05GcI-7AAs/view?usp=sharing", // default
  "000": "https://drive.google.com/file/d/1ASVRdEW09qjPXxmQ1iYroV05GcI-7AAs/view?usp=sharing", // demo: Demo
  "2HsKMtRnHrCi1RrqsCmr":
    "https://drive.google.com/file/d/13sWQ9RTra9vyD_37cdsVx8isD4NYawr8/view?usp=drive_link", // al-kp: Krishonna's Practice
  // "mi-bear-with-me": "Bear With Me Behavior Solutions", // TODO Nothing on file, no staff in the staff table
  "5S52WtvIBJXkxGEtamvE":
    "https://drive.google.com/file/d/1QlWtb4_7dSjvPU0grQ0i9R587CJf7noc/view?usp=drive_link", // ct-arc-light: Arc Light Behavioral Health Collective
  "6KnMkGWXDnerrbcnCUD6":
    "https://drive.google.com/file/d/10S5mEvNrr1g4W_BEd55RMjZtWOMzGgBl/view?usp=sharing", // ny-wc: New York
  "7MBfmyWMF6sqcke6XPAI":
    "https://drive.google.com/file/d/147dOGiqLQ-cuzDEd4Uwl-nHfZs35IyEk/view?usp=drive_link", // spark-joy: Donnie's Practice
  "8OYj0EdqefRLbmfi8y5w":
    "https://drive.google.com/file/d/1N9HnjqUdgKAWmirakCsZXOh0RaasTkw-/view?usp=drive_link", // mi-lexie: Lexie's Practice
  // "oh-tiffany": "Tiffany's Practice", // TODO Missing form on google drive
  FEvB96jXaWy1qVZCfuoN:
    "https://drive.google.com/file/d/1_jS4lXmapsPp9Y5G6M9nTaqJdzgzACIh/view?usp=drive_link", // va-rithly: Rithly's Practice
  // "tx-treichae": "Affirmative Parenting Solutions", // TODO Missing form on google drive (Churned)
  // "nj-heather": "Heather's Practice", // TODO Missing form on google drive
  // "sc-tia": "Tia's Practice", // TODO Missing form on google drive
  // "ca-kelsey": "Kelsey's Practice", // TODO Missing form on google drive
  N5GYBRWWxeqJ1jUnYK2J:
    "https://drive.google.com/file/d/111Fz6AMo37DhYn0zgq-6c6AUf2BZhhZg/view?usp=drive_link", // ca-melissa: Melissa's Practice
  PzDphUpK6AxnCDuC617h:
    "https://drive.google.com/file/d/18eLlCpGuwZDZ6I-BW5u1oBogwxjm7ii4/view?usp=drive_link", // mo-mae: Missouri
  Rhza4bU6N1GLnF0LcEnI:
    "https://drive.google.com/file/d/1wV5rwexfBO57VzDQkw-lr8XcUk5WuuB6/view?usp=drive_link", // va-cb: Virginia Beach
  Ru35D8TfqvW1PIlB7yDy:
    "https://drive.google.com/file/d/1AiMdYJw6O-J5NKksWI9PDeUmPK3_xD9j/view?usp=drive_link", // il-brooke: Brooke's Practice
  // "ri-spenser": "Spenser's Practice", // TODO Missing form on google drive
  UlNSaqhO5xIeYbeWca62:
    "https://drive.google.com/file/d/1pWCxYSEOneVZ9uzd7AqIo7y89TOz1MYw/view?usp=drive_link", // sc-stacie: Stacie's Practice
  YIrPnVSehX7lxTYOWh3h:
    "https://drive.google.com/file/d/1CgDC6vg6qe6wGv1QWOwvES_2bVdmJ_MG/view?usp=drive_link", // il-ha: Illinois
  YdbhNuNLHkHiGAEkWype:
    "https://drive.google.com/file/d/1r-V3pFTDpD314MWhmg3dZN7TiYnibuGa/view?usp=drive_link", // nm-lc: South New Mexico
  // "co-anikkah": "Anikkah's Practice", // TODO Missing form on google drive
  bZXsjPDAU7c8AYLzbruR:
    "https://drive.google.com/file/d/1VIgk2dQKCBo7ki9fkfqvxx_92OWiKSRD/view?usp=drive_link", // co-wi: Big Bear
  // "co-diane": "Diane's Practice", // TODO Missing form on google drive
  // "nm-north": "North New Mexico", // TODO Missing form on google drive
  k9Da5Qs6StM2q87s6VkR:
    "https://drive.google.com/file/d/1WlQLmqDviGDH6AZ2dOREGqtmyMgpC-ZI/view?usp=drive_link", // "pivot-aba": "Pivot ABA"
  mHwkuIeevA01LDZRDrA9:
    "https://drive.google.com/file/d/1WjXHw8rzR97HI5amWlEOH7WvqL8B8lUO/view?usp=drive_link", // "sc-chanelle": "South Carolina"
  msw1bEZQ7DxDfpH7nrn1:
    "https://drive.google.com/file/d/1UCXjVImR_YjtqI14ml9M9eAeEh2rRY3D/view?usp=drive_link", // ga-ga
  // "sc-chelsea": "Chelsea's Practice", // TODO Missing form on google drive
  // "tn-kb-bh": "KB Behavioral Health", // TODO Missing form on google drive
  q5oBmjbpHSE7uSoi9STJ:
    "https://drive.google.com/file/d/1UCXjVImR_YjtqI14ml9M9eAeEh2rRY3D/view?usp=drive_link", // olympia: Kaylyn's Practice
  // "il-dominique": "Dominique's Practice", // TODO Missing form on google drive
  // "tx-jazmyn": "Behavioral Therhappy", // TODO Missing form on google drive
  urBWc6yZTMiIrkuRLIVC:
    "https://drive.google.com/file/d/1uzLoFhTq-EuX1AN7XS_4EYjgtWdbv9Xg/view?usp=drive_link", // mi-marshayla: Marshayla's Practice
  yWmwwKRIwFrtSi9luaqc:
    "https://drive.google.com/file/d/1k2h4AyYx3fO6mmIfQu4LmbdOo0spPTsG/view?usp=drive_link", // co-bh: Brick House
  FO8O6dCl2Vy7K3QAnAeP:
    "https://drive.google.com/file/d/1EK-3tdQaShKoxd189xW9hZhCCZHHsda3/view?usp=drive_link", // hope-to-inspire: Hannah’s Practice: Hope to Inspire ABA
  X9uqSh5gtU7Oecfd5hqx:
    "https://drive.google.com/file/d/1_rO1NpyaoJVcw-nS_4HRclEsktnBCIQC/view?usp=drive_link", // nh-ally: Ally's Practice
  OTAeFNecymhgmS0fXpiE:
    "https://drive.google.com/file/d/1I0gkJgYNPfYmymtfaLjn9Er87E5iNH-S/view?usp=sharing", // beyond-behavior: Beyond Behavior
  onHDBODjA0dvcKwtZ7UG:
    "https://drive.google.com/file/d/1JN7k0-Srmd48btVPGnzDvc1h14T66tpD/view?usp=sharing", // tn-kb-bh: KB Behavioral Health
  ukfPVmaMCYIg0b3tXsNy:
    "https://drive.google.com/file/d/1YuYaB8JYTfosKT7DNcYXEE-njF1sbJio/view?usp=drive_link", // well-aba: Well ABA
  pJQTyDvchBiZmpTGVLHc:
    "https://drive.google.com/file/d/1gctCCPBNnFzcn-U28F9sOz8896C1SN8Z/view?usp=sharing", // beauty-and-the-behavior: Beauty and the Behavior
  G6Czrb8XM3uCtjkmZpF8:
    "https://drive.google.com/file/d/1hk9Ek5YbnhlYawz4-DicuhRH-OhwX4yH/view?usp=sharing", // sense-ational: Sheila's Practice
  uZ5Yhn8opwMlIKxRrEBz:
    "https://drive.google.com/file/d/1I6sqk79Gb-HvJm1vucjZ21dj0zvQL89T/view?usp=sharing", // thrive-consulting: VA Kelli Smith
  "5ynjhErMt1MA5ZM3CjfS":
    "https://drive.google.com/file/d/1AqI388rXhxtDgHapofc3Un0BJRMKM0_G/view?usp=sharing", // sunny-solutions-aba: CT Deanna Dixon
  te07HQ0i8lKKtdLQFaMK:
    "https://drive.google.com/file/d/1wWZ3PvPFaCdZbFR0X5SIrTVX_KID2-u6/view?usp=sharing", // BehaviorBloom ABA: CO Rachel
  iwmmFls5mF5G0vJ1mTyk:
    "https://drive.google.com/file/d/1q-3Wvpn3JVTKRxP0exq-Aza24jSwX29h/view?usp=sharing", // Arrow Behavioral Associates: CT Julio
  KMKIMSOm5oNvCQpBYoro:
    "https://drive.google.com/file/d/1SEgiHcsifxflEVtFdsZ9HTF_EYxpfxDA/view?usp=drive_link", // Integrity ABA: SC Tia Reyes
  UUGS5EnyZ6tgcSuZ5s2J:
    "https://drive.google.com/file/d/1vFm8mGdPu4bHvX1gYv1B7b8Iev9Be_mF/view?usp=drive_link", // Chockstone Behavioral Consulting: VT Kassondra Gaylord
  fQRBUX9VaMuWvjNIoyZP:
    "https://drive.google.com/file/d/1RD5oQUZXUMqumLc_JXbP1wBHwGJgBWgm/view?usp=sharing", // Empower ABA: NC Jade Alford
  VKoUKPwQql87F9kYqemK:
    "https://drive.google.com/file/d/1Zh1KPpQG4yA_NyEqzbG5rInMJF4RwsyO/view?usp=drive_link", // Bright Beginnings ABA: CA Sarah Mandel
  kUrTsz8s1pjgQameiBH3:
    "https://drive.google.com/file/d/19zrIPxdqkTac8N7IZlSd-NGOjiA8tCm5/view?usp=sharing", // Common Grounds Learning: ID Annabel
  ItzbxUAwGpF4sNmWujpK:
    "https://drive.google.com/file/d/1pF0D8HbsQwxGerBHsy15pI2VlcQQ9Cob/view?usp=drive_link", // Inclusive Health: CA Audrey Ronquillo
  ePwsjdwU8JPezi5Rd6Gi:
    "https://drive.google.com/file/d/1IvG7NJLw5grGh8Hw_Tmvhh_mAMmDRxPh/view?usp=drive_link", // Goemaere Consulting LLC: GA Ariana Goemaere
  CGJj8XTcQuv8EfonTKxC:
    "https://drive.google.com/file/d/1TGbAFOb6Ij5qjN7NMg4t4iprdYuzEa6C/view?usp=sharing", // roots-aba-services: Roots ABA Services
  "5mLG1CJRCZ3kT0y2b8YG": "https://drive.google.com/file/d/1c-fCKQVmt_lArXoJ7wMr4ARzBULBezw2/view", // hundred-leaves: AZ Hundred Leaves
  DKaS1ljDaEeR14Baqg3F:
    "https://drive.google.com/file/d/1dYw-oeDB_c0Bm8VmfDh9w5ltoTkJlthz/view?usp=sharing", // committed-to-kids-aba: Committed to Kids ABA'
  LjRUQ9rku6XjFH2Fh87M:
    "https://drive.google.com/file/d/136ZaLH2DS4sFXCJ1QpUImOdKMZdkimTg/view?usp=sharing", // blossom-aba-therapy: Tia Palmer's practice
  xwPcCKvGJIQcCD9UnvOJ:
    "https://drive.google.com/file/d/1gob_LZSHYt8fKoGZuQXeGbfHeAZOTG0n/view?usp=sharing", // Little Teddy Bears's ABA: NY Sunghee Kim
  GA0AUq560NZ1XSx3IyBb:
    "https://drive.google.com/file/d/1GkcYLwVoDBtsiu0PIb7livMBmreQH_M6/view?usp=sharing", // NM ABA.CO
  CMWGGt1FRc3bJmDfZi1F: "https://drive.google.com/drive/folders/1M7fK9YnDL72URR62CGrQyHaMoHThymXD", // Open Doors ABA
  kQDAbTDWydDjrWJRYCaM:
    "https://drive.google.com/file/d/1i8a72dTyRk07Rj5SEqoNETIblvzVHXiX/view?usp=drive_link", // Connecticut Behavioral Services LLC
};

export const DROPBOX_SIGN_WEBHOOK_SUCCESS = "Hello API Event Received";

export const DROPBOX_SIGN_WEBHOOK_ERROR = "Hello API Event Received with errors";

export const DEBUG_DROPBOX = false;
