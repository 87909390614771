import { z } from "zod";

export enum ExportFilter {
  START_DATE = "startDate",
  COMPLETION_DATE = "completionDate",
}

export const getAllPayrollRawDataEndpointRequestSchema = z.object({
  startMs: z.number().min(1),
  endMs: z.number().min(1),
  exportBy: z.nativeEnum(ExportFilter).optional(),
});

export type IGetAllPayrollRawDataEndpointRequest = z.input<
  typeof getAllPayrollRawDataEndpointRequestSchema
>;
